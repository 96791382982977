import "core-js/modules/es.function.name";
import util from "@/libs/util";
import utils from "@/utils/utils";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./Editor.css"; // var Size = Quill.import("formats/size");
// Size.whitelist = sizes;
// Quill.register(Size, true);

var sizes = ["14px", "16px", "18px", "20px", "22px", "26px", "28px", "30px"];
/*富文本编辑图片上传配置*/

/* uploadConfig 可选，可使用自己封装的上传方法 */

var uploadConfig = {
  action: process.env.VUE_APP_API + "/pay/upload_img",
  // 上传的图片服务器地址,  // 必填参数 图片上传地址
  methods: "POST",
  // 必填参数 图片上传方式
  token: util.cookies.get("token"),
  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
  name: "pic",
  // 必填参数 文件的参数名
  size: 500,
  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon" // 可选 可上传的图片格式

}; // toolbar工具栏的工具选项（默认展示全部）

var toolOptions = [["bold", "italic", "underline", "strike", "image"], // 加粗 斜体 下划线 删除线
["blockquote", "code-block"], // 引用  代码块
[{
  list: "ordered"
}, {
  list: "bullet"
}], // 有序、无序列表
[{
  indent: "-1"
}, {
  indent: "+1"
}], // 缩进
[{
  size: sizes
}], // 字体大小
// [{ font: fonts }], //显示字体选择
[{
  header: [1, 2, 3, 4, 5, 6, false]
}], // 标题
[{
  color: []
}, {
  background: []
}], // 字体颜色、字体背景颜色
[{
  align: []
}], // 对齐方式
["clean"], // 清除文本格式
["link"], // 链接、图片、视频
["omega"]];
var handlers = {
  image: function image() {
    var self = this;
    var fileInput = this.container.querySelector("input.ql-image[type=file]");

    if (fileInput === null) {
      fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file"); // 设置图片参数名

      if (uploadConfig.name) {
        fileInput.setAttribute("name", uploadConfig.name);
      } // 可设置上传图片的格式


      fileInput.setAttribute("accept", uploadConfig.accept);
      fileInput.classList.add("ql-image"); // 监听选择文件

      fileInput.addEventListener("change", function () {
        // 创建formData
        var formData = new FormData();
        formData.append(uploadConfig.name, fileInput.files[0]); // 图片上传

        var xhr = new XMLHttpRequest();
        xhr.open(uploadConfig.methods, uploadConfig.action, true); // 如果需要token且存在token

        if (uploadConfig.token) {
          xhr.setRequestHeader("Authorization", "Bearer " + uploadConfig.token);
        }

        xhr.setRequestHeader("Request-Url", "/index "); // 上传数据成功，会触发

        xhr.onload = function (e) {
          if (xhr.status === 200) {
            var res = JSON.parse(xhr.responseText);
            var length = self.quill.getSelection(true).index;
            console.log(res); //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。

            self.quill.insertEmbed(length, "image", utils.imgTrow(res.data.image));
            self.quill.setSelection(length + 1);
          }

          fileInput.value = "";
        }; // 开始上传数据


        xhr.upload.onloadstart = function (e) {
          fileInput.value = "";
        }; // 当发生网络异常的时候会触发，如果上传数据的过程还未结束


        xhr.upload.onerror = function (e) {}; // 上传数据完成（成功或者失败）时会触发


        xhr.upload.onloadend = function (e) {// console.log('上传结束')
        };

        xhr.send(formData);
      });
      this.container.appendChild(fileInput);
    }

    fileInput.click();
  }
};
export default {
  theme: "snow",
  // 主题
  debug: "warn",
  modules: {
    imageDrop: true,
    //图片拖拽
    imageResize: {
      //放大缩小
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white"
      },
      modules: ["Resize", "DisplaySize", "Toolbar"]
    },
    toolbar: {
      container: toolOptions,
      // 工具栏选项
      handlers: handlers // 事件重写

    }
  }
};