import request from '@/utils/request'; // 产品相关筛选  

/**
 * 无权限接口
 */
// 获取所有游戏

export function getGame(data) {
  return request({
    url: '/data/searchData',
    method: 'get',
    params: data
  });
} // 获取游戏关联平台

export function getPlatform(data) {
  return request({
    url: '/data/searchGamePlatformNoAccess',
    method: 'get',
    params: data
  });
} // 获取平台关联渠道

export function getChannel(data) {
  return request({
    url: '/data/searchPlatformChannelNoAccess',
    method: 'get',
    params: data
  });
} // 获取渠道关联包名

export function getPkg(data) {
  return request({
    url: '/data/searchChannelPkgNoAccess',
    method: 'get',
    params: data
  });
} // 获取广告位置

export function searchProductAdvPlatformNoAccess(data) {
  return request({
    url: '/data/searchProductAdvPlatformNoAccess',
    method: 'get',
    params: data
  });
} // 获取游戏下所有渠道

export function searchChannelToGameNoAccess(data) {
  return request({
    url: '/data/searchChannelToGameNoAccess',
    method: 'get',
    params: data
  });
} // 获取货币类型

export function getCurrencyList(data) {
  return request({
    url: '/data/getCurrencyList',
    method: 'get',
    params: data
  });
} // 获取支付渠道

export function getMultiPayChannel(data) {
  return request({
    url: '/data/getMultiPayChannel',
    method: 'get',
    params: data
  });
} // 获取产品名称ID

export function getProductData(data) {
  return request({
    url: '/financial/getProductData',
    method: 'get',
    params: data
  });
}