var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { ref: "container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择环境" },
                  on: { change: _vm.Renewal },
                  model: {
                    value: _vm.searchData.is_prod,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "is_prod", $$v)
                    },
                    expression: "searchData.is_prod"
                  }
                },
                _vm._l(_vm.severlist, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                height: "32px"
              },
              attrs: { span: 10 }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.radio,
                    callback: function($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("角色ID查询")
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("角色名查询")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: {
              dataOptions: "pickerOptionsOnMonth",
              propsData: {
                isProduct: false,
                adChannel: false,
                adPlacement: false,
                isPayChannel: false,
                isCountry: false,
                isDate: false,
                isCurrency: true
              }
            },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择服务器"
                  },
                  model: {
                    value: _vm.searchData.server_id,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "server_id", $$v)
                    },
                    expression: "searchData.server_id"
                  }
                },
                _vm._l(_vm.serverLists, function(item) {
                  return _c("el-option", {
                    key: item.serverID,
                    attrs: { label: item.serverName, value: item.serverID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.radio == 1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "角色名"
                    },
                    model: {
                      value: _vm.searchData.role_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "role_name", $$v)
                      },
                      expression: "searchData.role_name"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.radio == 2
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "角色ID: 1001,1002"
                    },
                    model: {
                      value: _vm.searchData.role_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "role_id", $$v)
                      },
                      expression: "searchData.role_id"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isderive && _vm.searchList.length
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exuserInfoAll }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoadingOne },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1, true)
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoadingOne,
              expression: "reqLoadingOne"
            }
          ],
          staticClass: "marginB",
          attrs: { data: _vm.searchList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "server_name", label: "服务器", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "role_name", label: "角色名", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { prop: "role_id", label: "角色ID", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { prop: "level", label: "等级", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "vip", label: "VIP等级", width: "150" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recharge_amount",
              label: "充值总金额",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "real_time_amount",
              label: "实时充值总额",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "fighting", label: "战斗力", width: "150" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "account_type",
              label: "第三方登录类型",
              width: "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "relevancy_role_name",
              label: "关联账号",
              "show-overflow-tooltip": "",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-ellipsis-1",
                        staticStyle: { cursor: "pointer" }
                      },
                      [_vm._v(" " + _vm._s(row.relevancy_role_name) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "device_id", label: "最近登录设备", width: "290" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(" " + _vm._s(row.device_id) + " ")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "register_time",
              label: "注册时间",
              width: _vm.$utils.flexColumnWidth("2023-12-05 14:56:43", false)
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "last_login_time",
              label: "最近登录时间",
              "min-width": "210"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "220", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.viewDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      row.emperorStatus == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.emperor(row, 1)
                                }
                              }
                            },
                            [_vm._v("封号")]
                          )
                        : _vm._e(),
                      row.emperorStatus == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.unseal(row, 1)
                                }
                              }
                            },
                            [_vm._v("解封")]
                          )
                        : _vm._e(),
                      row.emperorStatus != 3
                        ? _c("div", { staticClass: "controlsBtn-vertical" })
                        : _vm._e(),
                      row.speakingStatus == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.emperor(row, 2)
                                }
                              }
                            },
                            [_vm._v("禁言")]
                          )
                        : _vm._e(),
                      row.speakingStatus == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function($event) {
                                  return _vm.unseal(row, 2)
                                }
                              }
                            },
                            [_vm._v("解禁")]
                          )
                        : _vm._e(),
                      row.speakingStatus != 3
                        ? _c("div", { staticClass: "controlsBtn-vertical" })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.$refs.sendEmail.open(row, "shot")
                            }
                          }
                        },
                        [_vm._v("发邮件")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: {
          "current-change": function($event) {
            return _vm.searchClick($event, false)
          },
          "size-change": _vm.searchClickSizeChange
        }
      }),
      _vm.detailsData.length
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticClass: "marginB",
                  attrs: { data: _vm.detailsData, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "server_name",
                      label: "服务器",
                      "min-width": "160"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "user_id",
                      label: "用户ID",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "role_name",
                      label: "角色名",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "role_id",
                      label: "角色ID",
                      "min-width": "170"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "level", label: "等级", "min-width": "110" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "vip", label: "VIP等级", "min-width": "110" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fighting",
                      label: "战斗力",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "league_name",
                      label: "联盟",
                      "min-width": "160",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "account_type",
                      label: "第三方登录类型",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "recharge_amount",
                      label: "充值总金额",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "real_time_amount",
                      label: "实时充值总额",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remain_coin",
                      label: "金币数量",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remain_diamond",
                      label: "宝石数量",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "relevancy_role_name",
                      label: "关联账号",
                      "min-width": "160",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "text-ellipsis-1",
                                  staticStyle: { cursor: "pointer" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(row.relevancy_role_name) + " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1884808150
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "channel",
                      label: "渠道",
                      "min-width": "140"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(row.channel_name || row.channel) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4013907267
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "last_login_country",
                      label: "最近登录地区",
                      "min-width": "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "last_login_time",
                      label: "最近登录时间",
                      "min-width": "210"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "last_logins",
                      label: "最近登录设备",
                      width: "290"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(" " + _vm._s(row.device_id) + " ")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      344628089
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "register_country",
                      label: "语言",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "register_time",
                      label: "注册时间",
                      width: _vm.$utils.flexColumnWidth(
                        "2023-12-05 14:56:43",
                        false
                      )
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "country",
                      label: "国家",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ip", label: "IP", "min-width": "140" }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "100%"
                      },
                      attrs: { span: 4.5 }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.handleCheckedCitiesChange },
                          model: {
                            value: _vm.checkedCities,
                            callback: function($$v) {
                              _vm.checkedCities = $$v
                            },
                            expression: "checkedCities"
                          }
                        },
                        _vm._l(_vm.featureList, function(item, index) {
                          return _c(
                            "el-radio-button",
                            {
                              key: index,
                              attrs: { label: item.id, border: "" }
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "260px" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "picker-options": _vm.pickerOptionsRadius,
                          "value-format": "yyyy-MM-dd",
                          clearable: false,
                          type: "daterange",
                          align: "right",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.date,
                          callback: function($$v) {
                            _vm.date = $$v
                          },
                          expression: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "资源ID"
                            },
                            model: {
                              value: _vm.propertySearchData.resource_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.propertySearchData,
                                  "resource_id",
                                  $$v
                                )
                              },
                              expression: "propertySearchData.resource_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "操作ID"
                            },
                            model: {
                              value: _vm.propertySearchData.action,
                              callback: function($$v) {
                                _vm.$set(_vm.propertySearchData, "action", $$v)
                              },
                              expression: "propertySearchData.action"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "operationFrame-entity",
                              attrs: { clearable: "", placeholder: "变动类型" },
                              model: {
                                value: _vm.propertySearchData.kind,
                                callback: function($$v) {
                                  _vm.$set(_vm.propertySearchData, "kind", $$v)
                                },
                                expression: "propertySearchData.kind"
                              }
                            },
                            _vm._l(_vm.kindList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "3" && _vm.searchCurrency.length
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择货币类型"
                              },
                              model: {
                                value: _vm.orderSearchData.currency_info,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.orderSearchData,
                                    "currency_info",
                                    $$v
                                  )
                                },
                                expression: "orderSearchData.currency_info"
                              }
                            },
                            _vm._l(_vm.searchCurrency, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.currency_name,
                                  value: item.currency
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "3" && _vm.orderData.length
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "100px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.exportLoading
                              },
                              on: { click: _vm.exportdata }
                            },
                            [_vm._v("导出全部")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities != "5"
                    ? _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.reqLoadingTow
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleCheckedCitiesChange(
                                    _vm.checkedCities
                                  )
                                }
                              }
                            },
                            [_vm._v("查询")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.checkedCities == "1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "ksajaiqsasrfr",
                          attrs: { data: _vm.tableData, border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_channel_reveal",
                              label: "广告平台",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_placement_reveal",
                              label: "广告位置",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "server_id",
                              label: "服务器ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "device_id",
                              label: "设备号",
                              "show-overflow-tooltip": "",
                              width: "360"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "user_id",
                              label: "账号ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "role_id",
                              label: "角色ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "角色名称",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "desc_kind",
                              label: "物品类型",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "desc_name",
                              label: "物品名称",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "num",
                              label: "物品数量",
                              "show-overflow-tooltip": ""
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          layout: "total,sizes, prev, pager, next",
                          "current-page": _vm.currentPage,
                          total: _vm.tableCopyTableList.length,
                          "page-size": _vm.pageSize
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange1,
                          "size-change": _vm.backSizeChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == "2"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "sdkjaoqiweq",
                          attrs: {
                            data: _vm.property,
                            border: "",
                            "max-height": "600px"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "device_id",
                              label: "设备ID",
                              width: "360"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "user_id", label: "用户ID" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "role_id", label: "角色ID" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "action", label: "操作ID" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "action_name", label: "操作名称" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "th", label: "时间", width: "200" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "resource_name", label: "资源名称" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "resource_id", label: "资源ID" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "kind", label: "变动类型" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "total_num", label: "变动数量" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == "3"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "sdjkahdsja",
                          attrs: { data: _vm.orderData, border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_channel_reveal",
                              label: "广告渠道",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_placement_reveal",
                              label: "广告位置",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "country",
                              label: "国家",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "order_id",
                              label: "研发订单ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "pay_channel_order_id",
                              label: "渠道订单ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "user_id",
                              label: "用户ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "role_id",
                              label: "角色ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "device_id",
                              label: "设备ID",
                              "show-overflow-tooltip": "",
                              "min-width": "360"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount",
                              label: "付费金额",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$utils
                                              .conversion(row.amount)
                                              .toFixed(2)
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2165437223
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "product_id",
                              label: "充值商品ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "product_name",
                              label: "充值商品名称",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "timestamp",
                              label: "充值时间",
                              "show-overflow-tooltip": "",
                              "min-width": "200"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ip",
                              label: "IP",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          layout: "total,sizes, prev, pager, next",
                          "current-page": _vm.orderSearchData.page,
                          total: _vm.orderTotal
                        },
                        on: {
                          "current-change": _vm.orderhandleCurrentChange,
                          "size-change": _vm.orderhandleCurrentChangeSizeChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == "4"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "asasikdjq",
                          attrs: { data: _vm.emailList, border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "server_id",
                              label: "服务器ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "user_id",
                              label: "账号ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "role_id",
                              label: "角色ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "email_id",
                              label: "邮箱ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receive_time",
                              label: "收到时间",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$times.timestampToTime(
                                              row.receive_time,
                                              "YY-MM-DD HH"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3460515199
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "reward", label: "物品" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      row.item_list !== "[]"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: { color: "#409EFF" },
                                              on: {
                                                click: function($event) {
                                                  _vm.$refs.viewArticles.init(
                                                    JSON.parse(row.item_list),
                                                    "Articles"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("查看")]
                                          )
                                        : _c("div", [_vm._v("/")])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3983680399
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "request_data", label: "内容详情" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { color: "#409EFF" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.richTextDetails.open(
                                                row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("查看")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1132675269
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "status",
                              label: "状态",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      row.status == 1
                                        ? _c("div", [_vm._v("已读")])
                                        : _vm._e(),
                                      row.status == 2
                                        ? _c("div", [_vm._v("未读")])
                                        : _vm._e(),
                                      row.status == 3
                                        ? _c("div", [_vm._v("已领取")])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2726834207
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == 5
                ? _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                height: "100%"
                              },
                              attrs: { span: 4.5 }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  on: { change: _vm.operationLogChange },
                                  model: {
                                    value: _vm.controlsSortIndex,
                                    callback: function($$v) {
                                      _vm.controlsSortIndex = $$v
                                    },
                                    expression: "controlsSortIndex"
                                  }
                                },
                                _vm._l(_vm.controlsSort, function(item, index) {
                                  return _c(
                                    "el-radio-button",
                                    {
                                      key: index,
                                      attrs: { label: index, border: "" }
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "12px",
                                    "margin-top": "2px"
                                  },
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    loading: _vm.reqLoadingTow
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.operationLogChange(
                                        _vm.controlsSortIndex
                                      )
                                    }
                                  }
                                },
                                [_vm._v("查询")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.controlsSortIndex == 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.reqLoadingTow,
                                      expression: "reqLoadingTow"
                                    }
                                  ],
                                  key: "asaewqw1dc",
                                  attrs: {
                                    data: _vm.controlsSort[0].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "admin_name",
                                      label: "操作员"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "role_id", label: "玩家ID" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "pay_channel",
                                      label: "渠道ID"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "pay_id", label: "充值ID" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "orderId", label: "订单号" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "补单时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$times.timestampToTime(
                                                      row.created_at,
                                                      "YY-MM-DD HH"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2699609748
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "passwd_secret",
                                      label: "补单类型"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.supplement.filter(
                                                      function(item) {
                                                        return (
                                                          item.value ==
                                                          row.op_type
                                                        )
                                                      }
                                                    )[0].label
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2087678176
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "reward", label: "物品" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              row.op_type !=
                                              "supplement_product"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#409EFF"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.viewCode(
                                                            row.reward
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("查看")]
                                                  )
                                                : _c("div", [_vm._v("/")])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3270072199
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "status",
                                      label: "补单状态"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    row.status == 1
                                                      ? "成功"
                                                      : "失败"
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1914892328
                                    )
                                  })
                                ],
                                1
                              ),
                              _c("el-pagination", {
                                attrs: {
                                  layout: "total, sizes, prev, pager, next",
                                  "current-page": _vm.controlsSort[0].page,
                                  total: _vm.controlsSort[0].total
                                },
                                on: {
                                  "current-change": function($event) {
                                    return _vm.handleCurrentChange($event, 0)
                                  },
                                  "size-change": function($event) {
                                    return _vm.handleCurrentChangeSizeChange(
                                      $event,
                                      0
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.controlsSortIndex == 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.reqLoadingTow,
                                      expression: "reqLoadingTow"
                                    }
                                  ],
                                  key: "lsaisnhds",
                                  attrs: {
                                    data: _vm.controlsSort[1].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "admin_name",
                                      label: "操作员"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.admin_name || "/"
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      620019531
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "expire",
                                      label: "封禁时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.expire == 0
                                                      ? "永封"
                                                      : _vm.$times.timestampToTime(
                                                          row.expire,
                                                          "YY-MM-DD HH"
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2343779222
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "reason",
                                      label: "备注",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-ellipsis-1"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.reason || "/"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2718878951
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "操作时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.created_at
                                                      ? _vm.$times.formDate(
                                                          row.created_at,
                                                          "YY-MM-DD HH"
                                                        )
                                                      : "/"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3524612050
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.controlsSortIndex == 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.reqLoadingTow,
                                      expression: "reqLoadingTow"
                                    }
                                  ],
                                  key: "asaewqdfdfw",
                                  attrs: {
                                    data: _vm.controlsSort[2].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "admin_name",
                                      label: "操作员"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.admin_name || "/"
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      620019531
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "expire",
                                      label: "封禁时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.expire == 0
                                                      ? "永封"
                                                      : _vm.$times.timestampToTime(
                                                          row.expire,
                                                          "YY-MM-DD HH"
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2343779222
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "reason",
                                      label: "备注",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-ellipsis-1"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.reason || "/"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2718878951
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "操作时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.created_at
                                                      ? _vm.$times.formDate(
                                                          row.created_at,
                                                          "YY-MM-DD HH"
                                                        )
                                                      : "/"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3524612050
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.controlsSortIndex == 3
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.reqLoadingTow,
                                      expression: "reqLoadingTow"
                                    }
                                  ],
                                  key: "dsjhdfiuwks",
                                  attrs: {
                                    data: _vm.controlsSort[3].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "admin_name",
                                      label: "操作员"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "expire",
                                      label: "操作时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$times.formDate(
                                                      row.created_at
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      724052954
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "角色详情" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "x-c" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "controlsBtn",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$refs.recoveryviewArticles.init(
                                                            row.desc,
                                                            row.server_ids
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("查看")]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3260164701
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "request_data",
                                      label: "邮件详情"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "controlsBtn",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.contentView.open(
                                                        row.request_data,
                                                        "recovery"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("查看")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2948432670
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "expire", label: "状态" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.status == 2
                                                      ? "未回收"
                                                      : "已回收"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1356978404
                                    )
                                  })
                                ],
                                1
                              ),
                              _c("el-pagination", {
                                attrs: {
                                  layout: "total,sizes, prev, pager, next",
                                  "current-page": _vm.controlsSort[3].page,
                                  total: _vm.controlsSort[3].total
                                },
                                on: {
                                  "current-change": function($event) {
                                    return _vm.handleCurrentChange($event, 3)
                                  },
                                  "size-change": function($event) {
                                    return _vm.handleCurrentChangeSizeChange(
                                      $event,
                                      3
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("addEmperor", {
        ref: "addEmperor",
        attrs: { prod: { is_prod: _vm.searchData.is_prod } },
        on: { change: _vm.emperorChange }
      }),
      _c("addEmail", {
        ref: "sendEmail",
        attrs: {
          serverList: _vm.serverLists,
          prod: { is_prod: _vm.searchData.is_prod }
        }
      }),
      _c("viewArticles", {
        ref: "viewArticles",
        attrs: { prod: { is_prod: _vm.searchData.is_prod } }
      }),
      _c("recoveryviewArticles", {
        ref: "recoveryviewArticles",
        attrs: { serverList: _vm.serverLists }
      }),
      _c("contentView", { ref: "contentView" }),
      _c("richTextDetails", { ref: "richTextDetails" }),
      _c(
        "el-drawer",
        {
          attrs: { title: "详情", visible: _vm.isCode },
          on: {
            "update:visible": function($event) {
              _vm.isCode = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px 20px 0", position: "relative" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.codeLists, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "expire", label: "时间", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$times.timestampToTime(row.expire)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "item_id", label: "物品" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.articleList.findIndex(function(item) {
                                    return item.id == row.item_id
                                  }) > -1
                                    ? _vm.articleList.find(function(item) {
                                        return item.id == row.item_id
                                      }).kind
                                    : row.item_id
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "num", label: "数量" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }