import { render, staticRenderFns } from "./addgainAdv.vue?vue&type=template&id=02765ee4&scoped=true&"
import script from "./addgainAdv.vue?vue&type=script&lang=js&"
export * from "./addgainAdv.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02765ee4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\datacenter_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02765ee4')) {
      api.createRecord('02765ee4', component.options)
    } else {
      api.reload('02765ee4', component.options)
    }
    module.hot.accept("./addgainAdv.vue?vue&type=template&id=02765ee4&scoped=true&", function () {
      api.rerender('02765ee4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/auth/gainAdv/components/addgainAdv.vue"
export default component.exports