import request from "@/utils/request";
export function summaryExeDateData(data) {
  return request({
    url: "/financial/summaryExeDateData",
    method: "get",
    params: data
  });
}
export function summaryExeDateAdd(data) {
  return request({
    url: "/financial/summaryExeDateAdd",
    method: "post",
    params: data
  });
}
export function summaryExeDateUpd(data) {
  return request({
    url: "/financial/summaryExeDateUpd",
    method: "post",
    params: data
  });
}
export function summaryExeDateDel(data) {
  return request({
    url: "/financial/summaryExeDateDel",
    method: "post",
    params: data
  });
}