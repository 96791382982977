var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      ref: "newPopover",
      attrs: { title: _vm.form.id ? "修改" : "新增", visible: _vm.dialogShow },
      on: {
        "update:visible": function($event) {
          _vm.dialogShow = $event
        },
        close: function($event) {
          return _vm.clearData()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "110px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公告类型", prop: "announce_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择公告类型" },
                  model: {
                    value: _vm.form.announce_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "announce_type", $$v)
                    },
                    expression: "form.announce_type"
                  }
                },
                _vm._l(_vm.AnnouncemenTypeListTow, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入标题" },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "内容缩略标题", prop: "content_title" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入内容缩略标题" },
                model: {
                  value: _vm.form.content_title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "content_title", $$v)
                  },
                  expression: "form.content_title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语言", prop: "language" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.language,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "language", $$v)
                    },
                    expression: "form.language"
                  }
                },
                _vm._l(_vm.language, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否置顶" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.top,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "top", $$v)
                    },
                    expression: "form.top"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "是", value: 1 } }),
                  _c("el-option", { attrs: { label: "否", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务器渠道", prop: "channel" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.channel,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "channel", $$v)
                    },
                    expression: "form.channel"
                  }
                },
                _vm._l(_vm.serverChannel, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.announce_channel_name,
                      value: item.announce_channel_mark
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.state,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "state", $$v)
                    },
                    expression: "form.state"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1, border: "" } }, [
                    _vm._v("开启")
                  ]),
                  _c("el-radio", { attrs: { label: 2, border: "" } }, [
                    _vm._v("关闭")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "有效时间", prop: "date" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  align: "right",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.form.date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "内容", prop: "content" }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  clearable: "",
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.form.content,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogShow = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "info" },
              on: {
                click: function($event) {
                  return _vm.clearData(_vm.form.id)
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitData()
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }