var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "latestToday" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: {
              propsData: { isCountry: false, isDate: false, isCurrency: true }
            },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "end" }, attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getallRecharge(true)
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "sticky-table-header",
              rawName: "v-sticky-table-header",
              value: 0,
              expression: "0"
            }
          ],
          attrs: { data: _vm.allRechargeData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "register_pnum", label: "总注册人数" }
          }),
          _c("el-table-column", {
            attrs: { prop: "recharge_amount", label: "总付费金额" }
          }),
          _vm.queryCurrency == "VND"
            ? _c("el-table-column", {
                attrs: {
                  prop: "recharge_amountexchange",
                  label: "总付费金额(美元)"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }