var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", clearable: "", placeholder: "产品" },
                  model: {
                    value: _vm.searchData.product_id,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "product_id", $$v)
                    },
                    expression: "searchData.product_id"
                  }
                },
                _vm._l(_vm.productLists, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "广告渠道"
                  },
                  model: {
                    value: _vm.searchData.advert_channel_id,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "advert_channel_id", $$v)
                    },
                    expression: "searchData.advert_channel_id"
                  }
                },
                _vm._l(_vm.advertChannelData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.advert_channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addproductBinding.init()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "custom_name", label: "名称" }
          }),
          _c("el-table-column", { attrs: { prop: "name", label: "产品" } }),
          _c("el-table-column", {
            attrs: { prop: "advert_channel_name", label: "广告渠道标志" }
          }),
          _c("el-table-column", {
            attrs: { prop: "params", label: "参数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "viewText",
                        on: {
                          click: function($event) {
                            _vm.$refs.parameterPopup.init(
                              JSON.parse(row.params)
                            )
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.$refs.addproductBinding.init(row)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete([row.id])
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("addproductBinding", {
        ref: "addproductBinding",
        attrs: {
          productLists: _vm.productLists,
          advertChannelData: _vm.advertChannelData
        },
        on: {
          refreshDataList: function($event) {
            return _vm.searchClick(_vm.searchData.page, false)
          }
        }
      }),
      _c("parameterPopup", { ref: "parameterPopup" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }