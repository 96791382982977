var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCurrency: true, isPayChannel: true } },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    filterable: "",
                    disabled: !this.tableData.length,
                    placeholder: "筛选项"
                  },
                  on: { change: _vm.screenControls },
                  model: {
                    value: _vm.screen,
                    callback: function($$v) {
                      _vm.screen = $$v
                    },
                    expression: "screen"
                  }
                },
                _vm._l(_vm.screenArr, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.prop }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.searchData.all_countries,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "all_countries", $$v)
                    },
                    expression: "searchData.all_countries"
                  }
                },
                _vm._l(_vm.countryAllList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "tipsClass",
              staticStyle: { position: "relative" },
              attrs: { span: 4 }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "自定义LTV,ROI,RR范围" },
                model: {
                  value: _vm.searchData.self_design_day,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "self_design_day", $$v)
                  },
                  expression: "searchData.self_design_day"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "tipss",
                  attrs: { effect: "dark", placement: "top" }
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: {
                      innerHTML: _vm._s(
                        "1. 单独一个数字 1 <br /> 2. 范围1-3 <br /> 3. 多个范围1-3,5-9,7-10"
                      )
                    },
                    slot: "content"
                  }),
                  _c("i", { staticClass: "el-icon-question " })
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut operationButTow", attrs: { span: 4 } },
            [
              _vm.tableData.length
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.ResetFilter }
                    },
                    [_vm._v("重置筛选")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClickNs }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !this.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length && _vm.isShow
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "tableRef",
                "cell-class-name": _vm.cellStyle,
                data: _vm.tableData,
                border: ""
              }
            },
            [
              _vm.countryShow
                ? _vm._l(_vm.countryPro, function(item, index) {
                    return _c("el-table-column", {
                      key: "desk" + item.prop,
                      attrs: {
                        sortable: "",
                        "sort-orders": ["descending", "ascending"],
                        "sort-method": function(a, b) {
                          return a[item.prop] - b[item.prop]
                        },
                        "class-name": item.classname,
                        prop: item.prop,
                        label: item.label,
                        "show-overflow-tooltip": "",
                        "render-header": function(h, obj) {
                          return _vm.renderheader(h, obj, item.desc)
                        },
                        "min-width": _vm.$utils.flexColumnWidth(
                          "国家国家国家",
                          true
                        )
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.PercentageUnit.findIndex(function(i) {
                                        return i == item.prop
                                      }) > -1
                                        ? row[item.prop] + "%"
                                        : row[item.prop]
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                : _vm._e(),
              _vm.searchData.channel == "wxminigame" && _vm.tableData.length
                ? _vm._l(_vm.wxProp, function(item, index) {
                    return _c("el-table-column", {
                      key: "desk" + item.prop,
                      attrs: {
                        sortable: "",
                        "sort-orders": ["descending", "ascending"],
                        "sort-method": function(a, b) {
                          return a[item.prop] - b[item.prop]
                        },
                        "class-name": item.classname,
                        prop: item.prop,
                        label: item.label,
                        "show-overflow-tooltip": "",
                        "render-header": function(h, obj) {
                          return _vm.renderheader(h, obj, item.desc)
                        },
                        "min-width": _vm.$utils.flexColumnWidth(
                          item.tabText,
                          true
                        )
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.PercentageUnit.findIndex(function(i) {
                                        return i == item.prop
                                      }) > -1
                                        ? row[item.prop] + "%"
                                        : row[item.prop]
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                : _vm._e(),
              _vm.queryCurrency == "VND" && _vm.tableData.length
                ? _vm._l(_vm.exchangeVND, function(item, index) {
                    return _c("el-table-column", {
                      key: "desk" + item.prop,
                      attrs: {
                        sortable: "",
                        "sort-orders": ["descending", "ascending"],
                        "sort-method": function(a, b) {
                          return a[item.prop] - b[item.prop]
                        },
                        "class-name": item.classname,
                        prop: item.prop,
                        label: item.label,
                        "show-overflow-tooltip": "",
                        "render-header": function(h, obj) {
                          return _vm.renderheader(h, obj, item.desc)
                        },
                        "min-width": _vm.$utils.flexColumnWidth(
                          item.tabText,
                          true
                        )
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " + _vm._s(row.exchange || "0.00") + " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                : _vm._e(),
              _vm._l(_vm.sericeArr, function(item, index) {
                return _c("el-table-column", {
                  key: "desk" + item.prop,
                  attrs: {
                    sortable: "",
                    fixed: item.isfixed,
                    "sort-orders": ["descending", "ascending"],
                    "sort-method": function(a, b) {
                      return a[item.prop] - b[item.prop]
                    },
                    "class-name": item.classname,
                    prop: item.prop,
                    label: item.label,
                    "show-overflow-tooltip": "",
                    "render-header": function(h, obj) {
                      return _vm.renderheader(h, obj, item.desc)
                    },
                    "min-width": _vm.$utils.flexColumnWidth(item.tabText, true)
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.PercentageUnit.findIndex(function(i) {
                                    return i == item.prop
                                  }) > -1
                                    ? row[item.prop] + "%"
                                    : row[item.prop]
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              _vm._l(_vm.otherArr, function(item, index) {
                return _c("el-table-column", {
                  key: "desk" + item.prop,
                  attrs: {
                    sortable: "",
                    fixed: item.isfixed,
                    "sort-orders": ["descending", "ascending"],
                    "sort-method": function(a, b) {
                      return a[item.prop] - b[item.prop]
                    },
                    "class-name": item.classname,
                    prop: item.prop,
                    label: item.label,
                    "show-overflow-tooltip": "",
                    "render-header": function(h, obj) {
                      return _vm.renderheader(h, obj, item.desc)
                    },
                    "min-width": _vm.$utils.flexColumnWidth(item.tabText, true)
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.PercentageUnit.findIndex(function(i) {
                                    return i == item.prop
                                  }) > -1
                                    ? row[item.prop] + "%"
                                    : row[item.prop]
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.length,
            expression: "tableData.length"
          }
        ],
        staticClass: "margin-top",
        staticStyle: { height: "850px" },
        attrs: { id: "dataSummaryScreenEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }