var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { ref: "container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.searchData.env_id,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "env_id", $$v)
                    },
                    expression: "searchData.env_id"
                  }
                },
                _vm._l(_vm.szgmEnv, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器渠道" },
                  on: { change: _vm.serverChange },
                  model: {
                    value: _vm.server_channel,
                    callback: function($$v) {
                      _vm.server_channel = $$v
                    },
                    expression: "server_channel"
                  }
                },
                _vm._l(_vm.serverChannel, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "是否展示测试服"
                  },
                  on: { change: _vm.serverChannelChange },
                  model: {
                    value: _vm.show_test,
                    callback: function($$v) {
                      _vm.show_test = $$v
                    },
                    expression: "show_test"
                  }
                },
                _vm._l(_vm.serverType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "服务器"
                  },
                  on: { change: _vm.severChange },
                  model: {
                    value: _vm.searchData.server_id,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "server_id", $$v)
                    },
                    expression: "searchData.server_id"
                  }
                },
                _vm._l(_vm.serverListYJ, function(item) {
                  return _c("el-option", {
                    key: item.serverID,
                    attrs: { label: item.serverName, value: item.serverID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("dataScreening", {
            attrs: {
              dataOptions: "pickerOptionsOnMonth",
              propsData: {
                isProduct: false,
                adChannel: false,
                adPlacement: false,
                isPayChannel: false,
                isCountry: false,
                isDate: false,
                isCurrency: true
              }
            },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "角色ID: 1001,1002"
                },
                model: {
                  value: _vm.searchData.role_id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "role_id", $$v)
                  },
                  expression: "searchData.role_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "角色昵称"
                },
                model: {
                  value: _vm.searchData.role_name,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "role_name", $$v)
                  },
                  expression: "searchData.role_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { clearable: "", filterable: "", placeholder: "账号" },
                model: {
                  value: _vm.searchData.account,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "account", $$v)
                  },
                  expression: "searchData.account"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticStyle: { width: "100px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.exportLoadingt,
                    disabled: !_vm.searchList.length
                  },
                  on: { click: _vm.exportDtl }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoadingOne },
                  on: {
                    click: function($event) {
                      return _vm.searchClick(1, true)
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoadingOne,
              expression: "reqLoadingOne"
            }
          ],
          staticClass: "marginB",
          attrs: { data: _vm.searchList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "server_id", label: "服务器ID", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "role_name", label: "角色名", width: "150" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "role_id",
              label: "角色ID",
              width: "150",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1" }, [
                      _vm._v(" " + _vm._s(row.role_id) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "account",
              label: "账号",
              width: "150",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1" }, [
                      _vm._v(" " + _vm._s(row.account || "/") + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gate_user_id",
              label: "网关用户ID",
              "show-overflow-tooltip": "",
              width: "330"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1" }, [
                      _vm._v(" " + _vm._s(row.gate_user_id || "/") + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "level", label: "声望等级", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fighting", label: "战力总和", width: "150" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recharge_amount",
              label: "充值总金额",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "real_time_amount",
              label: "实时充值总额",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "third_login_type",
              label: "第三方登录类型",
              width: "150",
              "render-header": function(h, obj) {
                return _vm.renderheader(
                  h,
                  obj,
                  "normal:自建,apple:苹果,google:谷歌,facebook:脸书,guest:游客"
                )
              }
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "device_id", label: "最近登录设备号", width: "370" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ext",
              label: "扩展参数",
              width: "300",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-ellipsis-1" }, [
                      _vm._v(" " + _vm._s(row.ext || "/") + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "register_time", label: "注册时间", width: "200" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "last_login_time",
              label: "最近登录时间",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "x-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function($event) {
                              return _vm.viewDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      ),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      row.ban_info == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function() {
                                  _vm.popData = row
                                  _vm.$refs.addEmperor.open(
                                    { uid: row.role_id },
                                    false
                                  )
                                }
                              }
                            },
                            [_vm._v("封号")]
                          )
                        : _vm._e(),
                      row.ban_info == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "controlsBtn",
                              on: {
                                click: function() {
                                  _vm.popData = row
                                  _vm.$refs.unseal.open({ uid: row.role_id })
                                }
                              }
                            },
                            [_vm._v("解封")]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "controlsBtn-vertical" }),
                      _c(
                        "div",
                        {
                          staticClass: "controlsBtn",
                          on: {
                            click: function() {
                              _vm.popData = row
                              _vm.$refs.draft.open({}, row.role_id)
                            }
                          }
                        },
                        [_vm._v("发邮件")]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.searchData.page,
          total: _vm.total
        },
        on: {
          "current-change": function($event) {
            return _vm.searchClick($event, false)
          },
          "size-change": function($event) {
            return _vm.searchClickSizeChange($event, false)
          }
        }
      }),
      _vm.detailsData.length
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticClass: "marginB",
                  attrs: { data: _vm.detailsData, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "role_name",
                      label: "角色名",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "user_id",
                      label: "用户ID",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "account",
                      label: "账号",
                      width: "150",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(" " + _vm._s(row.account || "/") + " ")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3022683586
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "gate_user_id",
                      label: "网关用户ID",
                      "show-overflow-tooltip": "",
                      width: "330"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(" " + _vm._s(row.gate_user_id) + " ")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2558494759
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "level",
                      label: "声望等级",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fighting",
                      label: "战力总和",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "league_name",
                      label: "仙盟",
                      "min-width": "160",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "recharge_amount",
                      label: "充值总金额",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "real_time_amount",
                      label: "实时充值总额",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remain_coin",
                      label: "灵晶数量",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remain_diamond",
                      label: "仙玉数量",
                      "min-width": "110"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "relevancy_role_name",
                      label: "关联账号",
                      "min-width": "160",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "text-ellipsis-1",
                                  staticStyle: { cursor: "pointer" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.relevancy_role_name || "/") +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3985923001
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "channel",
                      label: "渠道",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(row.channel_name || row.channel) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4013907267
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "last_login_country",
                      label: "最近登录地区",
                      "min-width": "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "device_id",
                      label: "最近登录设备号",
                      width: "370"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.last_logins
                                      ? row.last_logins.device_id
                                      : "/"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2441257813
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ext",
                      label: "扩展参数",
                      width: "300",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "text-ellipsis-1" }, [
                                _vm._v(" " + _vm._s(row.ext || "/") + " ")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1503039050
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "register_country",
                      label: "注册国家",
                      "min-width": "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "register_time",
                      label: "注册时间",
                      width: _vm.$utils.flexColumnWidth(
                        "2023-12-05 14:56:43",
                        false
                      )
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "last_login_time",
                      label: "最近登录时间",
                      "min-width": "210"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "country",
                      label: "国家",
                      "min-width": "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ip", label: "IP", "min-width": "140" }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "100%"
                      },
                      attrs: { span: 4.5 }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.handleCheckedCitiesChange },
                          model: {
                            value: _vm.checkedCities,
                            callback: function($$v) {
                              _vm.checkedCities = $$v
                            },
                            expression: "checkedCities"
                          }
                        },
                        _vm._l(_vm.featureList, function(item, index) {
                          return _c(
                            "el-radio-button",
                            {
                              key: index,
                              attrs: { label: item.id, border: "" }
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.checkedCities != "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptionsRadius,
                              "value-format": "yyyy-MM-dd",
                              clearable: false,
                              type: "daterange",
                              align: "right",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期"
                            },
                            on: { change: _vm.dataChange },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptionsDay,
                              "value-format": "yyyy-MM-dd",
                              editable: false,
                              clearable: false,
                              type: "date"
                            },
                            model: {
                              value: _vm.singledate,
                              callback: function($$v) {
                                _vm.singledate = $$v
                              },
                              expression: "singledate"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "资源ID"
                            },
                            model: {
                              value: _vm.propertySearchData.resource_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.propertySearchData,
                                  "resource_id",
                                  $$v
                                )
                              },
                              expression: "propertySearchData.resource_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "操作ID"
                            },
                            model: {
                              value: _vm.propertySearchData.action,
                              callback: function($$v) {
                                _vm.$set(_vm.propertySearchData, "action", $$v)
                              },
                              expression: "propertySearchData.action"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2"
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "260px" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "operationFrame-entity",
                              attrs: { clearable: "", placeholder: "变动类型" },
                              model: {
                                value: _vm.propertySearchData.kind,
                                callback: function($$v) {
                                  _vm.$set(_vm.propertySearchData, "kind", $$v)
                                },
                                expression: "propertySearchData.kind"
                              }
                            },
                            _vm._l(_vm.kindList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "2" && _vm.property.length
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "100px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.exportproperty }
                            },
                            [_vm._v("导出")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "3" && _vm.searchCurrency.length
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择货币类型"
                              },
                              model: {
                                value: _vm.orderSearchData.currency_info,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.orderSearchData,
                                    "currency_info",
                                    $$v
                                  )
                                },
                                expression: "orderSearchData.currency_info"
                              }
                            },
                            _vm._l(_vm.searchCurrency, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.currency_name,
                                  value: item.currency
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities == "3" && _vm.orderData.length
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "100px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.exportLoading
                              },
                              on: { click: _vm.exportdata }
                            },
                            [_vm._v("导出全部")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.checkedCities != "5"
                    ? _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.reqLoadingTow
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleCheckedCitiesChange(
                                    _vm.checkedCities
                                  )
                                }
                              }
                            },
                            [_vm._v("查询")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.checkedCities == "1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          key: "ksajaiqsasrfr",
                          attrs: { data: _vm.tableData, border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_channel_reveal",
                              label: "广告平台",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_placement_reveal",
                              label: "广告位置",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "server_id",
                              label: "服务器ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "device_id",
                              label: "设备号",
                              "show-overflow-tooltip": "",
                              width: "360"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "user_id",
                              label: "账号ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "role_id",
                              label: "角色ID",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "角色名称",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "desc_kind",
                              label: "物品类型",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "desc_name",
                              label: "物品名称",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "num",
                              label: "物品数量",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "last_update_time",
                              label: "更新时间",
                              "show-overflow-tooltip": "",
                              width: "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$times.timestampToTime(
                                              row.last_update_time,
                                              "YY-MM-DD HH"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2567600880
                            )
                          })
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          layout: "total,sizes, prev, pager, next",
                          "current-page": _vm.currentPage,
                          total: _vm.tableCopyTableList.length,
                          "page-size": _vm.pageSize
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange1,
                          "size-change": _vm.backSizeChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == "2"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "sdkjaoqiweq",
                          attrs: {
                            data: _vm.property,
                            border: "",
                            "max-height": "600px"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "user_id", label: "用户ID" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "role_id", label: "角色ID" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "device_id",
                              label: "设备ID",
                              width: "360"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "action", label: "操作" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "action_name", label: "操作名称" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "th", label: "时间", width: "200" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "resource_name", label: "资源名称" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "resource_id", label: "资源ID" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "kind", label: "变动类型" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "total_num", label: "变动数量" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == "3"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "sdjkahdsja",
                          attrs: { data: _vm.orderData, border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_channel_reveal",
                              label: "广告渠道",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ad_placement_reveal",
                              label: "广告位置",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "country",
                              label: "国家",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "order_id",
                              label: "研发订单ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "pay_channel_order_id",
                              label: "渠道订单ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "user_id",
                              label: "用户ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "role_id",
                              label: "角色ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "device_id",
                              label: "设备ID",
                              "show-overflow-tooltip": "",
                              "min-width": "360"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount",
                              label: "付费金额",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$utils
                                              .conversion(row.amount)
                                              .toFixed(2)
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2165437223
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "product_id",
                              label: "充值商品ID",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "product_name",
                              label: "充值商品名称",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "timestamp",
                              label: "充值时间",
                              "show-overflow-tooltip": "",
                              "min-width": "200"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ip",
                              label: "IP",
                              "show-overflow-tooltip": "",
                              "min-width": "150"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          layout: "total,sizes, prev, pager, next",
                          "current-page": _vm.orderSearchData.page,
                          total: _vm.orderTotal
                        },
                        on: {
                          "current-change": _vm.orderhandleCurrentChange,
                          "size-change": _vm.orderhandleCurrentChangeSizeChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == "4"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.reqLoadingTow,
                              expression: "reqLoadingTow"
                            }
                          ],
                          key: "asasikdjq",
                          attrs: { data: _vm.emailList, border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "id",
                              label: "ID",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sender",
                              label: "发送者",
                              "min-width": "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "text-ellipsis-1" },
                                        [_vm._v(_vm._s(row.sender))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3402713671
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: "邮件标题",
                              "min-width": "180",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "text-ellipsis-1" },
                                        [_vm._v(_vm._s(row.title))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2128057068
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "email_type",
                              label: "邮件类型",
                              "min-width": "120"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.emailType.find(function(item) {
                                              return (
                                                item.value == row.email_type
                                              )
                                            }).label
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              603777189
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "reward",
                              label: "邮件附件",
                              "min-width": "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#409EFF",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.viewArticles.init(
                                                row.reward
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("查看")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2004550400
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "language",
                              label: "语言",
                              "min-width": "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.Language.filter(function(item) {
                                              return item.value == row.language
                                            })[0].label
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1598020169
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "email_user_ids",
                              label: "目标账号",
                              "min-width": "160",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-ellipsis-1",
                                          staticStyle: { width: "140px" }
                                        },
                                        [_vm._v(_vm._s(row.email_user_ids))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4143997457
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "times",
                              label: "生成账号时间范围",
                              "min-width": "220"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "created_at",
                              label: "添加时间",
                              "min-width": "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$times.formDate(row.created_at)
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              724052954
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expire",
                              label: "过期时间",
                              "min-width": "180"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "expire", label: "详情" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#409EFF",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.emailDetils.init(
                                                row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("查看")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2518115788
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expire",
                              label: "邮件状态",
                              "min-width": "250"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      row.state == 2 && row.delay_state == 2
                                        ? _c("div", [_vm._v("普通任务未发送")])
                                        : _vm._e(),
                                      row.state == 2 && row.delay_state == 1
                                        ? _c("div", [
                                            _vm._v(
                                              "定时 " +
                                                _vm._s(
                                                  _vm.$times.timestampToTime(
                                                    row.delay_time,
                                                    "YY-MM-DD hh:mm:ss"
                                                  )
                                                ) +
                                                " 发送"
                                            )
                                          ])
                                        : _vm._e(),
                                      (row.state == 1 &&
                                        row.delay_state == 1) ||
                                      (row.state == 1 && row.delay_state == 2)
                                        ? _c("div", [
                                            _vm._v(
                                              "已发送 " +
                                                _vm._s(
                                                  _vm.$times.timestampToTime(
                                                    row.send_time,
                                                    "YY-MM-DD hh:mm:ss"
                                                  )
                                                )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              470001773
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.checkedCities == 5
                ? _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                height: "100%"
                              },
                              attrs: { span: 4.5 }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  on: { change: _vm.operationLogChange },
                                  model: {
                                    value: _vm.controlsSortIndex,
                                    callback: function($$v) {
                                      _vm.controlsSortIndex = $$v
                                    },
                                    expression: "controlsSortIndex"
                                  }
                                },
                                _vm._l(_vm.controlsSort, function(item, index) {
                                  return _c(
                                    "el-radio-button",
                                    {
                                      key: index,
                                      attrs: { label: index, border: "" }
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    height: "28px",
                                    "line-height": "12px"
                                  },
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.reqLoadingTow,
                                    size: "mini"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.operationLogChange(
                                        _vm.controlsSortIndex
                                      )
                                    }
                                  }
                                },
                                [_vm._v("查询")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.controlsSortIndex == 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.reqLoadingTow,
                                      expression: "reqLoadingTow"
                                    }
                                  ],
                                  key: "asaewqw1dc",
                                  attrs: {
                                    data: _vm.controlsSort[0].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "role_id", label: "玩家ID" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "user_id", label: "用户" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "order_id", label: "订单ID" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "level", label: "等级" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "amount", label: "金额" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "product_id",
                                      label: "商品ID"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "product_name",
                                      label: "商品名称"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "timestamp",
                                      label: "补单时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$times.timestampToTime(
                                                      row.timestamp,
                                                      "YY-MM-DD HH"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1164856144
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "ip", label: "IP" }
                                  })
                                ],
                                1
                              ),
                              _c("el-pagination", {
                                attrs: {
                                  layout: "total,sizes, prev, pager, next",
                                  "current-page": _vm.controlsSort[0].page,
                                  total: _vm.controlsSort[0].total
                                },
                                on: {
                                  "current-change": function($event) {
                                    return _vm.handleCurrentChange($event, 0)
                                  },
                                  "size-change": function($event) {
                                    return _vm.handleCurrentChangeSizeChange(
                                      $event,
                                      0
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.controlsSortIndex == 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.reqLoadingTow,
                                      expression: "reqLoadingTow"
                                    }
                                  ],
                                  key: "lsaisnhds",
                                  attrs: {
                                    data: _vm.controlsSort[1].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "admin_name",
                                      label: "操作员"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.admin_name || "/"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3883411047
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "uid", label: "玩家ID" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "op_type", label: "操作" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.status == 2
                                                      ? "解封"
                                                      : "封禁"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      378567966
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "expire_time",
                                      label: "封禁时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.status == 2
                                                      ? "/"
                                                      : row.expire_time == 0
                                                      ? "永封"
                                                      : _vm.$times.timestampToTime(
                                                          row.expire_time,
                                                          "YY-MM-DD HH"
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      340510206
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "expire_time",
                                      label: "操作时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$times.timestampToTime(
                                                      row.time,
                                                      "YY-MM-DD HH"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      513827243
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "reason",
                                      label: "操作原因",
                                      width: "600",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row.reason || "/") +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2917784532
                                    )
                                  })
                                ],
                                1
                              ),
                              _c("el-pagination", {
                                attrs: {
                                  layout: "total,sizes, prev, pager, next",
                                  "current-page": _vm.controlsSort[1].page,
                                  total: _vm.controlsSort[1].total
                                },
                                on: {
                                  "current-change": function($event) {
                                    return _vm.handleCurrentChange($event, 1)
                                  },
                                  "size-change": function($event) {
                                    return _vm.handleCurrentChangeSizeChange(
                                      $event,
                                      1
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.controlsSortIndex == 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  key: "asaewqdfdfw",
                                  attrs: {
                                    data: _vm.controlsSort[2].list,
                                    border: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "admin_name",
                                      label: "操作员"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.admin_name || "/"
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      620019531
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "expire",
                                      label: "封禁时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.expire == 0
                                                      ? "永封"
                                                      : _vm.$times.timestampToTime(
                                                          row.expire,
                                                          "YY-MM-DD HH"
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2343779222
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "reason",
                                      label: "备注",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-ellipsis-1"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.reason || "/"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2718878951
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "操作时间"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.created_at
                                                      ? _vm.$times.formDate(
                                                          row.created_at,
                                                          "YY-MM-DD HH"
                                                        )
                                                      : "/"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3524612050
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("draft", {
        ref: "draft",
        attrs: {
          propsData: {
            server_id: _vm.popData.server_id,
            env_id: _vm.searchData.env_id
          }
        }
      }),
      _c("addEmperor", {
        ref: "addEmperor",
        attrs: {
          propsData: {
            server_id: _vm.popData.server_id,
            env_id: _vm.searchData.env_id
          }
        },
        on: { change: _vm.addEmperorChange }
      }),
      _c("unseal", {
        ref: "unseal",
        attrs: {
          propsData: {
            server_id: _vm.popData.server_id,
            env_id: _vm.searchData.env_id
          }
        },
        on: { change: _vm.addEmperorChange }
      }),
      _c("viewArticles", { ref: "viewArticles" }),
      _c("emailDetils", { ref: "emailDetils" }),
      _c(
        "el-drawer",
        {
          attrs: { title: "详情", visible: _vm.isCode },
          on: {
            "update:visible": function($event) {
              _vm.isCode = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px 20px 0", position: "relative" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.codeLists, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "expire", label: "时间", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$times.timestampToTime(row.expire)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "item_id", label: "物品" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.articleList.findIndex(function(item) {
                                    return item.id == row.item_id
                                  }) > -1
                                    ? _vm.articleList.find(function(item) {
                                        return item.id == row.item_id
                                      }).kind
                                    : row.item_id
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "num", label: "数量" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }