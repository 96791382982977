var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCountry: false } },
            on: { searchChange: _vm.searchChange }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "用户类型"
                  },
                  model: {
                    value: _vm.searchData.is_new_register,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData, "is_new_register", $$v)
                    },
                    expression: "searchData.is_new_register"
                  }
                },
                _vm._l(_vm.userTypes, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择服务器ID"
                      },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: { filterable: "", placeholder: "请选择环境" },
                      model: {
                        value: _vm.searchData.env_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "env_id", $$v)
                        },
                        expression: "searchData.env_id"
                      }
                    },
                    _vm._l(_vm.szgmEnv, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "服务器渠道" },
                      on: { change: _vm.serverChange },
                      model: {
                        value: _vm.server_channel,
                        callback: function($$v) {
                          _vm.server_channel = $$v
                        },
                        expression: "server_channel"
                      }
                    },
                    _vm._l(_vm.serverChannel, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "是否展示测试服"
                      },
                      on: { change: _vm.serverChannelChange },
                      model: {
                        value: _vm.show_test,
                        callback: function($$v) {
                          _vm.show_test = $$v
                        },
                        expression: "show_test"
                      }
                    },
                    _vm._l(_vm.serverType, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "服务器"
                      },
                      on: { change: _vm.severChange },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverListYJ, function(item) {
                      return _c("el-option", {
                        key: item.serverID,
                        attrs: { label: item.serverName, value: item.serverID }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClicknew }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                }
              ],
              ref: "tableRef",
              attrs: { id: "tableRef", data: _vm.tableData, border: "" }
            },
            _vm._l(_vm.Dynamic, function(item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  fixed: item.prop == "length",
                  prop: item.prop,
                  label: item.label,
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              })
            }),
            1
          )
        : _vm._e(),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "500px" },
        attrs: { id: "onlineDurationEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }