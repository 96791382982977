var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: { propsData: { isCurrency: true } },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            {
              staticClass: "tipsClass",
              staticStyle: { position: "relative" },
              attrs: { span: 4 }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "自定义范围" },
                model: {
                  value: _vm.searchData.self_design_day,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "self_design_day", $$v)
                  },
                  expression: "searchData.self_design_day"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "tipss",
                  attrs: { effect: "dark", placement: "top" }
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: {
                      innerHTML: _vm._s(
                        "1. 单独一个数字 1 <br /> 2. 范围1-3 <br /> 3. 多个范围1-3,5-9,7-10"
                      )
                    },
                    slot: "content"
                  }),
                  _c("i", { staticClass: "el-icon-question " })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.searchClickNs }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "tableRef",
                "cell-class-name": _vm.cellStyle,
                data: _vm.tableData,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  fixed: "",
                  label: "日期",
                  "min-width": "105"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "dateClass" }, [
                            _vm._v(_vm._s(row.date))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  560306523
                )
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "recharge_all",
                  label: "内购纯收入.Earnings",
                  "sort-method": function(a, b) {},
                  "render-header": function(h, obj) {
                    return _vm.renderheader(
                      h,
                      obj,
                      "当日新增用户截至周期内累计内购收入"
                    )
                  },
                  "min-width": _vm.$utils.flexColumnWidth("内购纯入", true)
                }
              }),
              _vm.queryCurrency == "VND"
                ? _vm._l(_vm.exchangeVND, function(item, index) {
                    return _c("el-table-column", {
                      key: "desk" + item.prop,
                      attrs: {
                        sortable: "",
                        "sort-orders": ["descending", "ascending"],
                        "sort-method": function(a, b) {
                          return a[item.prop] - b[item.prop]
                        },
                        "class-name": item.classname,
                        prop: item.prop,
                        label: item.label,
                        "show-overflow-tooltip": "",
                        "render-header": function(h, obj) {
                          return _vm.renderheader(h, obj, item.desc)
                        },
                        "min-width": _vm.$utils.flexColumnWidth(
                          item.tabText,
                          true
                        )
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " + _vm._s(row.exchange || "0.00") + " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "cost",
                  label: "实际消耗.PAY",
                  "sort-method": function(a, b) {
                    return a.cost - b.cost
                  },
                  "render-header": _vm.renderheader,
                  "min-width": _vm.$utils.flexColumnWidth("内购纯", true)
                }
              }),
              _vm._l(_vm.cyclicData, function(item, index) {
                return _c("el-table-column", {
                  key: "rois" + index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    "render-header": function(h, obj) {
                      return _vm.renderheader(h, obj, item.desc)
                    },
                    "min-width": _vm.$utils.flexColumnWidth(
                      item.tableText + "内购",
                      false
                    )
                  }
                })
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "500px" },
        attrs: { id: "roiPirateEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }