import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import { serchSelect, tabulation, gmToolNewPage } from "@/mixins/index";
import { outputAnalysis } from "@/api/dataAll/new";
import { serverList as hdServerList } from "@/api/gmtool/packageCode";
import { serverList as yjServerList } from "@/api/gmToolNew/gmToolNew";
export default {
  name: "outputAnalysis",
  mixins: [serchSelect, tabulation, gmToolNewPage],
  data: function data() {
    return {
      searchData: {
        action: "",
        resources: "",
        server_id: ""
      },
      serverLists: [],
      tableData: [],
      Dynamic: [],
      isServer: false
    };
  },
  created: function created() {
    this.getServerLists();
    this.isServer = this.rawData.game_mark.indexOf("royalpirates") > -1 || this.rawData.game_mark.indexOf("yjfm") > -1 ? true : false;
  },
  computed: _objectSpread({}, mapState("d2admin", {
    severlist: function severlist(state) {
      return state.user.GMauthority;
    }
  })),
  methods: {
    //请求服务器列表  用于指定海盗和御剑请求
    getServerLists: function getServerLists() {
      this.RollSuit = false;
      this.serverLists = [];
      this.searchData.server_id = "";
      this.searchData.is_prod = "";
      var game = this.rawData.game_mark;

      if (game.indexOf(this.royalpirates) > -1) {
        this.RollSuit = this.severlist.length ? true : false;
      } else if (game.indexOf("yjfm") > -1) {
        this.RollSuit = true;
      }
    },
    severlistChange: function severlistChange(e) {
      var _this = this;

      this.serverLists = [];
      this.searchData.server_id = "";

      if (e) {
        hdServerList({
          is_prod: this.searchData.is_prod
        }).then(function (res) {
          if (res.status == 200) {
            _this.serverLists = res.data;
          }
        });
      }
    },
    searchClick: function searchClick() {
      var _this2 = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      var formdata = JSON.parse(JSON.stringify(this.searchData));

      if (formdata.resources) {
        var str = formdata.resources.split(",");
        var strOne = "";
        str.map(function (i) {
          if (i) {
            strOne += i + ",";
          }
        });
        formdata.resources = strOne.substring(0, strOne.length - 1);
      }

      if (formdata.action) {
        var strT = formdata.action.split(",");
        var strTow = "";
        strT.map(function (i) {
          if (i) {
            strTow += i + ",";
          }
        });
        formdata.action = strTow.substring(0, strTow.length - 1);
      }

      this.reqLoading = true;
      outputAnalysis(formdata).then(function (res) {
        if (res.status == 200) {
          var objToTitle = function objToTitle(obj) {
            return Object.keys(obj).map(function (k) {
              return obj[k];
            });
          };

          var objToKey = function objToKey(obj) {
            return Object.keys(obj).map(function (k) {
              return k;
            });
          };

          var pageData = res.data;
          var isServer = _this2.searchData.server_id.length ? true : false;
          var columns = isServer ? [{
            label: "物品",
            prop: "items"
          }] : [{
            label: "操作",
            prop: "controls"
          }, {
            label: "物品",
            prop: "items"
          }];
          var data = [];
          var number = {
            controls: "总数",
            items: isServer ? "总数" : ""
          };
          var complete = {
            controls: "",
            items: ""
          };
          var listNum = 0;
          pageData.map(function (item, index) {
            var k = objToKey(item)[0];
            var list = formdata.resources ? [{
              label: k,
              prop: index + "userid"
            }, {
              label: "",
              prop: index + "upMoney"
            }, {
              label: "",
              prop: index + "avgNum"
            }] : [{
              label: k,
              prop: index + "userid"
            }, {
              label: "",
              prop: index + "upMoney"
            }];
            columns.push.apply(columns, list);
            number[index + "userid"] = +item[k].sum_output;
            complete[index + "userid"] = "数量";
            complete[index + "upMoney"] = "占比";

            if (formdata.resources) {
              complete[index + "avgNum"] = "平均产出资源数量";
            }

            var IN = -1;

            for (var i in item[k].resource) {
              data.push({});
              IN += 1;
              listNum = IN;
              var j = i.split(":");

              var taskData = _defineProperty({
                controls: j[0],
                // items: isServer ? j[0] : j[1],
                items: item[k].resource[i].item_name
              }, "controls", item[k].resource[i].action_name);

              taskData[index + "userid"] = item[k].resource[i].out_num;
              taskData[index + "upMoney"] = item[k].resource[i].ratio;

              if (formdata.resources) {
                taskData[index + "avgNum"] = item[k].resource[i].avgNum;
              }

              data[IN] = _objectSpread(_objectSpread({}, data[IN]), taskData);
            }
          }); // for (let n = 0; n < parseInt((columns.length - 1) / 2); n++) {
          //   merges.push([this.headerZh(n, 0), this.headerZh(n, 1)])
          //   mergesTow.push([this.towHeaderZh(n, 0), this.towHeaderZh(n, 1)])
          // }

          data.unshift(complete);
          data.unshift(number);
          data.splice(listNum + 3, data.length - listNum + 3);
          _this2.tableData = data;
          _this2.Dynamic = columns;

          _this2.$nextTick(function () {
            _this2.getTableRight();
          });
        }
      }).finally(function () {
        _this2.reqLoading = false;
      });
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Dynamic,
        data: this.tableData,
        title: this.$route.meta.title
      });
    },
    headerZh: function headerZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      var num = k > 0 ? letterTable[k - 1] : "";

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[(k <= 1 ? index * 2 : index) + 1];
        } else {
          num = num + letterTable[(k <= 1 ? index * 2 + 1 : index + 1) + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2 + 1];
        } else {
          num = num + letterTable[index % 13 * 2 + 1 + 1];
        }
      }

      return num + "1";
    },
    towHeaderZh: function towHeaderZh(index, nIndex) {
      var k = parseInt(index / 13);
      var letterTable = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      var num = k > 0 ? letterTable[k - 1] : "";

      if (k < 1) {
        if (nIndex == 0) {
          num = num + letterTable[(k <= 1 ? index * 2 : index) + 1];
        } else {
          num = num + letterTable[(k <= 1 ? index * 2 + 1 : index + 1) + 1];
        }
      } else {
        if (nIndex == 0) {
          num = num + letterTable[index % 13 * 2 + 1];
        } else {
          num = num + letterTable[index % 13 * 2 + 1 + 1];
        }
      }

      return num + "2";
    }
  }
};