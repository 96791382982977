var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { editorBox: _vm.fullscreen }, staticStyle: { width: "100%" } },
    [
      _c("quill-editor", {
        ref: "myQuillEditor",
        staticClass: "editor",
        attrs: { options: _vm.quillOption },
        on: {
          change: function($event) {
            return _vm.onEditorChange($event)
          }
        },
        model: {
          value: _vm.currentValue,
          callback: function($$v) {
            _vm.currentValue = $$v
          },
          expression: "currentValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }