var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.paramsData.env_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "env_id", $$v)
                    },
                    expression: "paramsData.env_id"
                  }
                },
                _vm._l(_vm.szgmEnv, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器渠道" },
                  on: { change: _vm.serverChange },
                  model: {
                    value: _vm.server_channel,
                    callback: function($$v) {
                      _vm.server_channel = $$v
                    },
                    expression: "server_channel"
                  }
                },
                _vm._l(_vm.serverChannel, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "是否展示测试服"
                  },
                  on: { change: _vm.serverChannelChange },
                  model: {
                    value: _vm.show_test,
                    callback: function($$v) {
                      _vm.show_test = $$v
                    },
                    expression: "show_test"
                  }
                },
                _vm._l(_vm.serverType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器" },
                  on: { change: _vm.severChange },
                  model: {
                    value: _vm.paramsData.server_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "server_id", $$v)
                    },
                    expression: "paramsData.server_id"
                  }
                },
                _vm._l(_vm.serverListYJ, function(item) {
                  return _c("el-option", {
                    key: item.serverID,
                    attrs: { label: item.serverName, value: item.serverID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "游戏UID", clearable: "" },
                model: {
                  value: _vm.paramsData.uid,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "uid", $$v)
                  },
                  expression: "paramsData.uid"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "订单ID", clearable: "" },
                model: {
                  value: _vm.paramsData.order_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "order_id", $$v)
                  },
                  expression: "paramsData.order_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "商品ID", clearable: "" },
                model: {
                  value: _vm.paramsData.product_id,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "product_id", $$v)
                  },
                  expression: "paramsData.product_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "支付方式"
                  },
                  model: {
                    value: _vm.paramsData.pay_type,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "pay_type", $$v)
                    },
                    expression: "paramsData.pay_type"
                  }
                },
                _vm._l(_vm.payType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportdata } },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function() {
                      _vm.serverJud().then(function() {
                        _vm.$refs.addReplenishment.open({}, false)
                      })
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.reqLoading,
              expression: "reqLoading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "admin_name", label: "操作员" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.admin_name || "/") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "uid", label: "游戏UID" } }),
          _c("el-table-column", {
            attrs: { prop: "order_id", label: "订单ID", width: "370" }
          }),
          _c("el-table-column", {
            attrs: { prop: "pay_channel_order_id", label: "支付渠道订单" }
          }),
          _c("el-table-column", {
            attrs: { prop: "product_id", label: "商品ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "pay_type", label: "支付方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.payType.find(function(i) {
                            return i.value == row.pay_type
                          }).label
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "reward", label: "物品", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#409EFF", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.viewArticles.init(row.reward)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "expire_time", label: "补单时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$times.timestampToTime(
                            row.created_at,
                            "YY-MM-DD HH"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      }),
      _c("addReplenishment", {
        ref: "addReplenishment",
        attrs: {
          propsData: {
            server_id: _vm.paramsData.server_id,
            env_id: _vm.paramsData.env_id
          }
        },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      }),
      _c("viewArticles", { ref: "viewArticles" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }