import "core-js/modules/es.array.for-each";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
//
//
//
//
//
//
//
import { Quill, quillEditor } from "vue-quill-editor"; //调用编辑器

import quillConfig from './quillConfig';
import { ImageDrop } from 'quill-image-drop-module'; // 图片拖动组件引用

import imageResize from 'quill-image-resize-module'; // 图片缩放组件引用

Quill.register('modules/imageDrop', ImageDrop); // 注册

Quill.register('modules/imageResize', imageResize); // 注册.

export default {
  components: {
    quillEditor: quillEditor
  },
  name: "Editor",
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: ""
    },

    /* 高度 */
    height: {
      type: Number,
      default: null
    },

    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null
    },

    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 2
    },

    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url"
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: function _default() {
        return ["png", "jpg", "jpeg"];
      }
    }
  },
  data: function data() {
    return {
      Quill: null,
      currentValue: "",
      quillOption: quillConfig,
      fullscreen: false
    };
  },
  computed: {},
  watch: {
    value: {
      handler: function handler(val) {
        this.currentValue = val;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {},
  methods: {
    init: function init() {
      var _this = this;

      this.$nextTick(function () {
        var that = _this; //  自定义粘贴图片功能

        var quill = _this.$refs.myQuillEditor.quill;

        _this.$forceUpdate();

        quill.root.addEventListener('paste', function (evt) {
          console.log(evt);

          if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, function (file) {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }

              _this.uploadToServer(file, function (res) {
                // 获取光标内容
                var range = quill.getSelection();

                if (range) {
                  console.log(res, file); // this.uploadAttachment(res, file, null)
                  //  将光标移动到图片后面

                  _this.$refs.myQuillEditor.quill.setSelection(range.index + 1);
                }
              });
            });
          }
        }, false);
        var custoButton = document.querySelector('.ql-omega');
        custoButton.addEventListener('click', function () {
          that.fullscreen = !that.fullscreen;
        });
      });
    },
    onEditorChange: function onEditorChange(e) {
      this.currentValue = e.html;
      this.$emit("onEditorChange", e.html);
    }
  }
};