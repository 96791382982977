import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, serverChanner, gmToolNewPage } from "@/mixins/index";
import { gameAnnounceData, gameAnnounceDel } from "@/api/gmToolNew/gmToolNew";
import addInGame from "./components/addInGame";
export default {
  name: "InGame",
  mixins: [loadMoreList, serverChanner, gmToolNewPage],
  components: {
    addInGame: addInGame
  },
  data: function data() {
    return {
      pagingReqFn: gameAnnounceData,
      pagingReqValue: "data",
      paramsData: {
        announce_type: '',
        language: '',
        state: '0'
      },
      drawer: false,
      content: '',
      delIds: [],
      state: [{
        label: '全部',
        value: '0'
      }, {
        label: '启用',
        value: 1
      }, {
        label: '禁用',
        value: 2
      }],
      top: [{
        label: '全部',
        value: ''
      }, {
        label: '置顶',
        value: 1
      }, {
        label: '不置顶',
        value: 0
      }]
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(e) {
      this.delIds = e;
    },
    viewOpen: function viewOpen(content) {
      this.content = this.$utils.textRepl(content);
      this.drawer = true;
    },
    del: function del(dateList) {
      var _this = this;

      var ids = dateList.map(function (item) {
        return item.id;
      });
      this.$store.dispatch('d2admin/method/deleteList', {
        req: gameAnnounceDel,
        data: {
          id: ids.toString(),
          env_id: this.paramsData.env_id
        }
      }).then(function () {
        _this.getList();
      });
    }
  }
};