var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCode" },
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.isUpd ? "修改服务器" : "新增服务器",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                model: _vm.paramsData,
                rules: _vm.rules,
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "serverID", label: "服务器ID" }
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isUpd, placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.serverID,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "serverID", $$v)
                      },
                      expression: "paramsData.serverID"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "serverName", label: "服务器名称" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.serverName,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "serverName", $$v)
                      },
                      expression: "paramsData.serverName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "serverIP", label: "服务器IP" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.serverIP,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "serverIP", $$v)
                      },
                      expression: "paramsData.serverIP"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "serverPort", label: "服务器端口号" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.serverPort,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "serverPort", $$v)
                      },
                      expression: "paramsData.serverPort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "areaID", label: "服务器所属大区" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        disabled: _vm.isUpd,
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.areaID,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "areaID", $$v)
                        },
                        expression: "paramsData.areaID"
                      }
                    },
                    _vm._l(_vm.serverChannel, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "config_state", label: "服务器状态" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.config_state,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "config_state", $$v)
                        },
                        expression: "paramsData.config_state"
                      }
                    },
                    _vm._l(_vm.configState, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "is_timing", label: "定时修改" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        disabled: !_vm.isUpd,
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.is_timing,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "is_timing", $$v)
                        },
                        expression: "paramsData.is_timing"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: 1 } }),
                      _c("el-option", { attrs: { label: "否", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.paramsData.is_timing == 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: { prop: "time_to_run", label: "定时时间" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          "value-format": "timestamp",
                          type: "datetime",
                          placeholder: "日期"
                        },
                        model: {
                          value: _vm.paramsData.time_to_run,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "time_to_run", $$v)
                          },
                          expression: "paramsData.time_to_run"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "开服时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "日期"
                    },
                    model: {
                      value: _vm.paramsData.OpenTime,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "OpenTime", $$v)
                      },
                      expression: "paramsData.OpenTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "new_tag", label: "是否是新服" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.new_tag,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "new_tag", $$v)
                        },
                        expression: "paramsData.new_tag"
                      }
                    },
                    _vm._l(_vm.newTag, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "is_recomm", label: "推荐服务器标记" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.paramsData.is_recomm,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "is_recomm", $$v)
                        },
                        expression: "paramsData.is_recomm"
                      }
                    },
                    _vm._l(_vm.recommList, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "ver_max", label: "最高版本号" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.ver_max,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "ver_max", $$v)
                      },
                      expression: "paramsData.ver_max"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "ver_min", label: "最低版本号" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.paramsData.ver_min,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "ver_min", $$v)
                      },
                      expression: "paramsData.ver_min"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }