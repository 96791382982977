var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.paramsData.env_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "env_id", $$v)
                    },
                    expression: "paramsData.env_id"
                  }
                },
                _vm._l(_vm.szgmEnv, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器渠道" },
                  on: { change: _vm.serverChange },
                  model: {
                    value: _vm.server_channel,
                    callback: function($$v) {
                      _vm.server_channel = $$v
                    },
                    expression: "server_channel"
                  }
                },
                _vm._l(_vm.serverChannel, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "是否展示测试服"
                  },
                  on: { change: _vm.serverChannelChange },
                  model: {
                    value: _vm.show_test,
                    callback: function($$v) {
                      _vm.show_test = $$v
                    },
                    expression: "show_test"
                  }
                },
                _vm._l(_vm.serverType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "服务器" },
                  on: { change: _vm.severChange },
                  model: {
                    value: _vm.paramsData.server_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "server_id", $$v)
                    },
                    expression: "paramsData.server_id"
                  }
                },
                _vm._l(_vm.serverListYJ, function(item) {
                  return _c("el-option", {
                    key: item.serverID,
                    attrs: { label: item.serverName, value: item.serverID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "玩家ID", clearable: "" },
                model: {
                  value: _vm.paramsData.uid,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "uid", $$v)
                  },
                  expression: "paramsData.uid"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "玩家名称", clearable: "" },
                model: {
                  value: _vm.paramsData.uname,
                  callback: function($$v) {
                    _vm.$set(_vm.paramsData, "uname", $$v)
                  },
                  expression: "paramsData.uname"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptionsRadius,
                  "value-format": "timestamp",
                  type: "datetimerange",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间"
                },
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getList(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.exportdataDealoading
                      },
                      on: { click: _vm.exportdata }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "uid", label: "玩家ID", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { prop: "uname", label: "玩家名称", width: "200" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "recharge_amount",
                  label: "累计充值金额(元)",
                  width: "160"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s((row.recharge_amount / 100).toFixed(2)) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2457107950
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "uname", label: "注册时间", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.register
                                    ? row.register.register_time
                                      ? _vm.$times.timestampToTime(
                                          row.register.register_time,
                                          "YY-MM-DD HH"
                                        )
                                      : "/"
                                    : "/"
                                ) +
                                " "
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3821714587
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "time", label: "违规时间", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$times.timestampToTime(
                                  row.time,
                                  "YY-MM-DD HH"
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  209519339
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reason",
                  label: "违规原因",
                  "show-overflow-tooltip": "",
                  width: "400"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "text-ellipsis-1" }, [
                            _vm._v(_vm._s(row.reason))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  569602408
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", width: "280" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.status == 1
                            ? _c("div", [_vm._v("未处罚")])
                            : _vm._e(),
                          row.status == 2
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "封禁中 - " +
                                    _vm._s(
                                      _vm.$times.timestampToTime(
                                        row.expire_time,
                                        "YY-MM-DD HH"
                                      )
                                    )
                                )
                              ])
                            : _vm._e(),
                          row.status == 3
                            ? _c(
                                "div",
                                { staticStyle: { color: "goldenrod" } },
                                [
                                  _vm._v(
                                    "封禁结束 - " +
                                      _vm._s(
                                        _vm.$times.timestampToTime(
                                          row.expire_time,
                                          "YY-MM-DD HH"
                                        )
                                      )
                                  )
                                ]
                              )
                            : _vm._e(),
                          row.status == 4
                            ? _c("div", [
                                _vm._v(
                                  "已解封 - " +
                                    _vm._s(
                                      _vm.$times.timestampToTime(
                                        row.expire_time,
                                        "YY-MM-DD HH"
                                      )
                                    )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4032507749
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "reason", label: "操作封禁记录", width: "400" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.ban_state.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top-start"
                                      }
                                    },
                                    [
                                      _c("div", {
                                        attrs: { slot: "content" },
                                        domProps: {
                                          innerHTML: _vm._s(row.html)
                                        },
                                        slot: "content"
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "y-start" },
                                        _vm._l(row.ban_state, function(
                                          item,
                                          index
                                        ) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-ellipsis-1",
                                                staticStyle: { width: "380px" },
                                                style: {
                                                  color: item.finishs
                                                    ? "red"
                                                    : "goldenrod"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " 操作时间:" +
                                                    _vm._s(item.timenew) +
                                                    ",封禁结束时间:" +
                                                    _vm._s(
                                                      item.expire_timenew
                                                    ) +
                                                    ",原因" +
                                                    _vm._s(item.reason) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("div", [_vm._v(" / ")])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3030730677
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "abnormal_count",
                  label: "异常次数",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "160", fixed: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "x-c" }, [
                            row.op_type == 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.addEmperor.open(
                                          row,
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("处罚")]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.addEmperor.open(
                                          row,
                                          true,
                                          "up"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                ),
                            row.op_type == 1
                              ? _c("div", {
                                  staticClass: "controlsBtn-vertical"
                                })
                              : _vm._e(),
                            row.op_type == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "controlsBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleHandle(row.uid)
                                      }
                                    }
                                  },
                                  [_vm._v("解封")]
                                )
                              : _vm._e()
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3984177352
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total,sizes, prev, pager, next",
              "current-page": _vm.paramsData.page,
              total: _vm.total
            },
            on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
          })
        : _vm._e(),
      _c("addEmperor", {
        ref: "addEmperor",
        attrs: {
          propsData: {
            server_id: _vm.paramsData.server_id,
            env_id: _vm.paramsData.env_id
          }
        },
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }