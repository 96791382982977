import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import "D:\\work\\admin\\datacenter_web\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\work\\admin\\datacenter_web\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\work\\admin\\datacenter_web\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\work\\admin\\datacenter_web\\node_modules\\core-js\\modules\\es.promise.finally.js";
// Vue
import Vue from "vue";
import i18n from "./i18n";
import App from "./App"; // 核心插件

import d2Admin from "@/plugin/d2admin"; // store

import store from "@/store/index";
import "@/assets/style/Custom.scss"; // global css

import "@/styles/font/font.css";
import times from "@/utils/changTime";
Vue.prototype.$times = times;
import utils from "@/utils/utils";
Vue.prototype.$utils = utils;
import uploadImage from "@/components/uploadImage";
import unpack from "@/components/unpack";
Vue.component("uploadImage", uploadImage);
Vue.component("unpack", unpack); // 弹窗组件

import customBox from "@/components/customBox";
Vue.component("custom-box", customBox);
import dataScreening from "@/components/dataScreening";
Vue.component("dataScreening", dataScreening);
import indexDb from '@/utils/indexdDB';
var dbInstance = new indexDb();
dbInstance.init().then(function (db) {
  console.log('数据库初始化成功', db); // 在这里可以进行后续的数据库操作
}).catch(function (error) {
  console.error('数据库初始化失败', error);
});
Vue.prototype.$DBControls = dbInstance;
import VueParticles from "vue-particles";
Vue.use(VueParticles); // 菜单和路由设置

import router from "./router";
import { menuHeader, menuAside } from "@/menu";
import { frameInRoutes } from "@/router/routes";
import dataV from "@jiaminghi/data-view";

var echarts = require("echarts"); // 复制插件


import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
Vue.use(dataV); // 注册指令

import stickyTableHeader from "@/utils/sticky-table-header";
Vue.directive("stickyTableHeader", stickyTableHeader);
import bus from "@/utils/bus";
Vue.prototype.$bus = bus;
import pluginExport from "@d2-projects/vue-table-export";
Vue.use(pluginExport);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false; // 适配flex

import "@/common/flexible.js";
import { Message } from "element-ui";
Vue.prototype.$Message = Message;
Vue.prototype.$imgUrl = process.env.VUE_APP_IMAGEURL; // 重写toFixed

Number.prototype.toFixed = function (n) {
  if (n > 20 || n < 0) {
    //精度允许0-20
    throw new RangeError("toFixed() digits argument must be between 0 and 20");
  }

  var number = this;

  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString();
  }

  if (typeof n == "undefined" || n == 0) {
    return Math.round(number).toString();
  } //判断是否为负数


  var isMinus = number > 0 ? false : true;
  var result = number.toString();
  var arr = result.split("."); // 整数的情况

  if (arr.length < 2) {
    result += ".";

    for (var i = 0; i < n; i += 1) {
      result += "0";
    }

    return result;
  }

  var integer = arr[0];
  var decimal = arr[1]; //小数位数和精确位数相等时

  if (decimal.length == n) {
    return result;
  } //小数位数小于精确位数时


  if (decimal.length < n) {
    for (var _i = 0; _i < n - decimal.length; _i += 1) {
      result += "0";
    }

    return result;
  }

  result = integer + "." + decimal.substr(0, n);
  var last = decimal.substr(n, 1); // 如果需要进，放大转换为整数再处理，避免浮点数精度的损失

  if (parseInt(last, 10) >= 5) {
    var x = Math.pow(10, n); //放大系数
    // 对于过精度位的下一位值大于5时，正数+1 负数-1.
    // 正数例如1.057 转化为两位精度的小数是 1.06。
    // 负数例如-1.057 转化为两位精度的小数是 -1.06。

    result = (Math.round(parseFloat(result) * x) + (isMinus ? -1 : 1)) / x; //为避免除以系数后小数部分0缺失，再调用一次方法。
    //如1.299，保留两位小数，（1.29*100 + 1）/100=1.3，则需要再补一次零->1.30

    result = result.toFixed(n);
  }

  return result;
}; // el-cascader 改


import multiCascader from "multi-cascader-base-ele"; // 核心插件

Vue.use(d2Admin);
Vue.use(multiCascader); // 将版本号添加到全局变量中

Vue.prototype.$version = process.env.VUE_APP_VERSION;
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit("d2admin/page/init", frameInRoutes); // 设置顶栏菜单

    this.$store.commit("d2admin/menu/headerSet", menuHeader); // 设置侧边栏菜单

    this.$store.commit("d2admin/menu/asideSet", menuAside); // 初始化菜单搜索功能

    this.$store.commit("d2admin/search/init", menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit("d2admin/releases/versionShow"); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch("d2admin/account/load"); // 获取并记录用户 UA

    this.$store.commit("d2admin/ua/get"); // 初始化全屏监听

    this.$store.dispatch("d2admin/fullscreen/listen"); // 获取页面信息

    this.$store.dispatch("d2admin/system/getPageinfo");
  }
}).$mount("#app");