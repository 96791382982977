var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id ? "修改" : "新增",
            visible: _vm.dialogShow,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "user_id", label: "用户ID" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入用户ID" },
                    model: {
                      value: _vm.paramsData.user_id,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "user_id", $$v)
                      },
                      expression: "paramsData.user_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "user_name", label: "用户名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入用户名称" },
                    model: {
                      value: _vm.paramsData.user_name,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "user_name", $$v)
                      },
                      expression: "paramsData.user_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "contact_time", label: "联系时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "flex-1 ",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间"
                    },
                    model: {
                      value: _vm.paramsData.contact_time,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "contact_time", $$v)
                      },
                      expression: "paramsData.contact_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "vip_class", label: "VIP等级" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { type: "number", placeholder: "请输入VIP等级" },
                    model: {
                      value: _vm.paramsData.vip_class,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "vip_class", _vm._n($$v))
                      },
                      expression: "paramsData.vip_class"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.hiad()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }