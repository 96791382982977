var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告渠道", prop: "client_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "广告渠道"
                  },
                  model: {
                    value: _vm.formData.advert_channel_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "advert_channel_id", $$v)
                    },
                    expression: "formData.advert_channel_id"
                  }
                },
                _vm._l(_vm.advertChannelData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.advert_channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "事件名称", prop: "event_name" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.event_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "event_name", $$v)
                  },
                  expression: "formData.event_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "事件标志", prop: "event_mark" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.event_mark,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "event_mark", $$v)
                  },
                  expression: "formData.event_mark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }