var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "产品绑定广告渠道", prop: "switch_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择产品绑定广告渠道"
                  },
                  on: { change: _vm.callbackSwitch },
                  model: {
                    value: _vm.formData.switch_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "switch_id", $$v)
                    },
                    expression: "formData.switch_id"
                  }
                },
                _vm._l(_vm.callbackSwitchConfigData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.advert_channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "事件", prop: "original_event" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择事件"
                  },
                  model: {
                    value: _vm.formData.original_event,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "original_event", $$v)
                    },
                    expression: "formData.original_event"
                  }
                },
                _vm._l(_vm.eventManageDataList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.event_name, value: item.event_mark }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "自定义事件名称", prop: "custom_event_name" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.custom_event_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "custom_event_name", $$v)
                  },
                  expression: "formData.custom_event_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "映射事件", prop: "reflection_event" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.reflection_event,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "reflection_event", $$v)
                  },
                  expression: "formData.reflection_event"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }