// 在线用户事件上报
import request from '@/utils/request'; // 在线事件上报

export function updateUserState(data) {
  return request({
    url: '/user/updateUserState',
    method: 'get',
    params: data
  });
}
export function onlineUserList(data) {
  return request({
    url: '/user/onlineUserList',
    method: 'get',
    params: data
  });
}
export function debugReportData(data) {
  return request({
    url: '/user/debugReportData',
    method: 'get',
    params: data
  });
}
export function debugReport(data) {
  return request({
    url: '/user/debugReport',
    method: 'post',
    params: data
  });
}
export function gainAdvData(data) {
  return request({
    url: '/user/gainAdvData',
    method: 'get',
    params: data
  });
}
export function gainAdvAdd(data) {
  return request({
    url: '/user/gainAdvAdd',
    method: 'post',
    params: data
  });
}
export function gainAdvDel(data) {
  return request({
    url: '/user/gainAdvDel',
    method: 'post',
    params: data
  });
}
export function getSonRole(data) {
  return request({
    url: '/rule/getSonRole',
    method: 'get',
    params: data
  });
}
export function sonRoleAdd(data) {
  return request({
    url: '/rule/sonRoleAdd',
    method: 'post',
    params: data
  });
}
export function getSonUser(data) {
  return request({
    url: '/auth/getSonUser',
    method: 'get',
    params: data
  });
}