import times from "@/utils/changTime";
export default {
  data: function data() {
    var _this = this;

    return {
      // 之前
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > times.zeroTimeZone();
        }
      },
      // 之后
      pickerOptionsLater: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < times.zeroTimeZone() - 24 * 60 * 60 * 1000;
        }
      },
      // 日 单选
      pickerOptionsDay: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > times.zeroTimeZone();
        },
        shortcuts: [{
          text: "今日",
          onClick: function onClick(picker) {
            picker.$emit("pick", new Date(times.zeroTimeZone()));
          }
        }, {
          text: "一周前",
          onClick: function onClick(picker) {
            var date = new Date(times.zeroTimeZone());
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", date);
          }
        }, {
          text: "半月前",
          onClick: function onClick(picker) {
            var date = new Date(times.zeroTimeZone());
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit("pick", date);
          }
        }]
      },
      // 日 范围选择
      pickerOptionsRadius: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > times.zeroTimeZone();
        },
        shortcuts: [{
          text: "今日/此刻",
          onClick: function onClick(picker) {
            var end = new Date(times.zeroTimeZone());
            var start = new Date(times.zeroTimeZone());
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "一周",
          onClick: function onClick(picker) {
            var end = new Date(times.zeroTimeZone());
            var start = new Date(times.zeroTimeZone());
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "半月",
          onClick: function onClick(picker) {
            var end = new Date(times.zeroTimeZone());
            var start = new Date(times.zeroTimeZone());
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      // 日 范围选择 不限制时间
      pickerOptionsRadiusT: {
        shortcuts: [{
          text: "今日/此刻",
          onClick: function onClick(picker) {
            var end = new Date(times.zeroTimeZone());
            var start = new Date(times.zeroTimeZone());
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "一周",
          onClick: function onClick(picker) {
            var end = new Date(times.zeroTimeZone());
            var start = new Date(times.zeroTimeZone());
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "半月",
          onClick: function onClick(picker) {
            var end = new Date(times.zeroTimeZone());
            var start = new Date(times.zeroTimeZone());
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      // 月 单选
      pickerOptionsMonth: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > times.zeroTimeZone();
        },
        shortcuts: [{
          text: "当月",
          onClick: function onClick(picker) {
            picker.$emit("pick", new Date(times.zeroTimeZone()));
          }
        }, {
          text: "上月",
          onClick: function onClick(picker) {
            var date = new Date(times.zeroTimeZone());
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", date);
          }
        }]
      },
      // 只能选择一个月时间
      pickerOptionsOnMonth: {
        // 设置不能选择的日期
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
              minDate = _ref.minDate;
          _this.choiceDate0 = minDate.getTime();

          if (maxDate) {
            _this.choiceDate0 = "";
          }
        },
        disabledDate: function disabledDate(time) {
          var choiceDateTime = new Date(_this.choiceDate0).getTime();
          var minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          var maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          var min = minTime;
          var newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          var max = newDate < maxTime ? newDate : maxTime; //如果已经选中一个日期 则 返回 该日期前后一个月时间可选

          if (_this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          } //若一个日期也没选中 则 返回 当前日期以前日期可选


          return time.getTime() > times.zeroTimeZone();
        }
      }
    };
  },
  activated: function activated() {},
  methods: {
    setCellClass: function setCellClass(_ref2) {
      var row = _ref2.row,
          column = _ref2.column,
          type = _ref2.type;

      if (type === "today") {
        return "";
      }

      return null;
    }
  }
};