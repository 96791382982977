var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: { title: "发送礼包", visible: _vm.dialogShow },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: _vm.clearData
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  key: "type",
                  staticClass: "lodingItem",
                  attrs: { prop: "type", label: "类型" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.paramsData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "type", $$v)
                        },
                        expression: "paramsData.type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "start_time",
                  staticClass: "lodingItem",
                  attrs: { prop: "start_time", label: "开始时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "日期"
                    },
                    model: {
                      value: _vm.paramsData.start_time,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "start_time", $$v)
                      },
                      expression: "paramsData.start_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "end_time",
                  staticClass: "lodingItem",
                  attrs: { prop: "end_time", label: "结束时间" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "operationFrame-entity",
                    attrs: {
                      "popper-class": "no-atTheMoment",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "日期"
                    },
                    model: {
                      value: _vm.paramsData.end_time,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "end_time", $$v)
                      },
                      expression: "paramsData.end_time"
                    }
                  })
                ],
                1
              ),
              _vm.paramsData.type == 1
                ? _c(
                    "el-form-item",
                    {
                      key: "gift_code",
                      staticClass: "lodingItem",
                      attrs: { prop: "gift_code", label: "礼包码" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.gift_code,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "gift_code", $$v)
                          },
                          expression: "paramsData.gift_code"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == 1
                ? _c(
                    "el-form-item",
                    {
                      key: "count",
                      staticClass: "lodingItem",
                      attrs: { prop: "count", label: "数量" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.count,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "count", _vm._n($$v))
                          },
                          expression: "paramsData.count"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == 2
                ? _c(
                    "el-form-item",
                    {
                      key: "giftCount",
                      staticClass: "lodingItem",
                      attrs: { prop: "giftCount", label: "生成礼包数量" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.giftCount,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "giftCount", _vm._n($$v))
                          },
                          expression: "paramsData.giftCount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == 2
                ? _c(
                    "el-form-item",
                    {
                      key: "prefix",
                      staticClass: "lodingItem",
                      attrs: { prop: "prefix", label: "礼包码前缀" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.prefix,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "prefix", $$v)
                          },
                          expression: "paramsData.prefix"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == 2
                ? _c(
                    "el-form-item",
                    {
                      key: "codeLen",
                      staticClass: "lodingItem",
                      attrs: { prop: "codeLen", label: "礼包码长度" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.codeLen,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "codeLen", $$v)
                          },
                          expression: "paramsData.codeLen"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == 2
                ? _c(
                    "el-form-item",
                    {
                      key: "rewardCount",
                      staticClass: "lodingItem",
                      attrs: { prop: "rewardCount", label: "单个玩家领取次数" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.paramsData.rewardCount,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "rewardCount", $$v)
                          },
                          expression: "paramsData.rewardCount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { prop: "rewards" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addDomain("rewards")
                            }
                          }
                        },
                        [_vm._v("添加物品 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.paramsData.rewards, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain("rewards", index)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "rewards." + index + ".type",
                              rules: _vm.rules.item_type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { placeholder: "类型" },
                                on: {
                                  change: function($event) {
                                    return _vm.domainType(index)
                                  }
                                },
                                model: {
                                  value: domain.type,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type", $$v)
                                  },
                                  expression: "domain.type"
                                }
                              },
                              _vm._l(_vm.itemType, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: {
                                    label: items.label,
                                    value: items.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "rewards." + index + ".id",
                              rules: !domain.textid ? _vm.rules.id : []
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "150px"
                                },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  disabled: !domain.type || domain.textid != "",
                                  placeholder: "物品"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.itemChange(index, "ITEM")
                                  }
                                },
                                model: {
                                  value: domain.id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "id", $$v)
                                  },
                                  expression: "domain.id"
                                }
                              },
                              [
                                _vm._l(_vm.itemType1, function(items, indexs) {
                                  return domain.type == 1
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType2, function(items, indexs) {
                                  return domain.type == 2
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType3, function(items, indexs) {
                                  return domain.type == 3
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType4, function(items, indexs) {
                                  return domain.type == 4
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType5, function(items, indexs) {
                                  return domain.type == 5
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType6, function(items, indexs) {
                                  return domain.type == 6
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType7, function(items, indexs) {
                                  return domain.type == 7
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType8, function(items, indexs) {
                                  return domain.type == 8
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "rewards." + index + ".textid",
                              rules: !domain.id ? _vm.rules.textid : []
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "物品ID",
                                disabled: domain.id != ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.itemChange(index, "ID")
                                }
                              },
                              model: {
                                value: domain.textid,
                                callback: function($$v) {
                                  _vm.$set(domain, "textid", $$v)
                                },
                                expression: "domain.textid"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "rewards." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        domain.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px" },
                                attrs: {
                                  prop: "rewards." + index + ".quality",
                                  rules: _vm.rules.item_quality
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { placeholder: "品质" },
                                    model: {
                                      value: domain.quality,
                                      callback: function($$v) {
                                        _vm.$set(domain, "quality", $$v)
                                      },
                                      expression: "domain.quality"
                                    }
                                  },
                                  _vm._l(_vm.quality, function(items, indexs) {
                                    return _c("el-option", {
                                      key: indexs,
                                      attrs: { label: items, value: items }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    disabled: _vm.reqLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }