//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import collect from "./channelCom/collect.vue";
import hourDetails from "./channelCom/hourDetails.vue";
import accountSummary from "./channelCom/accountSummary.vue";
import accountSummaryDetails from "./channelCom/accountSummaryDetails.vue";
import channel from "./channelCom/channel.vue";
import * as echarts from "echarts";
export default {
  name: "channelData",
  components: {
    collect: collect,
    hourDetails: hourDetails,
    accountSummary: accountSummary,
    accountSummaryDetails: accountSummaryDetails,
    channel: channel
  },
  data: function data() {
    return {
      activeIndex: ''
    };
  },
  mounted: function mounted() {},
  created: function created() {
    var _this = this;

    setTimeout(function () {
      _this.activeIndex = 'collect';
    }, 1000);
  },
  watch: {},
  methods: {
    handleSelect: function handleSelect(e) {
      this.activeIndex = e;
      this.$refs[e].handleSelect(e);
    }
  }
};