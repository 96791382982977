// 汇总数据
import request from '@/utils/request';
export function dataSummary(data) {
  return request({
    url: '/data/summary',
    method: 'get',
    params: data
  });
}
export function dataSummary_currency(data) {
  return request({
    url: '/data/summary_currency',
    method: 'get',
    params: data
  });
}
export function summary_currency_other(data) {
  return request({
    url: '/data/summary_currency_other',
    method: 'get',
    params: data
  });
}
export function summary_new(data) {
  return request({
    url: '/data/summary_new',
    method: 'get',
    params: data
  });
}
export function summary_currency_other_new(data) {
  return request({
    url: '/data/summary_currency_other_new',
    method: 'get',
    params: data
  });
}
export function exchangeRate(data) {
  return request({
    url: '/data/exchangeRate',
    method: 'get',
    params: data
  });
}
export function summary_new_ltv(data) {
  return request({
    url: '/data/summary_new_ltv',
    method: 'get',
    params: data
  });
}
export function summary_new_other(data) {
  return request({
    url: '/data/summary_new_other',
    method: 'get',
    params: data
  });
}
export function summary_new_rr(data) {
  return request({
    url: '/data/summary_new_rr',
    method: 'get',
    params: data
  });
}
export function summary_new_activity_ltv(data) {
  return request({
    url: '/data/summary_new_activity_ltv',
    method: 'get',
    params: data
  });
}
export function summary_new_activity_rr(data) {
  return request({
    url: '/data/summary_new_activity_rr',
    method: 'get',
    params: data
  });
}