// 上拉加载更多列表
export default {
  data: function data() {
    return {
      loading: false,
      // 总条数
      totalNum: 0,
      pagingList: [],
      // 列表请求方法
      pagingReqFn: null,
      // 请求中存放列表的字段
      pagingReqValue: '',
      // 请求或搜索存放请求参数
      paramsData: {
        page_size: 10,
        page: 1
      },
      delIds: '',
      // 请求成功回调函数
      pagingCallback: null
    };
  },
  methods: {
    /**
     * 请求列表方法
     * @param page 页码
     * @param pagingCallback 回调函数
     * **/
    getList: function getList() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var pagingCallback = arguments.length > 1 ? arguments[1] : undefined;
      this.paramsData.page = page;

      if (!this.pagingReqFn) {
        console.error('请传入请求函数');
        return false;
      }

      this.loading = true;
      this.pagingReqFn(this.paramsData).then(function (res) {
        _this.loading = false;

        if (res.status == 200) {
          var data = res.data;
          _this.totalNum = data.total;
          _this.pagingList = _this.pagingReqValue ? data[_this.pagingReqValue] : data;
        }

        _this.pagingCallback && _this.pagingCallback(_this.pagingList) || pagingCallback && pagingCallback();
      });
    }
  }
};