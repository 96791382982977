var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "latestToday" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: {
              propsData: { isCountry: false, isDate: false, isCurrency: true }
            },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择环境"
                      },
                      on: { change: _vm.severlistChange },
                      model: {
                        value: _vm.searchData.is_prod,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "is_prod", $$v)
                        },
                        expression: "searchData.is_prod"
                      }
                    },
                    _vm._l(_vm.severlist, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择服务器"
                      },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.name || item.serverName,
                          value: item.id || item.serverID
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "flex-1",
                      attrs: { filterable: "", placeholder: "请选择环境" },
                      model: {
                        value: _vm.searchData.env_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "env_id", $$v)
                        },
                        expression: "searchData.env_id"
                      }
                    },
                    _vm._l(_vm.szgmEnv, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "服务器渠道" },
                      on: { change: _vm.serverChange },
                      model: {
                        value: _vm.server_channel,
                        callback: function($$v) {
                          _vm.server_channel = $$v
                        },
                        expression: "server_channel"
                      }
                    },
                    _vm._l(_vm.serverChannel, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "是否展示测试服"
                      },
                      on: { change: _vm.serverChannelChange },
                      model: {
                        value: _vm.show_test,
                        callback: function($$v) {
                          _vm.show_test = $$v
                        },
                        expression: "show_test"
                      }
                    },
                    _vm._l(_vm.serverType, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("yjfm") > -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "服务器"
                      },
                      on: { change: _vm.severChange },
                      model: {
                        value: _vm.searchData.server_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "server_id", $$v)
                        },
                        expression: "searchData.server_id"
                      }
                    },
                    _vm._l(_vm.serverListYJ, function(item) {
                      return _c("el-option", {
                        key: item.serverID,
                        attrs: { label: item.serverName, value: item.serverID }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.rawData.game_mark.indexOf("royalpirates") == -1 &&
          _vm.rawData.game_mark.indexOf("yjfm") == -1
            ? _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "服务器ID"
                    },
                    model: {
                      value: _vm.searchData.server_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "server_id", $$v)
                      },
                      expression: "searchData.server_id"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  "picker-options": _vm.pickerOptionsDay,
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.searchData.date,
                  callback: function($$v) {
                    _vm.$set(_vm.searchData, "date", $$v)
                  },
                  expression: "searchData.date"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-time-picker", {
                attrs: {
                  format: "HH",
                  "value-format": "HH",
                  "is-range": "",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  placeholder: "选择时间范围"
                },
                model: {
                  value: _vm.PointTime,
                  callback: function($$v) {
                    _vm.PointTime = $$v
                  },
                  expression: "PointTime"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "end" }, attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: {
                    click: function($event) {
                      return _vm.getRollData(true)
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "sticky-table-header",
              rawName: "v-sticky-table-header",
              value: 0,
              expression: "0"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "server_id",
              label: "服务器ID",
              "min-width": _vm.$utils.flexColumnWidth("服务器ID", false)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "date",
              label: "数据日期",
              "min-width": _vm.$utils.flexColumnWidth("数据日期", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hours",
              label: "小时",
              "min-width": _vm.$utils.flexColumnWidth("12~13 ", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "server_register_pnum",
              label: "注册数",
              "min-width": _vm.$utils.flexColumnWidth("注册数", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "server_login_pnum",
              label: "登录数",
              "min-width": _vm.$utils.flexColumnWidth("登录数", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "server_recharge_pnum",
              label: "付费数",
              "min-width": _vm.$utils.flexColumnWidth("付费数", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "pay_percent",
              label: "付费率",
              "min-width": _vm.$utils.flexColumnWidth("付费率", true)
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.pay_percent + "%") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "server_recharge_amount",
              label: "付费总额(元)",
              "min-width": _vm.$utils.flexColumnWidth("付费总额(元)", true)
            }
          }),
          _vm.queryCurrency == "VND"
            ? _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "server_recharge_amountexchange",
                  label: "付费总额(元,美元)",
                  "min-width": _vm.$utils.flexColumnWidth(
                    "付费总额(元,美元)",
                    true
                  )
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "arpu",
              label: "累计ARPU",
              "min-width": _vm.$utils.flexColumnWidth("累计ARPU", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "arppu",
              label: "累计ARPPU",
              "min-width": _vm.$utils.flexColumnWidth("累计ARPPU", true)
            }
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "register_arpu",
              label: "累计注册ARPU",
              "min-width": _vm.$utils.flexColumnWidth("累计注册ARPU ", true)
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }