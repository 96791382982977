import request from '@/utils/request'; //GM工具新  御剑
// 邮件列表

export function szgmEmailList(data) {
  return request({
    url: 'sz-gm/email-list',
    method: 'get',
    params: data
  });
} // 发送邮件给所有人

export function szgmSendEmailAll(data) {
  return request({
    url: 'sz-gm/send-email-all',
    method: 'post',
    params: data
  });
} // 发送邮件给特定人

export function szgmSendEmailUser(data) {
  return request({
    url: 'sz-gm/send-email-users',
    method: 'post',
    params: data
  });
} // 提交邮件草稿

export function szgmSendEmail(data) {
  return request({
    url: 'sz-gm/send-email',
    method: 'post',
    params: data
  });
} // 发送邮件

export function szgmdoSend(data) {
  return request({
    url: 'sz-gm/do-send',
    method: 'post',
    params: data
  });
} // 删除邮件

export function szgmdoDeleteEmail(data) {
  return request({
    url: 'sz-gm/delete-email',
    method: 'post',
    params: data
  });
} // 礼包列表

export function szgmGiftList(data) {
  return request({
    url: 'sz-gm/gift-list',
    method: 'get',
    params: data
  });
} // 新增或修改礼包

export function szgmAddGift(data) {
  return request({
    url: 'sz-gm/add-gift',
    method: 'post',
    params: data
  });
} // 删除礼包

export function szgmDelGift(data) {
  return request({
    url: 'sz-gm/del-gift',
    method: 'post',
    params: data
  });
} // 物品列表

export function szgmItem(data) {
  return request({
    url: 'sz-gm/items',
    method: 'get',
    params: data
  });
} // 语言代码

export function szEmailLanguageList(data) {
  return request({
    url: 'sz-gm/sz-email-language-list',
    method: 'get',
    params: data
  });
}
/**
 * 跑马灯管理
 */
// 跑马灯列表

export function AnnounceList(data) {
  return request({
    url: 'sz-gm/announce-list',
    method: 'get',
    params: data
  });
} // 新增跑马灯

export function addAnnounce(data) {
  return request({
    url: 'sz-gm/add-announce',
    method: 'post',
    params: data
  });
} // 修改跑马灯

export function updAnnounce(data) {
  return request({
    url: 'sz-gm/updAnnounce',
    method: 'post',
    params: data
  });
} // 删除跑马灯

export function delAnnounce(data) {
  return request({
    url: 'sz-gm/delAnnounce',
    method: 'post',
    params: data
  });
}
/**
 * 服务器管理
 */
// 服务器列表

export function serverList(data) {
  return request({
    url: 'sz-gm/server-list',
    method: 'get',
    params: data
  });
} // 新增服务器

export function addServer(data) {
  return request({
    url: 'sz-gm/add-server',
    method: 'post',
    params: data
  });
} // 修改服务器

export function updServer(data) {
  return request({
    url: 'sz-gm/edit-server',
    method: 'post',
    params: data
  });
} // 服务器操作日志

export function serverUpdateLog(data) {
  return request({
    url: 'sz-gm/server_update_log',
    method: 'get',
    params: data
  });
} // 异常用户

export function abnormalList(data) {
  return request({
    url: 'sz-gm/abnormal-list',
    method: 'get',
    params: data
  });
} // 封禁列表

export function banUserList(data) {
  return request({
    url: 'sz-gm/ban-user-list',
    method: 'get',
    params: data
  });
} // 封禁账号

export function banUser(data) {
  return request({
    url: 'sz-gm/ban-user',
    method: 'post',
    params: data
  });
} // 修改封禁

export function unbanUser(data) {
  return request({
    url: 'sz-gm/unban-user',
    method: 'post',
    params: data
  });
} // 获取所有异常用户

export function abnormalListAll(data) {
  return request({
    url: 'sz-gm/abnormalListAll',
    method: 'get',
    params: data
  });
} // 玩家详情

export function szUserInfoNew(data) {
  return request({
    url: 'sz-gm/szUserInfoNew',
    method: 'get',
    params: data
  });
} // 查看背包 

export function SzBagDesc(data) {
  return request({
    url: 'sz-gm/SzBagDesc',
    method: 'get',
    params: data
  });
} // 用户详情道具 

export function SzesourceDescList(data) {
  return request({
    url: 'sz-gm/SzesourceDescList',
    method: 'get',
    params: data
  });
} // 订单 

export function SzRechargeGame(data) {
  return request({
    url: 'sz-gm/SzRechargeGame',
    method: 'get',
    params: data
  });
}
export function SzRechargeGameAll(data) {
  return request({
    url: 'sz-gm/SzRechargeGameAll',
    method: 'get',
    params: data
  });
}
export function SzRechargeGameAllCurrency(data) {
  return request({
    url: 'sz-gm/SzRechargeGameAllCurrency',
    method: 'get',
    params: data
  });
}
export function SzRechargeGameCurrency(data) {
  return request({
    url: 'sz-gm/SzRechargeGameCurrency',
    method: 'get',
    params: data
  });
} // 补单日志 

export function SzReplenishmentSelect(data) {
  return request({
    url: 'sz-gm/SzReplenishmentSelect',
    method: 'get',
    params: data
  });
} // 邮件列表

export function SzEmailList(data) {
  return request({
    url: 'sz-gm/SzEmailList',
    method: 'get',
    params: data
  });
} // 封号日志

export function SzBanedUserList(data) {
  return request({
    url: 'sz-gm/SzBanedUserList',
    method: 'get',
    params: data
  });
} // 删除日志

export function deleteLog(data) {
  return request({
    url: 'sz-gm/delete_log',
    method: 'get',
    params: data
  });
} // 补单记录

export function repairOrderData(data) {
  return request({
    url: 'sz-gm/repairOrderData',
    method: 'get',
    params: data
  });
} // 发送补单

export function sendRepairOrder(data) {
  return request({
    url: 'sz-gm/sendRepairOrder',
    method: 'post',
    params: data
  });
} // 邮件审核

export function auditEmail(data) {
  return request({
    url: 'sz-gm/auditEmail',
    method: 'post',
    params: data
  });
}
/**
 * VIP客户
 */

export function vipCallLogData(data) {
  return request({
    url: 'sz-gm/vipCallLogData',
    method: 'get',
    params: data
  });
}
export function vipCallLogAdd(data) {
  return request({
    url: 'sz-gm/vipCallLogAdd',
    method: 'post',
    params: data
  });
}
export function vipCallLogUpdate(data) {
  return request({
    url: 'sz-gm/vipCallLogUpdate',
    method: 'post',
    params: data
  });
}
export function vipCallLogDel(data) {
  return request({
    url: 'sz-gm/vipCallLogDel',
    method: 'post',
    params: data
  });
}
export function openVip(data) {
  return request({
    url: 'sz-gm/openVip',
    method: 'post',
    params: data
  });
}
/**
 * 礼包
 */
// 礼包码列表

export function getGiftList(data) {
  return request({
    url: 'sz-gm/getGiftList',
    method: 'post',
    params: data
  });
} // 新增礼包码

export function setGiftCode(data) {
  return request({
    url: 'sz-gm/setGiftCode',
    method: 'post',
    params: data
  });
} //登录前公告

export function loginBeforeAnnounceData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/loginBeforeAnnounceData",
    method: "get",
    params: data
  });
}
export function loginBeforeAnnounceAdd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/loginBeforeAnnounceAdd",
    method: "post",
    params: data
  });
}
export function loginBeforeAnnounceUpdate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/loginBeforeAnnounceUpdate",
    method: "post",
    params: data
  });
}
export function loginBeforeAnnounceDel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/loginBeforeAnnounceDel",
    method: "post",
    params: data
  });
} //游戏内公告

export function gameAnnounceData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/gameAnnounceData",
    method: "get",
    params: data
  });
}
export function gameAnnounceAdd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/gameAnnounceAdd",
    method: "post",
    params: data
  });
}
export function gameAnnounceDel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/gameAnnounceDel",
    method: "post",
    params: data
  });
}
export function gameAnnounceUpdate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/gameAnnounceUpdate",
    method: "post",
    params: data
  });
} //公告渠道列表

export function announceChannelData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/announceChannelData",
    method: "get",
    params: data
  });
}
export function announceChannelAdd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/announceChannelAdd",
    method: "post",
    params: data
  });
}
export function announceChannelUpdate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/announceChannelUpdate",
    method: "post",
    params: data
  });
}
export function announceChannelDel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/announceChannelDel",
    method: "post",
    params: data
  });
} // 服务器仙玉详情

export function serverEventData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "data/serverEventData",
    method: "get",
    params: data
  });
} // 御剑环境变量

export function yjenvlist() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/yj-env-list",
    method: "get",
    params: data
  });
}
export function addyjenv() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/add-yj-env",
    method: "post",
    params: data
  });
}
export function updateyjenv() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/update-yj-env",
    method: "post",
    params: data
  });
}
export function delyjenv() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/del-yj-env",
    method: "post",
    params: data
  });
}
export function useryjenvlist() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/user-yj-env-list",
    method: "get",
    params: data
  });
}
export function binduseryjenv() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/bind-user-yj-env",
    method: "post",
    params: data
  });
} // 跳过新手引导列表

export function getFixDataInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/getFixDataInfo",
    method: "get",
    params: data
  });
} // 跳过新手引导新增

export function fixDataAdd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/fixDataAdd",
    method: "post",
    params: data
  });
} // 查看坊市开关

export function getMarketInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/getMarketInfo",
    method: "get",
    params: data
  });
} // 调整坊市状态

export function marketUpd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "sz-gm/marketUpd",
    method: "post",
    params: data
  });
}