import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import { setGiftCode } from '@/api/gmToolNew/gmToolNew';
import { gmToolNew, gmToolNewCom } from "@/mixins/index";
export default {
  mixins: [gmToolNew, gmToolNewCom],
  data: function data() {
    var _this = this;

    var validateNum = function validateNum(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入'));
      } else {
        var num = Number(_this.paramsData.prefix.length) + Number(value);
        console.log(num);

        if (num < 4 || num > 10) {
          callback(new Error('随机礼包码长度 前缀+长度 不能大于10 需大于或等于4'));
        }

        callback();
      }
    };

    return {
      paramsData: {
        gift_code: '',
        start_time: '',
        end_time: '',
        count: '',
        type: '',
        giftCount: '',
        prefix: '',
        codeLen: '',
        rewardCount: '',
        rewards: []
      },
      dialogShow: false,
      ex_Val: [],
      typeList: [{
        label: '固定',
        value: 1
      }, {
        label: '随机',
        value: 2
      }],
      rules: {
        gift_code: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        start_time: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        end_time: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        count: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        giftCount: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        prefix: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        rewardCount: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        codeLen: [{
          required: true,
          validator: validateNum,
          trigger: "blur"
        }],
        rewards: [{
          required: true,
          message: "请添加物品",
          trigger: "blur"
        }],
        textid: [{
          required: true,
          message: "物品ID",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    expireChange: function expireChange(e) {// this.paramsData.expire = e / 1000
    },
    discountChange: function discountChange() {
      this.$set(this.paramsData, 'original_cost', []);
      this.paramsData.original_cost = [];
    },
    open: function open(data) {
      var _this2 = this;

      this.dialogShow = true;
      this.itemType.map(function (i) {
        _this2['itemType' + i.value] = _this2.szgmItemList.filter(function (item) {
          return item.type == i.value;
        });
      });

      if (data) {
        this.paramsData = _objectSpread({}, data);
        this.ex_Val = data.rewards ? JSON.parse(data.rewards) : [];
      }
    },
    itemChange: function itemChange(index, type) {
      this.paramsData.rewards[index][type == 'ITEM' ? 'textid' : 'id'] = '';
    },
    clearData: function clearData() {
      var _this$paramsData;

      this.paramsData = (_this$paramsData = {
        gift_id: '',
        name: '',
        desc: '',
        icon: '',
        refresh: 0,
        expire: '',
        num: '',
        delay_time: ''
      }, _defineProperty(_this$paramsData, "expire", ''), _defineProperty(_this$paramsData, "is_delay", 2), _defineProperty(_this$paramsData, "cost", []), _defineProperty(_this$paramsData, "has_discount", 2), _defineProperty(_this$paramsData, "original_cost", []), _defineProperty(_this$paramsData, "rewards", []), _this$paramsData);
      this.$refs.ruleForm.clearValidate();
    },
    domainType: function domainType(index, type) {
      this.paramsData.rewards[index].id = "";
      this.paramsData.rewards[index].textid = "";

      if (this.paramsData.rewards[index].num) {
        this.paramsData.rewards[index].num = '';
      }
    },
    addDomain: function addDomain(type) {
      this.paramsData[type].push({
        num: "",
        type: "",
        id: "",
        textid: "",
        quality: ""
      });
    },
    removeDomain: function removeDomain(type, index) {
      this.paramsData[type].splice(index, 1);
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this3 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this3.reqLoading = true;
          var data = JSON.parse(JSON.stringify(_this3.paramsData));
          data.rewards.map(function (item) {
            if (item.type == 1) {
              delete item.quality;
            }

            if (item.textid) {
              item.id = item.textid;
            }

            for (var i in item) {
              item[i] = parseInt(item[i]);
            }
          });
          data.start_time = data.start_time / 1000;
          data.end_time = data.end_time / 1000;
          data.rewards = JSON.stringify(data.rewards);
          setGiftCode(_objectSpread(_objectSpread({}, data), _this3.propsData)).then(function (res) {
            if (res.status == 200) {
              _this3.$message({
                message: '添加成功',
                type: 'success'
              });

              _this3.dialogShow = false;

              _this3.$emit('change');
            }
          }).finally(function () {
            _this3.reqLoading = false;
          });
        }
      });
    }
  }
};