// 首页 
import request from '@/utils/request'; // 

export function overview(data) {
  return request({
    url: '/data/overview',
    method: 'get',
    params: data
  });
}
export function overview_currency(data) {
  return request({
    url: '/data/overviewCurrency',
    method: 'get',
    params: data
  });
} // 滚服数据

export function rollData(data) {
  return request({
    url: '/data/roll-data',
    method: 'get',
    params: data
  });
}
export function rollData_currency(data) {
  return request({
    url: '/data/roll-data-currency',
    method: 'get',
    params: data
  });
} // 项目总览

export function allRecharge(data) {
  return request({
    url: '/data/all-register-recharge',
    method: 'get',
    params: data
  });
}
export function allRecharge_currency(data) {
  return request({
    url: '/data/all-register-recharge-currency',
    method: 'get',
    params: data
  });
}
export function updUserPassword(data) {
  return request({
    url: '/updUserPassword',
    method: 'post',
    params: data
  });
}