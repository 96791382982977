var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _vm.HomePageState == 1
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 4 } }, [
                  _c("div", { staticClass: "index-t" }, [
                    _c("div", { staticClass: "index-t-l" }),
                    _c("div", { staticClass: "index-t-t" }, [
                      _vm._v(
                        "已上线" +
                          _vm._s(_vm.game_show_type == 1 ? "游戏" : "项目")
                      )
                    ])
                  ])
                ]),
                _vm.rawData.game_mark
                  ? _c(
                      "el-col",
                      { staticClass: "operationBut", attrs: { span: 4 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "warning" },
                            on: { click: _vm.cancelCloseItem }
                          },
                          [_vm._v("取消")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "itemList" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  _vm._l(_vm.itemList, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticClass: "text-cursor",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { span: 6 }
                      },
                      [
                        item.game_show_type == 1
                          ? _c(
                              "el-card",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "306px"
                                },
                                attrs: { shadow: "hover" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.closeItem(item)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "itemList-i" }, [
                                  !item.icon
                                    ? _c("img", {
                                        staticClass: "itemList-i-i",
                                        attrs: {
                                          src: require("../../../assets/gameIcon.png")
                                        }
                                      })
                                    : _c("img", {
                                        staticClass: "itemList-i-i",
                                        attrs: {
                                          src: _vm.$utils.imgTrow(item.icon)
                                        }
                                      }),
                                  _c("div", [
                                    _c("div", { staticClass: "itemList-i-t" }, [
                                      _vm._v(_vm._s(item.game_name))
                                    ])
                                  ])
                                ]),
                                _vm.rawData.game_mark == item.game_mark
                                  ? _c("img", {
                                      staticClass: "selecticon",
                                      attrs: {
                                        src: require("../../../assets/selecticon.png")
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        item.game_show_type == 2
                          ? _c(
                              "el-tooltip",
                              { attrs: { placement: "top-start" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "18px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v("产品 : " + _vm._s(item.name)),
                                    _c("br"),
                                    _vm._v("游戏 : " + _vm._s(item.game_name)),
                                    _c("br"),
                                    _vm._v(
                                      "平台 : " + _vm._s(item.platform_name)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "渠道 : " + _vm._s(item.channel_name)
                                    ),
                                    _c("br"),
                                    _vm._v("包名 : " + _vm._s(item.pkg_name))
                                  ]
                                ),
                                _c(
                                  "el-card",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      height: "306px"
                                    },
                                    attrs: { shadow: "hover" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.closeItem(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "itemList-i" }, [
                                      !item.icon
                                        ? _c("img", {
                                            staticClass: "itemList-i-i",
                                            attrs: {
                                              src: require("../../../assets/gameIcon.png")
                                            }
                                          })
                                        : _c("img", {
                                            staticClass: "itemList-i-i",
                                            attrs: {
                                              src: _vm.$utils.imgTrow(item.icon)
                                            }
                                          }),
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "itemList-i-t" },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      ])
                                    ]),
                                    _vm.rawData.game_mark == item.game_mark &&
                                    _vm.rawData.platform_mark ==
                                      item.platform_mark &&
                                    _vm.rawData.channel_mark ==
                                      item.channel_mark &&
                                    _vm.rawData.pkg_mark == item.pkg_mark
                                      ? _c("img", {
                                          staticClass: "selecticon",
                                          attrs: {
                                            src: require("../../../assets/selecticon.png")
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.HomePageState == 3
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 4 } }, [
                  _c("div", { staticClass: "index-t" }, [
                    _c("div", { staticClass: "index-t-l" }),
                    _c("div", { staticClass: "index-t-t" }, [
                      _vm._v("选择项目")
                    ])
                  ])
                ]),
                _c(
                  "el-col",
                  { staticClass: "operationBut", attrs: { span: 4 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: { click: _vm.cancelCloseItem }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "itemList" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  _vm._l(_vm.kamitakuItemList, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { span: 6 }
                      },
                      [
                        _c(
                          "el-tooltip",
                          { attrs: { placement: "top-start" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { "line-height": "18px" },
                                attrs: { slot: "content" },
                                slot: "content"
                              },
                              [
                                _vm._v("游戏 : " + _vm._s(item.game_name)),
                                _c("br"),
                                _vm._v("平台 : " + _vm._s(item.platform_name)),
                                _c("br"),
                                _vm._v("渠道 : " + _vm._s(item.channel_name)),
                                _c("br"),
                                _vm._v("包名 : " + _vm._s(item.pkg_name))
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "306px"
                                },
                                attrs: { shadow: "hover" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.closeItem(item)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "itemList-i" }, [
                                  !item.icon
                                    ? _c("img", {
                                        staticClass: "itemList-i-i",
                                        attrs: {
                                          src: require("../../../assets/gameIcon.png")
                                        }
                                      })
                                    : _c("img", {
                                        staticClass: "itemList-i-i",
                                        attrs: {
                                          src: _vm.$utils.imgTrow(item.icon)
                                        }
                                      }),
                                  _c("div", [
                                    _c("div", { staticClass: "itemList-i-t" }, [
                                      _vm._v(_vm._s(item.name))
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.HomePageState == 2
      ? _c(
          "div",
          [
            _c("div", { staticClass: "index-t" }, [
              _c("div", { staticClass: "index-t-l" }),
              _c("div", { staticClass: "index-t-t" }, [_vm._v("功能菜单")])
            ]),
            _c(
              "el-checkbox",
              {
                attrs: { indeterminate: _vm.isIndeterminate },
                on: { change: _vm.handleCheckAllChange },
                model: {
                  value: _vm.checkAll,
                  callback: function($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll"
                }
              },
              [_vm._v("全选")]
            ),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities"
                }
              },
              _vm._l(_vm.featureList, function(item, index) {
                return _c(
                  "el-checkbox",
                  { key: index, attrs: { label: item.id, border: "" } },
                  [_vm._v(_vm._s(item.title))]
                )
              }),
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confimFeature }
                      },
                      [_vm._v("确认")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: { click: _vm.cancelfimFeature }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.HomePageState == "" && _vm.is_show_homes == 2 && _vm.rawData.game_mark
      ? _c(
          "div",
          {},
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo",
                attrs: {
                  "default-active": _vm.activeIndex,
                  mode: "horizontal"
                },
                on: { select: _vm.handleSelect }
              },
              [
                _c("el-menu-item", { attrs: { index: "0" } }, [
                  _vm._v("今日最新")
                ]),
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("整点数据")
                ]),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("整点对比")
                ]),
                _vm.RollSuit
                  ? _c("el-menu-item", { attrs: { index: "4" } }, [
                      _vm._v("服务器数据")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex == "0",
                    expression: "activeIndex == '0'"
                  }
                ],
                staticClass: "latestToday"
              },
              [_c("latests", { ref: "comModule0" })],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex == "1",
                    expression: "activeIndex == '1'"
                  }
                ],
                staticClass: "latestToday"
              },
              [_c("oneHour", { ref: "comModule1" })],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex == "2",
                    expression: "activeIndex == '2'"
                  }
                ],
                staticClass: "latestToday"
              },
              [_c("comparison", { ref: "comModule2" })],
              1
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeIndex == "3",
                  expression: "activeIndex == '3'"
                }
              ],
              staticClass: "latestToday"
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex == "4",
                    expression: "activeIndex == '4'"
                  }
                ],
                staticClass: "latestToday"
              },
              [_c("rollSuit", { ref: "comModule4" })],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex == "5",
                    expression: "activeIndex == '5'"
                  }
                ],
                staticClass: "latestToday"
              },
              [_c("allRechargeP", { ref: "comModule5" })],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              (_vm.HomePageState == "" && _vm.is_show_homes == 1) ||
              (_vm.HomePageState == "" && !_vm.rawData.game_mark),
            expression:
              "(HomePageState == '' && is_show_homes == 1) || (HomePageState == '' && !rawData.game_mark)"
          }
        ],
        staticClass: "x-c wellcome"
      },
      [
        _c("img", {
          attrs: { src: require("../../../assets/BGTOW.png"), alt: "" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }