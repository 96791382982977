var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("dataScreening", {
            attrs: {
              kamitaku: true,
              propsData: {
                isCurrency: _vm.tabType == "payAccountRetain" ? true : false
              }
            },
            on: {
              searchChange: _vm.searchChange,
              currencyChange: _vm.currencyChange
            }
          }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "用户性质" },
                  on: { change: _vm.getListnew },
                  model: {
                    value: _vm.tabType,
                    callback: function($$v) {
                      _vm.tabType = $$v
                    },
                    expression: "tabType"
                  }
                },
                _vm._l(_vm.tabTypeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.tableData.length },
                  on: { click: _vm.exportdata }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reqLoading },
                  on: { click: _vm.getListnew }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "sticky-table-header",
                  rawName: "v-sticky-table-header",
                  value: 0,
                  expression: "0"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.reqLoading,
                  expression: "reqLoading"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "tableRef",
                data: _vm.tableData,
                "cell-class-name": _vm.cellStyle,
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  fixed: "",
                  prop: "date",
                  "class-name": "font-bold",
                  label: "日期",
                  width: "150"
                }
              }),
              _vm.tabType == "accountRetain"
                ? _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      prop: "num",
                      label: "新用户数",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.tabType == "activityRetain"
                ? _c("el-table-column", {
                    attrs: { prop: "num", label: "登录人数", width: "120" }
                  })
                : _vm._e(),
              _vm.tabType == "payAccountRetain"
                ? _c("el-table-column", {
                    attrs: { prop: "num", label: "人数", width: "120" }
                  })
                : _vm._e(),
              _vm._l(_vm.Dynamic, function(item, index) {
                return _c("el-table-column", {
                  key: "key" + index,
                  attrs: {
                    prop: "r" + item.value,
                    label: item.label,
                    width: "160",
                    "render-header": function(h, obj) {
                      return _vm.renderheader(h, obj, item.desc)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row["r" + item.value]
                                    ? row["r" + item.value] +
                                        " / " +
                                        row["n" + item.value]
                                    : row["n" + item.value]
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", {
        staticClass: "margin-top",
        staticStyle: { height: "600px" },
        attrs: { id: "kamitakuactivityRetainEchart" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }