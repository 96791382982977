import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { chatGroupType, getchartList } from "@/api/dataAll/chartMonitor";
export default {
  name: "chatMonitor",
  mixins: [tabulation],
  data: function data() {
    return {
      chartTypeArr: [{
        label: "群组",
        value: "1"
      }, {
        label: "私聊",
        value: "2"
      }],
      groupList: [],
      searchData: {
        is_group: '2',
        to_grouo_id: '',
        kind: 1,
        is_history: '2',
        page_time: 0,
        user_id: '',
        page_user_id: '',
        to_user_id: '',
        page: 1
      },
      isFn: true,
      searchDis_group: '2',
      tableData: [],
      reqLoading: false,
      scrollContainer: null,
      isQuer: true
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    groupChange: function groupChange(e) {
      var _this = this;

      this.searchData.to_grouo_id = '';
      this.searchData.user_id = '';
      this.searchData.to_user_id = '';

      if (e == '1') {
        chatGroupType(this.searchData).then(function (res) {
          if (res.status == 200) {
            _this.groupList = res.data;
          }
        });
      }
    },
    searchClick: function searchClick(page) {
      this.isQuer = true;
      this.getList(page);
    },
    // 传时间戳的分页
    getList: function getList() {
      var _this2 = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var st = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      if (this.searchData.is_group == '1' && !this.searchData.to_grouo_id) {
        this.$message.error('请选择群组');
        return;
      }

      this.reqLoading = true;
      this.searchData.is_history = type;

      if (st) {
        this.searchData.is_history = '2';
        this.searchData.page_time = 0;
        this.tableData = [];
      }

      if (!this.isQuer && type == 1) {
        this.reqLoading = false;
        return;
      }

      getchartList(this.searchData).then(function (res) {
        if (res.status == 200) {
          _this2.isQuer = _this2.searchData.page_time == res.data[res.data.length - 1].time && type == 1 ? false : true;
          _this2.searchDis_group = _this2.searchData.is_group;
          var list = type == 1 ? [].concat(_toConsumableArray(_this2.tableData), _toConsumableArray(res.data)) : [].concat(_toConsumableArray(res.data), _toConsumableArray(_this2.tableData));
          _this2.tableData = _this2.fnTo(list);

          _this2.$nextTick(function () {
            _this2.containerFn();
          });
        }
      }).finally(function () {
        _this2.reqLoading = false;
      });
    },
    containerFn: function containerFn() {
      var that = this;
      this.scrollContainer = document.getElementById('scrollContainer');
      this.scrollContainer.addEventListener('scroll', this.scFn);
    },
    scFn: function scFn() {
      var that = this;

      if (!this.reqLoading) {
        if (this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop === this.scrollContainer.clientHeight) {
          that.searchData.is_history = '1';
          that.searchData.page_time = that.tableData[that.tableData.length - 1].time;
          that.searchData.page_user_id = that.tableData[that.tableData.length - 1].user_id;
          that.getList(1, false);
        }

        if (this.scrollContainer.scrollTop === 0) {
          that.searchData.is_history = '2';
          that.searchData.page_time = that.tableData[0].time;
          that.searchData.page_user_id = that.tableData[0].user_id;
          that.getList(2, false);
        }
      }
    },
    // 去重
    fnTo: function fnTo(list) {
      var newArr = [];

      for (var i = 0; i < list.length; i++) {
        if (newArr.indexOf(list[i].user_id + list[i].content + list[i].time) == -1) {
          newArr.push(list[i].user_id + list[i].content + list[i].time);
        } else {
          list.splice(i, 1);
          i--;
        }
      }

      console.log(list);
      return list;
    } // 传页码的分页
    // getList(page = 1) {
    //   if (this.value2.length > 0) {
    //     this.searchData.starttime = this.value2[0];
    //     this.searchData.endtime = this.value2[1];
    //   }
    //   if (this.searchData.is_group == '1' && !this.searchData.to_grouo_id) {
    //     this.$message.error('请选择群组')
    //     return
    //   }
    //   this.reqLoading = true
    //   this.searchData.page = page
    //   getchartList(this.searchData).then((res) => {
    //     if (res.status == 200) {
    //       this.searchDis_group = this.searchData.is_group
    //       this.tableData = page == 1 ? res.data.data : [...this.tableData, ...res.data.data]
    //       this.isFn = res.data.data.length ? true : false
    //       this.$nextTick(() => {
    //         this.containerFn()
    //       })
    //     }
    //   }).finally(() => {
    //     this.reqLoading = false
    //   });
    // },
    // containerFn() {
    //   let that = this
    //   this.scrollContainer = document.getElementById('scrollContainer');
    //   this.scrollContainer.addEventListener('scroll', this.scFn);
    // },
    // scFn() {
    //   let that = this
    //   if (!this.reqLoading) {
    //     if (this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop === this.scrollContainer.clientHeight) {
    //       if(that.isFn){
    //         let page = this.searchData.page + 1
    //         that.searchClick(page)
    //       }
    //     }
    //     if (this.scrollContainer.scrollTop === 0) {
    //       this.isFn = true
    //       that.searchClick(1)
    //     }
    //   }
    // }

  }
};