import "core-js/modules/es.object.to-string";
import { listNo } from "@/api/advRelation";
import { advertChannelManageData, callbackSwitchConfigManageData, eventManageData, callbackEventReflectionManageData } from "@/api/financial/backhaul";
export default {
  props: {},
  data: function data() {
    return {
      searchData: {},
      productLists: [],
      advertChannelData: [],
      callbackSwitchConfigData: [],
      eventManageDataList: [],
      callbackEventReflectionManageDataList: []
    };
  },
  activated: function activated() {},
  created: function created() {},
  watch: {},
  computed: {},
  methods: {
    getListNo: function getListNo() {
      var _this = this;

      listNo().then(function (res) {
        if (res.status == 200) {
          var list = ['game', 'platform', 'channel', 'pkg'];
          _this.productLists = res.data.length ? _this.$utils.dataConversion(res.data, list) : [];
        }
      });
    },
    getadvertChannelManageData: function getadvertChannelManageData() {
      var _this2 = this;

      advertChannelManageData().then(function (res) {
        if (res.status == 200) {
          _this2.advertChannelData = res.data;
        }
      });
    },
    getcallbackSwitchConfigManageData: function getcallbackSwitchConfigManageData() {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        callbackSwitchConfigManageData().then(function (res) {
          if (res.status == 200) {
            _this3.callbackSwitchConfigData = res.data;
            resolve();
          }
        });
      });
    },
    geteventManageData: function geteventManageData(e) {
      var _this4 = this;

      eventManageData(e).then(function (res) {
        if (res.status == 200) {
          _this4.eventManageDataList = res.data;
        }
      });
    },
    gettes: function gettes() {
      var _this5 = this;

      callbackEventReflectionManageData().then(function (res) {
        if (res.status == 200) {
          _this5.callbackEventReflectionManageDataList = res.data;
        }
      });
    }
  }
};