import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { onlineDatadv } from "@/api/dataAll/onlineDatadv";
import * as echarts from "echarts";
export default {
  name: "onlineDatadv",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      tableTitle: [],
      gameId: "",
      value1: "",
      value2: [],
      tableData: [],
      tableDate: "",
      show: false,
      echarts: false,
      dates: ["新增人数", "活跃人数", "总登录次数", "最高在线人数"],
      series: [],
      legend: []
    };
  },
  created: function created() {},
  methods: {
    searchClick: function searchClick() {
      var _this = this;

      if (this.value2.length > 0) {
        this.searchData.starttime = this.value2[0];
        this.searchData.endtime = this.value2[1];
      }

      this.reqLoading = true;
      onlineDatadv(this.searchData).then(function (res) {
        _this.legend = res.data.date_array;
        _this.series = [];
        var data_array = res.data.data;
        var date_array = res.data.date_array;

        var objToTitle = function objToTitle(obj) {
          return Object.keys(obj).map(function (k) {
            return obj[k];
          });
        };

        var objToTitle2 = function objToTitle2(obj) {
          return Object.keys(obj).map(function (k) {
            return k;
          });
        };

        var table = objToTitle(data_array);
        date_array.forEach(function (item, i) {
          var obj = {
            name: item,
            type: "bar",
            data: [],
            smooth: true
          };
          table.forEach(function (val, index) {
            if (i == index) {
              val.date = item;
              obj.data = objToTitle(val);
            }
          });

          _this.series.push(obj);
        });
        _this.tableData = table;
        _this.show = true;
        _this.echarts = true;

        _this.getechart();

        _this.$nextTick(function () {
          _this.getTableRight();
        });
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("onlineDatadvEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "在线设备(台)"
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.legend,
          // 这里显示图表的最上面
          width: "80%" // selected: {
          //   新增人数: true,
          //   活跃人数: true,
          //   总登录次数: true,
          //   最高在线设备数: true,
          // },

        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.dates
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      var columns = [{
        label: "时间",
        prop: "date"
      }, {
        label: "新增人数",
        prop: "new_device_num"
      }, {
        label: "活跃人数",
        prop: "login_device_num"
      }, {
        label: "总登录次数",
        prop: "login_device_times"
      }, {
        label: "最高在线设备数",
        prop: "max_online_num"
      }];
      var data = this.tableData;
      this.$export.excel({
        columns: columns,
        data: data,
        title: this.$route.meta.title
      });
    }
  }
};