import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmToolNewCom, gmToolNewPage } from "@/mixins/index";
import uploadImagetow from '@/components/uploadImagetow';
import { szgmdoSend } from '@/api/gmToolNew/gmToolNew';
export default {
  components: {
    uploadImagetow: uploadImagetow
  },
  mixins: [gmToolNewCom, gmToolNewPage],
  data: function data() {
    var validateuids = function validateuids(rule, value, callback) {
      var regex = /[\u4e00-\u9fa5，]/;

      if (regex.test(value) || !value) {
        callback(new Error('角色ID不正确(以英文逗号隔开 1,2,3)'));
      } else {
        callback();
      }
    };

    return {
      paramsData: {
        uids: '',
        type: 'to_users',
        id: '',
        server_id: '',
        expire: '',
        start_time: '',
        end_time: '',
        serverData: []
      },
      expires: [],
      dialogShow: false,
      emailType: [{
        label: '发送所有人',
        value: 'all'
      }, {
        label: '发送目标账号',
        value: 'to_users'
      }, {
        label: '补偿邮件',
        value: 'compensate'
      }],
      rules: {
        uids: [{
          required: true,
          validator: validateuids,
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择类型",
          trigger: "blur"
        }],
        server_id: [{
          required: true,
          message: "请选择服务器",
          trigger: "blur"
        }],
        expire: [{
          required: true,
          message: "请选择过期时间",
          trigger: "blur"
        }],
        expires: [{
          required: true,
          message: "请选择时间",
          trigger: "blur"
        }]
      },
      isPermissions: false
    };
  },
  created: function created() {},
  methods: {
    open: function open(data) {
      var isPermissions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.paramsData.id = data.id;
      var nowD = (new Date(this.$times.zeroTimeZone()).getTime() + 3600 * 1000 * 24 * 7) / 1000;
      this.paramsData.expire = this.$times.timestampToTime(nowD, 'YY-MM-DD HH:II:SS');
      this.paramsData.start_time = this.$times.timestampToTime(nowD, 'YY-MM-DD');
      this.paramsData.end_time = this.$times.timestampToTime(nowD, 'YY-MM-DD');
      this.expires = [this.$times.timestampToTime(nowD, 'YY-MM-DD'), this.$times.timestampToTime(nowD, 'YY-MM-DD')];
      this.dialogShow = true;

      if (isPermissions) {
        this.isPermissions = isPermissions;
        this.paramsData.type = this.emailType[1].value;
      }
    },
    serverIdChange: function serverIdChange(e) {
      var _this = this;

      this.paramsData.serverData = [];
      e.forEach(function (i) {
        _this.paramsData.serverData.push({
          label: i,
          value: ''
        });
      });
    },
    searchTimeChange: function searchTimeChange(e) {
      this.paramsData.start_time = e[0];
      this.paramsData.end_time = e[1];
    },
    fileUp: function fileUp(e, index) {
      this.paramsData.serverData[index].value = e.data.toString();
    },
    clearData: function clearData(isClearId) {
      this.paramsData = {
        uids: '',
        type: 'to_users',
        server_id: '',
        serverData: [],
        id: isClearId ? '' : this.paramsData.id,
        expire: this.$times.timestampToTime((new Date(this.$times.zeroTimeZone()).getTime() + 3600 * 1000 * 24 * 7) / 1000, 'YY-MM-DD HH:II:SS')
      };
      this.server_channel = '';
      this.show_test = '';
      this.serverListYJ = [];
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.paramsData));
          var uids = data.uids.split(',');
          data.server_id = data.server_id.toString();

          if (data.type == 'to_users') {
            if (data.serverData.length > 1) {
              var d = {};

              _this2.paramsData.serverData.forEach(function (i) {
                var uidI = i.value.split(',');
                uidI = uidI.map(function (i) {
                  return i = parseInt(i);
                });
                d[i.label] = uidI;
              });

              data.uids = JSON.stringify(d);
            } else {
              var uidI = _this2.paramsData.serverData[0].value.split(',');

              uidI = uidI.map(function (i) {
                return i = parseInt(i);
              });
              data.uids = JSON.stringify(uidI);
            }
          } else {
            uids = uids.map(function (i) {
              return i = parseInt(i);
            });
            data.uids = data.uids ? JSON.stringify(uids) : '';
          }

          szgmdoSend(_objectSpread(_objectSpread({}, data), {}, {
            env_id: _this2.propsData.env_id
          })).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                type: "success",
                message: '操作成功'
              });

              _this2.dialogShow = false;

              _this2.$emit('change');
            }
          });
        }
      });
    }
  }
};