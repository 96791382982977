import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    params: data
  });
}
export function getInfo(data) {
  return request({
    url: '/user/userInfo',
    method: 'get',
    params: data //  params: { token }

  });
}
export function logout() {
  return request({
    url: '/logout',
    method: 'get'
  });
}
export function userLogin(data) {
  return request({
    url: '/login',
    method: 'post',
    params: data
  });
}
export function getAuthMenu(data) {
  return request({
    url: '/route/list',
    method: 'get',
    params: data
  });
} // 获取验证码

export function getCode(data) {
  return request({
    url: '/verification_code',
    method: 'get',
    params: data
  });
}
export function usersList(data) {
  return request({
    url: '/auth/users',
    method: 'get',
    params: data
  });
}
export function usersListAll(data) {
  return request({
    url: '/auth/usersAll',
    method: 'get',
    params: data
  });
}
export function usersListAlls(data) {
  return request({
    url: '/usersAll',
    method: 'get',
    params: data
  });
}
export function queryUsers(data) {
  return request({
    url: '/user/add',
    method: 'get',
    params: data
  });
}
export function disableUser(data) {
  return request({
    url: '/user/disableUser',
    method: 'get',
    params: data
  });
}
export function userAdd(data) {
  return request({
    url: '/user/add',
    method: 'post',
    params: data
  });
}
export function queryupUser(data) {
  return request({
    url: '/user/upUser',
    method: 'get',
    params: data
  });
}
export function upUser(data) {
  return request({
    url: '/user/upUser',
    method: 'post',
    params: data
  });
}
export function delUser(data) {
  return request({
    url: '/user/delUser',
    method: 'get',
    params: data
  });
}
export function userGame(data) {
  return request({
    url: '/user/userGame',
    method: 'get',
    params: data
  });
}
export function PostuserGame(data) {
  return request({
    url: '/user/userGame',
    method: 'post',
    params: data
  });
}
export function getroutes(data) {
  return request({
    url: '/route/sort',
    method: 'get',
    params: data
  });
} // 获取路由列表

export function getDatas(data) {
  return request({
    url: '/auth/routes',
    method: 'get',
    params: data
  });
} // 已绑定广告渠道

export function adChannelList(data) {
  return request({
    url: '/user/ad-channel-list',
    method: 'get',
    params: data
  });
} // 已绑定广告位置

export function adPlacementList(data) {
  return request({
    url: '/user/ad-placement-list',
    method: 'get',
    params: data
  });
} // 

export function getUserGetPlant(data) {
  return request({
    url: '/user/userGetPlant',
    method: 'get',
    params: data
  });
} // 绑定用户与广告渠道

export function bindAdChannel(data) {
  return request({
    url: '/user/bind-ad-channel',
    method: 'post',
    params: data
  });
} // 绑定用户与广告位置

export function bindAdPlacement(data) {
  return request({
    url: '/user/bind-ad-placement',
    method: 'post',
    params: data
  });
}
/**
 * 
 * 数据筛选
 */
// 获取

export function screenDataGet(data) {
  return request({
    url: '/data/screenDataGet',
    method: 'get',
    params: data
  });
} // 储存

export function screenDataAdd(data) {
  return request({
    url: '/data/screenDataAdd',
    method: 'post',
    params: data
  });
} // 获取用户权限路由

export function getUserMenu(data) {
  return request({
    url: '/getUserMenu',
    method: 'get',
    params: data
  });
}