import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
export default {
  props: {
    activests: {
      type: String,
      data: function data() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      date2: [],
      tableData: [],
      tableDataList: [],
      screen: [],
      screenList: [{
        label: "充值",
        prop: "account_recharge_amount",
        width: "74"
      }, // {
      //   label:'充值货币',
      //   prop:''
      // },
      {
        label: "账号ARPU",
        prop: "account_arpu",
        width: "83"
      }, {
        label: "账号ARPPU",
        prop: "account_arppu",
        width: "92"
      }, {
        label: "注册设备数",
        prop: "register_device_pnum",
        width: "83"
      }, {
        label: "注册账号数",
        prop: "register_account_pnum",
        width: "83"
      }, {
        label: "登录设备数",
        prop: "login_device_pnum",
        width: "83"
      }, {
        label: "登录账号数",
        prop: "login_account_pnum",
        width: "83"
      }, {
        label: "注册未登录设备数",
        prop: "diff_device_register_login",
        width: "119"
      }, {
        label: "注册未登录账号数",
        prop: "diff_account_register_login",
        width: "119"
      }, {
        label: "活跃设备数",
        prop: "active_device_pnum",
        width: "83"
      }, {
        label: "活跃账号数",
        prop: "active_account_pnum",
        width: "83"
      }, {
        label: "充值设备数",
        prop: "recharge_device_pnum",
        width: "83"
      }, {
        label: "充值账号数",
        prop: "recharge_account_pnum",
        width: "83"
      }, {
        label: "注册账号充值",
        prop: "register_recharge_account_amount",
        width: "95"
      }, {
        label: "注册设备充值",
        prop: "register_recharge_device_amount",
        width: "95"
      }, {
        label: "注册账号充值数",
        prop: "register_recharge_account_pnum",
        width: "107"
      }, {
        label: "注册设备充值数",
        prop: "register_recharge_device_pnum",
        width: "107"
      }, {
        label: "首充账号充值",
        prop: "first_recharge_account_amount",
        width: "95"
      }, {
        label: "首充设备充值",
        prop: "first_recharge_device_amount",
        width: "95"
      }, {
        label: "首充账号数",
        prop: "first_recharge_account_pnum",
        width: "83"
      }, {
        label: "首充设备数",
        prop: "first_recharge_device_pnum",
        width: "83"
      }],
      screenListP: [{
        label: "充值",
        prop: "account_recharge_amount",
        width: "74"
      }, // {
      //   label:'充值货币',
      //   prop:''
      // },
      {
        label: "账号ARPU",
        prop: "account_arpu",
        width: "83"
      }, {
        label: "账号ARPPU",
        prop: "account_arppu",
        width: "92"
      }, {
        label: "注册设备数",
        prop: "register_device_pnum",
        width: "83"
      }, {
        label: "注册账号数",
        prop: "register_account_pnum",
        width: "83"
      }, {
        label: "登录设备数",
        prop: "login_device_pnum",
        width: "83"
      }, {
        label: "登录账号数",
        prop: "login_account_pnum",
        width: "83"
      }, {
        label: "注册未登录设备数",
        prop: "diff_device_register_login",
        width: "119"
      }, {
        label: "注册未登录账号数",
        prop: "diff_account_register_login",
        width: "119"
      }, {
        label: "活跃设备数",
        prop: "active_device_pnum",
        width: "83"
      }, {
        label: "活跃账号数",
        prop: "active_account_pnum",
        width: "83"
      }, {
        label: "充值设备数",
        prop: "recharge_device_pnum",
        width: "83"
      }, {
        label: "充值账号数",
        prop: "recharge_account_pnum",
        width: "83"
      }, {
        label: "注册账号充值",
        prop: "register_recharge_account_amount",
        width: "95"
      }, {
        label: "注册设备充值",
        prop: "register_recharge_device_amount",
        width: "95"
      }, {
        label: "注册账号充值数",
        prop: "register_recharge_account_pnum",
        width: "107"
      }, {
        label: "注册设备充值数",
        prop: "register_recharge_device_pnum",
        width: "107"
      }, {
        label: "首充账号充值",
        prop: "first_recharge_account_amount",
        width: "95"
      }, {
        label: "首充设备充值",
        prop: "first_recharge_device_amount",
        width: "95"
      }, {
        label: "首充账号数",
        prop: "first_recharge_account_pnum",
        width: "83"
      }, {
        label: "首充设备数",
        prop: "first_recharge_device_pnum",
        width: "83"
      }],
      screenListVND: [{
        label: "充值",
        prop: "account_recharge_amount",
        width: "74"
      }, {
        label: "充值(美元)",
        prop: "account_recharge_amountexchange",
        width: "110"
      }, // {
      //   label:'充值货币',
      //   prop:''
      // },
      {
        label: "账号ARPU",
        prop: "account_arpu",
        width: "83"
      }, {
        label: "账号ARPPU",
        prop: "account_arppu",
        width: "92"
      }, {
        label: "注册设备数",
        prop: "register_device_pnum",
        width: "83"
      }, {
        label: "注册账号数",
        prop: "register_account_pnum",
        width: "83"
      }, {
        label: "登录设备数",
        prop: "login_device_pnum",
        width: "83"
      }, {
        label: "登录账号数",
        prop: "login_account_pnum",
        width: "83"
      }, {
        label: "注册未登录设备数",
        prop: "diff_device_register_login",
        width: "119"
      }, {
        label: "注册未登录账号数",
        prop: "diff_account_register_login",
        width: "119"
      }, {
        label: "活跃设备数",
        prop: "active_device_pnum",
        width: "83"
      }, {
        label: "活跃账号数",
        prop: "active_account_pnum",
        width: "83"
      }, {
        label: "充值设备数",
        prop: "recharge_device_pnum",
        width: "83"
      }, {
        label: "充值账号数",
        prop: "recharge_account_pnum",
        width: "83"
      }, {
        label: "注册账号充值",
        prop: "register_recharge_account_amount",
        width: "95"
      }, {
        label: "注册账号充值(美元)",
        prop: "register_recharge_account_amountexchange",
        width: "140"
      }, {
        label: "注册设备充值",
        prop: "register_recharge_device_amount",
        width: "95"
      }, {
        label: "注册设备充值(美元)",
        prop: "register_recharge_device_amountexchange",
        width: "140"
      }, {
        label: "注册账号充值数",
        prop: "register_recharge_account_pnum",
        width: "107"
      }, {
        label: "注册设备充值数",
        prop: "register_recharge_device_pnum",
        width: "107"
      }, {
        label: "首充账号充值",
        prop: "first_recharge_account_amount",
        width: "95"
      }, {
        label: "首充设备充值",
        prop: "first_recharge_device_amount",
        width: "95"
      }, {
        label: "首充账号数",
        prop: "first_recharge_account_pnum",
        width: "83"
      }, {
        label: "首充设备数",
        prop: "first_recharge_device_pnum",
        width: "83"
      }],
      screenListUSD: [{
        label: "充值",
        prop: "account_recharge_amount",
        width: "74"
      }, {
        label: "充值(越南盾)",
        prop: "account_recharge_amountexchange",
        width: "110"
      }, // {
      //   label:'充值货币',
      //   prop:''
      // },
      {
        label: "账号ARPU",
        prop: "account_arpu",
        width: "83"
      }, {
        label: "账号ARPPU",
        prop: "account_arppu",
        width: "92"
      }, {
        label: "注册设备数",
        prop: "register_device_pnum",
        width: "83"
      }, {
        label: "注册账号数",
        prop: "register_account_pnum",
        width: "83"
      }, {
        label: "登录设备数",
        prop: "login_device_pnum",
        width: "83"
      }, {
        label: "登录账号数",
        prop: "login_account_pnum",
        width: "83"
      }, {
        label: "注册未登录设备数",
        prop: "diff_device_register_login",
        width: "119"
      }, {
        label: "注册未登录账号数",
        prop: "diff_account_register_login",
        width: "119"
      }, {
        label: "活跃设备数",
        prop: "active_device_pnum",
        width: "83"
      }, {
        label: "活跃账号数",
        prop: "active_account_pnum",
        width: "83"
      }, {
        label: "充值设备数",
        prop: "recharge_device_pnum",
        width: "83"
      }, {
        label: "充值账号数",
        prop: "recharge_account_pnum",
        width: "83"
      }, {
        label: "注册账号充值",
        prop: "register_recharge_account_amount",
        width: "95"
      }, {
        label: "注册账号充值(越南盾)",
        prop: "register_recharge_account_amountexchange",
        width: "140"
      }, {
        label: "注册设备充值",
        prop: "register_recharge_device_amount",
        width: "95"
      }, {
        label: "注册设备充值(越南盾)",
        prop: "register_recharge_device_amountexchange",
        width: "140"
      }, {
        label: "注册账号充值数",
        prop: "register_recharge_account_pnum",
        width: "107"
      }, {
        label: "注册设备充值数",
        prop: "register_recharge_device_pnum",
        width: "107"
      }, {
        label: "首充账号充值",
        prop: "first_recharge_account_amount",
        width: "95"
      }, {
        label: "首充设备充值",
        prop: "first_recharge_device_amount",
        width: "95"
      }, {
        label: "首充账号数",
        prop: "first_recharge_account_pnum",
        width: "83"
      }, {
        label: "首充设备数",
        prop: "first_recharge_device_pnum",
        width: "83"
      }],
      collectNum: ["register_device_pnum", "register_account_pnum", "login_device_pnum", "login_account_pnum", "diff_device_register_login", "diff_account_register_login", "active_device_pnum", "active_account_pnum", "recharge_device_pnum", "recharge_account_pnum", "register_recharge_device_pnum", "first_recharge_account_pnum", "first_recharge_device_pnum", "account_arpu", "account_arppu"],
      collectAm: ["account_recharge_amount", "register_recharge_account_amount", "register_recharge_device_amount", "first_recharge_account_amount", "first_recharge_device_amount"],
      processingList: ["account_recharge_amount", "register_recharge_account_amount", "register_recharge_device_amount", "first_recharge_account_amount", "first_recharge_device_amount", "account_arpu", "account_arppu", "register_recharge_account_amountexchange", "register_recharge_device_amountexchange", "account_recharge_amountexchange"],
      processingListTow: ["account_recharge_amount", "register_recharge_account_amount", "register_recharge_device_amount", "first_recharge_account_amount", "first_recharge_device_amount", "register_recharge_account_amountexchange", "register_recharge_device_amountexchange", "account_recharge_amountexchange"],
      isQuery: false
    };
  },
  watch: {
    activests: {
      handler: function handler(val) {
        if (this.name == val && !this.isQuery && this.name != '') {
          this.isQuery = true;
          this.searchClick();
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.getDate && this.getDate(); // setTimeout(() => {
    //   this.searchClick();
    // }, 500);
    // this.$bus.$on("upd", () => {
    //   this.searchClick();
    // });
  },
  methods: {
    // 统一筛选功能
    screenChange: function screenChange() {
      var _this = this;

      var list = [];
      this.screenList.map(function (item) {
        if (_this.screen.findIndex(function (t) {
          return t == item.prop;
        }) > -1) {
          list.push(item);
        }
      });
      this.tableDataList = list;
    },
    //
    handleSelect: function handleSelect(e) {
      var _this2 = this;

      this.activeIndex = e;
      this.$nextTick(function () {
        setTimeout(function () {
          _this2["echart" + e] && _this2["echart" + e].resize();
        }, 300);
      });
    }
  }
};