var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: { select: _vm.modelChange }
        },
        [
          _c("el-menu-item", { attrs: { index: "advertising" } }, [
            _vm._v("广告渠道管理")
          ]),
          _c("el-menu-item", { attrs: { index: "productBinding" } }, [
            _vm._v("产品绑定广告渠道回传")
          ]),
          _c("el-menu-item", { attrs: { index: "eventm" } }, [
            _vm._v("事件管理")
          ]),
          _c("el-menu-item", { attrs: { index: "predefined" } }, [
            _vm._v("预定义条件管理")
          ]),
          _c("el-menu-item", { attrs: { index: "eventBinding" } }, [
            _vm._v("事件绑定回传渠道")
          ]),
          _c("el-menu-item", { attrs: { index: "eventReturn" } }, [
            _vm._v("事件回传条件设置")
          ])
        ],
        1
      ),
      _c("advertising", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "advertising",
            expression: "activeIndex == 'advertising'"
          }
        ],
        ref: "advertising"
      }),
      _c("productBinding", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "productBinding",
            expression: "activeIndex == 'productBinding'"
          }
        ],
        ref: "productBinding"
      }),
      _c("eventm", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "eventm",
            expression: "activeIndex == 'eventm'"
          }
        ],
        ref: "eventm"
      }),
      _c("predefined", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "predefined",
            expression: "activeIndex == 'predefined'"
          }
        ],
        ref: "predefined"
      }),
      _c("eventBinding", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "eventBinding",
            expression: "activeIndex == 'eventBinding'"
          }
        ],
        ref: "eventBinding"
      }),
      _c("eventReturn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeIndex == "eventReturn",
            expression: "activeIndex == 'eventReturn'"
          }
        ],
        ref: "eventReturn"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }