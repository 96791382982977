import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// 无权限管理接口
import { getGame, getPlatform, getChannel, getPkg, searchProductAdvPlatformNoAccess, getProductData } from "@/api/serchSelect";
import { mapState } from "vuex";
export default {
  data: function data() {
    return {
      adPlacementSingleChoice: false,
      searchplatforms: [],
      searchchannels: [],
      searchpkgs: [],
      products: [],
      advchannels: [],
      advLocation: []
    };
  },
  activated: function activated() {
    if (!this.productAll.length) {
      this.$store.dispatch("d2admin/serchSelect/getAllProduct");
    }

    if (!this.searchgames.length) {
      this.$store.dispatch("d2admin/serchSelect/getAllGame");
    }
  },
  mounted: function mounted() {},
  computed: _objectSpread({}, mapState("d2admin", {
    productAll: function productAll(state) {
      return state.serchSelect.productAll;
    },
    searchgames: function searchgames(state) {
      return state.serchSelect.gameAll;
    },
    rawData: function rawData(state) {
      return state.serchSelect.rawData;
    }
  })),
  methods: {
    gamechangeManage: function gamechangeManage(game_mark) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.searchData.platform = "";
        _this.searchData.channel = "";
        _this.searchData.pkg = "";
        _this.searchplatforms = [];
        _this.searchchannels = [];
        _this.searchpkgs = [];

        if (game_mark != "") {
          getPlatform({
            game_id: _this.searchgames.find(function (item) {
              return item.game_mark == game_mark;
            }).game_id
          }).then(function (res) {
            _this.searchplatforms = res.data;
            resolve();
          });
        }
      });
    },
    searchProductData: function searchProductData(data) {
      return new Promise(function (resolve, reject) {
        getProductData(data).then(function (res) {
          if (res.status == 200) {
            resolve(res.data);
          }
        });
      });
    },
    platformchangeManage: function platformchangeManage(platform_mark) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        _this2.searchData.channel = "";
        _this2.searchData.pkg = "";
        _this2.searchchannels = [];
        _this2.searchpkgs = [];

        if (platform_mark != "") {
          getChannel({
            platform_id: _this2.searchplatforms.find(function (item) {
              return item.platform_mark == platform_mark;
            }).id
          }).then(function (res) {
            _this2.searchchannels = res.data;
            resolve();
          });
        }
      });
    },
    channelchangeManage: function channelchangeManage(channel_mark) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.searchData.pkg = "";
        _this3.searchpkgs = [];

        if (channel_mark != "") {
          getPkg({
            channel_id: _this3.searchchannels.find(function (item) {
              return item.channel_mark == channel_mark;
            }).id
          }).then(function (res) {
            _this3.searchpkgs = res.data;
            resolve();
          });
        }
      });
    },
    productChange: function productChange(e) {
      var _this4 = this;

      this.searchData.ad_channel = '';
      this.searchData.ad_placement = '';
      this.advchannels = [];
      this.advLocation = [];
      this.ex_Val = [];
      this.ex_ValAdv = [];

      if (!e) {
        return;
      }

      searchProductAdvPlatformNoAccess({
        special_product_id: e
      }).then(function (res) {
        if (res.status == 200) {
          // let list =  res.data.findIndex((i) => i.adv_platform_mark == "default") == -1 ? [{ adv_platform_mark: "default", adv_platform_name: "默认", adv_place_data: [{ adv_place_mark: "default", adv_place_name: "默认" }] }, ...res.data] : res.data;
          res.data.map(function (i) {
            i.adv_place_data.map(function (j) {
              j.adv_place_mark = j.adv_place_mark ? j.adv_place_mark : 'valuets';
            });
          });
          _this4.advchannels = res.data;
        }
      });
    },
    // 表单统一执行数据处理
    processingData: function processingData(data) {
      data.ad_placement = data.ad_placement ? data.ad_placement : 'valuets';
      return data;
    }
  }
};