var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "自定义名称", prop: "switch_config_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择自定义名称"
                  },
                  model: {
                    value: _vm.formData.switch_config_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "switch_config_id", $$v)
                    },
                    expression: "formData.switch_config_id"
                  }
                },
                _vm._l(_vm.callbackSwitchConfigData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.advert_channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "自定义事件名称", prop: "event_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择自定义事件名称"
                  },
                  model: {
                    value: _vm.formData.event_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "event_id", $$v)
                    },
                    expression: "formData.event_id"
                  }
                },
                _vm._l(_vm.callbackEventReflectionManageDataList, function(
                  item
                ) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.custom_event_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { width: "100%", "margin-bottom": "0" } },
            [
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { prop: "rule" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addContent }
                    },
                    [_vm._v("添加条件")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.formData.rule, function(domain, index) {
            return _c("div", { key: index + "k", staticClass: "ruleClass" }, [
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        prop: "rule." + index + ".field",
                        rules: _vm.rules.field,
                        "label-width": "60px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity",
                        attrs: { clearable: "", placeholder: "条件" },
                        model: {
                          value: domain.field,
                          callback: function($$v) {
                            _vm.$set(domain, "field", $$v)
                          },
                          expression: "domain.field"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex-1",
                      staticStyle: { padding: "0 20px" },
                      attrs: {
                        prop: "rule." + index + ".operator",
                        rules: _vm.rules.operator,
                        "label-width": "60px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "操作符号"
                          },
                          model: {
                            value: domain.operator,
                            callback: function($$v) {
                              _vm.$set(domain, "operator", $$v)
                            },
                            expression: "domain.operator"
                          }
                        },
                        _vm._l(_vm.conditionManageDataList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.condition_name,
                              value: item.condition_mark
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        prop: "rule." + index + ".value",
                        rules: _vm.rules.value,
                        "label-width": "60px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity ",
                        attrs: { clearable: "", placeholder: "条件值" },
                        model: {
                          value: domain.value,
                          callback: function($$v) {
                            _vm.$set(domain, "value", $$v)
                          },
                          expression: "domain.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticStyle: { margin: "0 10px 24px 10px" },
                    attrs: {
                      size: "mini",
                      type: "danger",
                      circle: "",
                      icon: "el-icon-delete"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.removeContent(domain)
                      }
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }