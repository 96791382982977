import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";

var _data$watch$computed$;

import { mapState, mapActions } from "vuex";
export default (_data$watch$computed$ = {
  data: function data() {
    return {
      reqFn: "",
      isreq: false,
      automatic: false
    };
  },
  watch: {
    homeEvents: function homeEvents(newVal) {
      if (this.is_show_homes !== 1 && !this.isreq) {
        // this.getServerList && this.getServerList();
        this.resettingData();

        if (this.automatic) {
          this[this.reqFn]();
        }
      }
    }
  },
  computed: _objectSpread({}, mapState("d2admin", {
    rawData: function rawData(state) {
      return state.serchSelect.rawData;
    }
  })),
  created: function created() {
    var _this = this;

    if (this.is_show_homes !== 1 && !this.isreq) {
      if (this.searchData.game_id) {
        this.resettingData();

        if (this.automatic) {
          this[this.reqFn]();
          this.isreq = true;
        }
      } else {
        setTimeout(function () {
          _this.resettingData();

          if (_this.automatic) {
            _this[_this.reqFn]();

            _this.isreq = true;
          }
        }, 200);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.$off("upd");
  }
}, _defineProperty(_data$watch$computed$, "computed", _objectSpread({}, mapState("d2admin", {
  is_show_homes: function is_show_homes(state) {
    return state.user.is_show_home;
  },
  homeEvents: function homeEvents(state) {
    return state.serchSelect.homeEvents;
  }
}))), _defineProperty(_data$watch$computed$, "methods", {
  gettingData: function gettingData() {
    this[this.reqFn]();
  }
}), _data$watch$computed$);