import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addServer, updServer } from "@/api/gmToolNew/gmToolNew";
import { gmToolNew, gmToolNewPage, gmToolNewCom } from "@/mixins/index";
export default {
  mixins: [gmToolNew, gmToolNewPage, gmToolNewCom],
  data: function data() {
    return {
      dialogShow: false,
      paramsData: {
        serverId: '',
        serverName: '',
        is_timing: 2,
        time_to_run: '',
        config_state: '',
        OpenTime: '',
        is_test: '',
        new_tag: '',
        is_recomm: '',
        push_msg: '',
        areaID: '',
        ver_max: '',
        ver_min: '',
        serverID: '',
        serverIP: '',
        serverPort: ''
      },
      areaIDList: [{
        label: 'common',
        value: 'common'
      }, {
        label: 'google',
        value: 'google'
      }, {
        label: 'iOS',
        value: 'iOS'
      }],
      newTag: [{
        label: '是',
        value: true
      }, {
        label: '否',
        value: false
      }],
      rules: {
        is_timing: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        serverName: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        config_state: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        new_tag: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        is_recomm: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        time_to_run: [{
          required: true,
          message: "请选择时间",
          trigger: "blur"
        }],
        is_test: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        areaID: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        ver_max: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        ver_min: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        serverID: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        serverIP: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        serverPort: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        OpenTime: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }]
      },
      isUpd: true
    };
  },
  created: function created() {},
  methods: {
    open: function open(data, type) {
      this.isUpd = type;
      this.dialogShow = true;

      if (type) {
        this.paramsData = _objectSpread(_objectSpread({}, data), {}, {
          is_timing: 2,
          OpenTime: Number(data.openTime) * 1000
        });
      }
    },
    addtag: function addtag(data) {
      this.paramsData.push_msg = "".concat(this.paramsData.push_msg).concat(data);
    },
    addDomain: function addDomain() {
      this.paramsData.data.push({
        server_id: "",
        server_name: ""
      });
    },
    hiad: function hiad() {
      this.dialogShow = false;
      this.clearData();
    },
    removeDomain: function removeDomain(item) {
      var index = this.ex_Val.indexOf(item);

      if (index !== -1) {
        this.paramsData.data.splice(index, 1);
      }
    },
    clearData: function clearData() {
      this.paramsData = {
        serverID: '',
        serverName: '',
        is_timing: 2,
        time_to_run: '',
        config_state: '',
        new_tag: '',
        is_test: '',
        areaID: '',
        is_recomm: '',
        push_msg: '',
        ver_max: '',
        ver_min: '',
        serverIP: '',
        serverPort: '',
        OpenTime: ''
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var time_to_run = 0;

          if (_this.paramsData.time_to_run) {
            var timestamp = new Date().valueOf();
            time_to_run = _this.paramsData.time_to_run / 1000 - parseInt(timestamp / 1000);

            if (time_to_run <= 0) {
              _this.$message.error('定时时间不能小于当前时间');

              return;
            }
          }

          var data = {
            serverID: _this.paramsData.serverID,
            serverName: _this.paramsData.serverName,
            is_timing: _this.paramsData.is_timing,
            time_to_run: time_to_run,
            showTime: _this.paramsData.time_to_run,
            state: !_this.isUpd ? '' : JSON.stringify(_this.paramsData.config_state),
            config_state: _this.isUpd ? '' : JSON.stringify(_this.paramsData.config_state),
            new_tag: _this.paramsData.serverID ? JSON.stringify(_this.paramsData.new_tag) : _this.paramsData.new_tag,
            areaID: _this.paramsData.areaID,
            is_recomm: JSON.stringify(_this.paramsData.is_recomm),
            ver_min: _this.paramsData.ver_min,
            ver_max: _this.paramsData.ver_max,
            push_msg: _this.isUpd ? '' : _this.paramsData.push_msg,
            serverIP: _this.paramsData.serverIP,
            serverPort: _this.paramsData.serverPort,
            OpenTime: _this.paramsData.OpenTime / 1000
          };
          var reqFn = _this.isUpd ? updServer : addServer;
          reqFn(_objectSpread(_objectSpread({}, data), _this.propsData)).then(function (res) {
            if (res.code == 200) {
              _this.$message({
                message: '操作成功',
                type: 'success'
              });

              _this.dialogShow = false;

              _this.$emit('update');
            }
          });
        }
      });
    },
    textareaChange: function textareaChange() {
      var code = this.paramsData.textarea.split(/[(\r\n)\r\n]+/);
      var serList = [];
      code.forEach(function (item) {
        var d = item.split(",");
        serList.push({
          server_id: d[0],
          server_name: d[1]
        });
      });
    }
  }
};