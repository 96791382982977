export default {
  data: function data() {
    return {
      serverChannel: [],
      isserverChannel: false,
      AnnouncemenTypeListTow: [{
        label: '活动公告',
        value: 1
      }, {
        label: '系统公告',
        value: 2
      }],
      language: [{
        label: '简体中文',
        value: 'cn'
      }, {
        label: '繁体中文',
        value: 'tc'
      }, {
        label: '英文',
        value: 'en'
      }, {
        label: '越语',
        value: 'vn'
      }]
    };
  },
  activated: function activated() {},
  created: function created() {},
  methods: {}
};