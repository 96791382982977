import request from '@/utils/request'; //广告渠道列表

export function advertChannelManageData(data) {
  return request({
    url: '/financial/advertChannelManageData',
    method: 'get',
    params: data
  });
} //广告渠道新增

export function advertChannelManageAdd(data) {
  return request({
    url: '/financial/advertChannelManageAdd',
    method: 'post',
    params: data
  });
} //广告渠道修改

export function advertChannelManageUpd(data) {
  return request({
    url: '/financial/advertChannelManageUpd',
    method: 'post',
    params: data
  });
} //广告渠道删除

export function advertChannelManageDel(data) {
  return request({
    url: '/financial/advertChannelManageDel',
    method: 'post',
    params: data
  });
} //广告渠道列表

export function callbackSwitchConfigManageData(data) {
  return request({
    url: '/financial/callbackSwitchConfigManageData',
    method: 'get',
    params: data
  });
} //广告渠道新增

export function callbackSwitchConfigManageAdd(data) {
  return request({
    url: '/financial/callbackSwitchConfigManageAdd',
    method: 'post',
    params: data
  });
} //广告渠道修改

export function callbackSwitchConfigManageDel(data) {
  return request({
    url: '/financial/callbackSwitchConfigManageDel',
    method: 'post',
    params: data
  });
} //广告渠道删除

export function callbackSwitchConfigManageUpd(data) {
  return request({
    url: '/financial/callbackSwitchConfigManageUpd',
    method: 'post',
    params: data
  });
} //事件列表

export function eventManageData(data) {
  return request({
    url: '/financial/eventManageData',
    method: 'get',
    params: data
  });
} //事件新增

export function eventManageAdd(data) {
  return request({
    url: '/financial/eventManageAdd',
    method: 'post',
    params: data
  });
} //事件修改

export function eventManageUpd(data) {
  return request({
    url: '/financial/eventManageUpd',
    method: 'post',
    params: data
  });
} //事件删除

export function eventManageDel(data) {
  return request({
    url: '/financial/eventManageDel',
    method: 'post',
    params: data
  });
} //预定义条件列表

export function conditionManageData(data) {
  return request({
    url: '/financial/conditionManageData',
    method: 'get',
    params: data
  });
} //预定义条件新增

export function conditionManageAdd(data) {
  return request({
    url: '/financial/conditionManageAdd',
    method: 'post',
    params: data
  });
} //预定义条件修改

export function conditionManageUpd(data) {
  return request({
    url: '/financial/conditionManageUpd',
    method: 'post',
    params: data
  });
} //预定义条件删除

export function conditionManageDel(data) {
  return request({
    url: '/financial/conditionManageDel',
    method: 'post',
    params: data
  });
} //事件绑定回传渠道列表

export function callbackEventReflectionManageData(data) {
  return request({
    url: '/financial/callbackEventReflectionManageData',
    method: 'get',
    params: data
  });
} //事件绑定回传渠道新增

export function callbackEventReflectionManageAdd(data) {
  return request({
    url: '/financial/callbackEventReflectionManageAdd',
    method: 'post',
    params: data
  });
} //事件绑定回传渠道修改

export function callbackEventReflectionManageUpd(data) {
  return request({
    url: '/financial/callbackEventReflectionManageUpd',
    method: 'post',
    params: data
  });
} //事件绑定回传渠道删除

export function callbackEventReflectionManageDel(data) {
  return request({
    url: '/financial/callbackEventReflectionManageDel',
    method: 'post',
    params: data
  });
} //事件回传条件设置列表

export function callbackTypeEventRuleManageData(data) {
  return request({
    url: '/financial/callbackTypeEventRuleManageData',
    method: 'get',
    params: data
  });
} //事件回传条件设置新增

export function callbackTypeEventRuleManageAdd(data) {
  return request({
    url: '/financial/callbackTypeEventRuleManageAdd',
    method: 'post',
    params: data
  });
} //事件回传条件设置修改

export function callbackTypeEventRuleManageUpd(data) {
  return request({
    url: '/financial/callbackTypeEventRuleManageUpd',
    method: 'post',
    params: data
  });
} //事件回传条件设置删除

export function callbackTypeEventRuleManageDel(data) {
  return request({
    url: '/financial/callbackTypeEventRuleManageDel',
    method: 'post',
    params: data
  });
}