import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  data: function data() {
    return {
      date: [],
      dialogShow: false,
      content_Val: [],
      type: ''
    };
  },
  created: function created() {},
  methods: {
    open: function open(data, type) {
      var _this = this;

      this.content_Val = [];
      this.type = type;

      if (type == 'recovery') {
        data.forEach(function (item) {
          _this.content_Val.push(item.title_content[0]);
        });
      } else {
        this.content_Val = data;
      }

      this.dialogShow = true;
    }
  }
};