var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page sewall section dataAll-content" },
    [
      _vm._l(_vm.searchData, function(item, index) {
        return _c("div", { key: index, staticClass: "inpBox" }, [
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("事件")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { disabled: "", placeholder: "事件" },
                model: {
                  value: item.func,
                  callback: function($$v) {
                    _vm.$set(item, "func", $$v)
                  },
                  expression: "item.func"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("时间")]),
              _c("el-date-picker", {
                staticClass: "inpClass",
                attrs: {
                  type: "datetime",
                  "value-format": "timestamp",
                  placeholder: "时间",
                  align: "right"
                },
                model: {
                  value: item.timestamp,
                  callback: function($$v) {
                    _vm.$set(item, "timestamp", $$v)
                  },
                  expression: "item.timestamp"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("游戏")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "游戏" },
                model: {
                  value: item.game,
                  callback: function($$v) {
                    _vm.$set(item, "game", $$v)
                  },
                  expression: "item.game"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("平台")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "平台" },
                model: {
                  value: item.platform,
                  callback: function($$v) {
                    _vm.$set(item, "platform", $$v)
                  },
                  expression: "item.platform"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("渠道")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "渠道" },
                model: {
                  value: item.channel,
                  callback: function($$v) {
                    _vm.$set(item, "channel", $$v)
                  },
                  expression: "item.channel"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("包名")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "包名" },
                model: {
                  value: item.pkg,
                  callback: function($$v) {
                    _vm.$set(item, "pkg", $$v)
                  },
                  expression: "item.pkg"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("广告渠道")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "广告渠道" },
                model: {
                  value: item.ad_channel,
                  callback: function($$v) {
                    _vm.$set(item, "ad_channel", $$v)
                  },
                  expression: "item.ad_channel"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("广告位置")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "广告位置" },
                model: {
                  value: item.ad_placement,
                  callback: function($$v) {
                    _vm.$set(item, "ad_placement", $$v)
                  },
                  expression: "item.ad_placement"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("设备ID")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "设备ID" },
                model: {
                  value: item.device_id,
                  callback: function($$v) {
                    _vm.$set(item, "device_id", $$v)
                  },
                  expression: "item.device_id"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("设备型号")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "设备型号" },
                model: {
                  value: item.device_model,
                  callback: function($$v) {
                    _vm.$set(item, "device_model", $$v)
                  },
                  expression: "item.device_model"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("系统类型")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "系统类型" },
                model: {
                  value: item.system_info,
                  callback: function($$v) {
                    _vm.$set(item, "system_info", $$v)
                  },
                  expression: "item.system_info"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("服务器ID")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "服务器ID" },
                model: {
                  value: item.server_id,
                  callback: function($$v) {
                    _vm.$set(item, "server_id", $$v)
                  },
                  expression: "item.server_id"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("用户ID")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "用户ID" },
                model: {
                  value: item.user_id,
                  callback: function($$v) {
                    _vm.$set(item, "user_id", $$v)
                  },
                  expression: "item.user_id"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("角色ID")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "角色ID" },
                model: {
                  value: item.role_id,
                  callback: function($$v) {
                    _vm.$set(item, "role_id", $$v)
                  },
                  expression: "item.role_id"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("订单ID")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "订单ID" },
                model: {
                  value: item.order_id,
                  callback: function($$v) {
                    _vm.$set(item, "order_id", $$v)
                  },
                  expression: "item.order_id"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("等级")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { type: "number", placeholder: "等级" },
                model: {
                  value: item.level,
                  callback: function($$v) {
                    _vm.$set(item, "level", _vm._n($$v))
                  },
                  expression: "item.level"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("金额")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { type: "number", placeholder: "金额" },
                model: {
                  value: item.amount,
                  callback: function($$v) {
                    _vm.$set(item, "amount", _vm._n($$v))
                  },
                  expression: "item.amount"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("国家")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "国家" },
                model: {
                  value: item.currency,
                  callback: function($$v) {
                    _vm.$set(item, "currency", $$v)
                  },
                  expression: "item.currency"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("商品ID")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "商品ID" },
                model: {
                  value: item.product_id,
                  callback: function($$v) {
                    _vm.$set(item, "product_id", $$v)
                  },
                  expression: "item.product_id"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("商品名称")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "商品名称" },
                model: {
                  value: item.product_name,
                  callback: function($$v) {
                    _vm.$set(item, "product_name", $$v)
                  },
                  expression: "item.product_name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c("div", { staticClass: "inpItemTitle" }, [_vm._v("IP")]),
              _c("el-input", {
                staticClass: "inpClass",
                attrs: { placeholder: "IP" },
                model: {
                  value: item.ip,
                  callback: function($$v) {
                    _vm.$set(item, "ip", $$v)
                  },
                  expression: "item.ip"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inpItem x-start" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.removeList(index)
                    }
                  }
                },
                [_vm._v("删除")]
              )
            ],
            1
          )
        ])
      }),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.ccc } },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("activity")
                    }
                  }
                },
                [_vm._v("激活上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("create_role")
                    }
                  }
                },
                [_vm._v("创角上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("register")
                    }
                  }
                },
                [_vm._v("注册上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("login")
                    }
                  }
                },
                [_vm._v("登录上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("recharge")
                    }
                  }
                },
                [_vm._v("充值上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("online_user")
                    }
                  }
                },
                [_vm._v("在线上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("resource")
                    }
                  }
                },
                [_vm._v("资源上报")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.searchClick("fastReport")
                    }
                  }
                },
                [_vm._v("快速上报")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }