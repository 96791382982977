var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.paramsData.env_id,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsData, "env_id", $$v)
                    },
                    expression: "paramsData.env_id"
                  }
                },
                _vm._l(_vm.szgmEnv, function(item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.label, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "operationBut ", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.getData(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSource({})
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { prop: "uid", label: "用户ID" } }),
          _c("el-table-column", { attrs: { prop: "task_id", label: "任务ID" } })
        ],
        1
      ),
      _c("addskipNoviceBoot", {
        ref: "addskipNoviceBoot",
        attrs: { propsData: { env_id: this.paramsData.env_id } },
        on: {
          change: function($event) {
            return _vm.getData(_vm.paramsData.page)
          }
        }
      }),
      _c("el-pagination", {
        attrs: {
          layout: "total,sizes, prev, pager, next",
          "current-page": _vm.paramsData.page,
          total: _vm.total
        },
        on: { "current-change": _vm.getList, "size-change": _vm.sizeChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }