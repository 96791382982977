import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import d2admin from './modules/d2admin';
import gmTool from './modules/gmTool';
import gmToolYJ from './modules/gmToolYJ';
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage,
    key: 'configData',
    paths: ['d2admin.serchSelect.productsList', 'd2admin.serchSelect.advchannels', 'd2admin.serchSelect.rawData', 'd2admin.serchSelect.HomePageState', 'd2admin.serchSelect.sericeArr', 'd2admin.serchSelect.royalpirates', 'd2admin.serchSelect.searchCountry', 'd2admin.serchSelect.productAll', 'd2admin.serchSelect.payChannel', 'd2admin.serchSelect.gameAll', 'd2admin.serchSelect.searchCurrency', 'd2admin.serchSelect.isreqCurrency', 'd2admin.serchSelect.isreqPayList', 'd2admin.serchSelect.isreqCountryList', 'd2admin.serchSelect.homeEvents', 'd2admin.system.CurrentRouter', 'd2admin.system.rebound', 'd2admin.system.exchangeRate', 'd2admin.system.pas', 'd2admin.system.kamitakuAuthorityList', 'd2admin.system.isAuthority', 'gmTool.init.serverList', 'gmTool.init.itemList', 'gmTool.init.isreqItemList', 'gmToolYJ.init.serverListYJ', 'd2admin.user.isOverview']
  })],
  modules: {
    d2admin: d2admin,
    gmTool: gmTool,
    gmToolYJ: gmToolYJ
  }
});