import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { onlineUsers } from "@/api/dataAll/onlineUsers";
import * as echarts from "echarts";
export default {
  name: "onlineUsers",
  mixins: [tabulation],
  data: function data() {
    return {
      more: true,
      value2: [],
      searchData: {
        days: "",
        starttime: ""
      },
      tableData: [],
      Dynamic: [],
      tableLtv: [],
      tableDate: {},
      show: false,
      echarts: false,
      dates: [],
      datas: [],
      series: [],
      selectedArr: {}
    };
  },
  created: function created() {
    this.getsearchDate();
  },
  methods: {
    getsearchDate: function getsearchDate() {
      var now = new Date(this.$times.zeroTimeZone());
      var year = now.getFullYear();
      var month = (now.getMonth() + 1).toString().length < 2 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      var date = now.getDate().toString().length < 2 ? "0" + now.getDate() : now.getDate();
      var date = year + "-" + month + "-" + date;
      this.searchData.starttime = date;
    },
    searchClick: function searchClick() {
      var _this = this;

      this.searchData.kind = 1;

      if (Number(this.searchData.days) <= 13) {
        this.searchData.days = this.searchData.days;
      } else {
        this.$message({
          showClose: true,
          message: "只可查询13天内的数据",
          type: "warning"
        });
        this.searchData.days = 1;
      }

      this.reqLoading = true;
      this.searchData.time = this.searchData.starttime;
      onlineUsers(this.searchData).then(function (res) {
        if (res.status == 200) {
          var objToKey = function objToKey(obj) {
            return Object.keys(obj).map(function (k) {
              return k;
            });
          };

          _this.dates = [];
          _this.datas = [];
          _this.series = [];
          var columns = [{
            label: '时间',
            prop: 'date'
          }];
          var data = [];
          var num = 0;
          var knum = 0;

          var _loop = function _loop(i) {
            num += 1;
            columns.push({
              label: i,
              prop: num + 'Number'
            });

            _this.dates.push(i);

            var obj = {
              name: i,
              type: "line",
              data: [],
              smooth: true
            };
            var d = {};
            var dateList = objToKey(res.data[i]);
            dateList.sort();
            dateList.forEach(function (ist) {
              var c = ist.substring(0, 1) == "0" ? ist.substring(1, 2) : ist;
              d[c] = res.data[i][ist];
              obj.data.push(res.data[i][ist]);
            });

            _this.series.push(obj);

            for (var k in d) {
              knum += 1;
              var a = knum - 1 < 10 ? "0" + (knum - 1) : knum - 1;
              var b = knum - 1 + 1 < 10 ? "0" + knum : knum;
              data.push({
                date: a + '点' + '~' + b + '点'
              });
              data[knum - 1][num + 'Number'] = d[k];

              if (knum == 24) {
                knum = 0;
              }
            }
          };

          for (var i in res.data) {
            _loop(i);
          }

          data.splice(24, data.length - 24);
          data.forEach(function (i) {
            _this.datas.push(i.date);
          });
          _this.tableData = data;
          _this.Dynamic = columns;

          _this.getechart();

          _this.$nextTick(function () {
            _this.getTableRight();
          });
        }
      }).finally(function () {
        _this.reqLoading = false;
      });
    },
    getechart: function getechart() {
      var chartDom = document.getElementById("onlineUsersEchart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: '在线人数(人)'
        },
        animationDuration: 3000,
        tooltip: {
          confine: true,
          trigger: "axis"
        },
        legend: {
          data: this.dates,
          // 这里显示点击搜索的日期--图表的最上面
          width: '80%' // selected: this.selectedArr,

        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          z: 12,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: this.datas
        },
        yAxis: {
          type: "value"
        },
        series: this.series
      };
      option && myChart.setOption(option, true);
    },
    exportdata: function exportdata() {
      this.$export.excel({
        columns: this.Dynamic,
        data: this.tableData,
        title: this.$route.meta.title
      });
    }
  }
};