import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { sendMail, editMail } from '@/api/gmtool/packageCode';
import { gmTool } from "@/mixins/index";
export default {
  mixins: [gmTool],
  props: {
    serverList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    prod: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var _rules;

    var validatePass = function validatePass(rule, value, callback) {
      if (value == '' || value < 0 || value > 65535) {
        callback(new Error('请输入正确的数值'));
      } else {
        callback();
      }
    };

    var validateuids = function validateuids(rule, value, callback) {
      var regex = /[\u4e00-\u9fa5，]/;

      if (regex.test(value) || !value) {
        callback(new Error('角色ID不正确(以英文逗号隔开 1,2,3)'));
      } else {
        callback();
      }
    };

    return {
      titleName: "",
      delayList: [{
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 2
      }],
      paramsData: {
        title: [],
        content: [],
        template_type: 0,
        is_default: [],
        language: [],
        server_ids: [],
        reward: [],
        content_Val: [],
        type: 'all_role',
        role_ids: '',
        is_delay: 2,
        delay_time: '',
        is_recycle_mail: 2,
        level: {
          minlevel: '',
          maxlevel: ''
        },
        sdk_type: '',
        vip_level: {
          minviplevel: '',
          maxviplevel: ''
        },
        logout_time: {
          minlogouttime: '',
          maxlogouttime: ''
        }
      },
      isTemplate: 0,
      date: [],
      dialogShow: false,
      whetherNot: [{
        label: '所有玩家',
        value: 'all_role'
      }, {
        label: '在线玩家',
        value: 'online_role'
      }, {
        label: '选中玩家',
        value: 'list_role'
      }, {
        label: '条件列表',
        value: 'limit'
      }],
      rules: (_rules = {
        role_ids: [{
          required: true,
          validator: validateuids,
          trigger: "blur"
        }],
        title: [{
          required: true,
          message: "请输入标题",
          trigger: "blur"
        }],
        server_ids: [{
          required: true,
          message: "请选择服务器",
          trigger: "blur"
        }],
        sdk_type: [{
          validator: validatePass,
          required: true,
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入内容",
          trigger: "blur"
        }],
        minlevel: [{
          required: true,
          message: "请输入等级下线",
          trigger: "blur"
        }],
        maxlevel: [{
          required: true,
          message: "请输入等级上线",
          trigger: "blur"
        }],
        minviplevel: [{
          required: true,
          message: "请输入VIP下线",
          trigger: "blur"
        }],
        maxviplevel: [{
          required: true,
          message: "请输入VIP上线",
          trigger: "blur"
        }],
        minlogouttime: [{
          required: true,
          message: "请选择时间下线",
          trigger: "blur"
        }],
        maxlogouttime: [{
          required: true,
          message: "请选择时间上线",
          trigger: "blur"
        }],
        item_id: [{
          required: true,
          message: "请选择物品",
          trigger: "blur"
        }],
        expire_time: [{
          required: true,
          message: "请选择过期时间",
          trigger: "blur"
        }],
        item_num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }],
        is_default: [{
          required: true,
          message: "请选择是否为模板",
          trigger: "blur"
        }],
        language: [{
          required: true,
          message: "请选择语言",
          trigger: "blur"
        }]
      }, _defineProperty(_rules, "content", [{
        required: true,
        message: "请输入邮件内容",
        trigger: "blur"
      }]), _defineProperty(_rules, "content_Val", [{
        required: true,
        message: "请添加内容",
        trigger: "blur"
      }]), _defineProperty(_rules, "delay_time", [{
        required: true,
        message: "请选择定时时间",
        trigger: "blur"
      }]), _defineProperty(_rules, "is_delay", [{
        required: true,
        message: "请选择",
        trigger: "blur"
      }]), _rules),
      isshot: false
    };
  },
  created: function created() {},
  methods: {
    addtag: function addtag(data, index) {
      this.paramsData.content_Val[index].content = "".concat(this.paramsData.content_Val[index].content).concat(data);
    },
    open: function open(datas, type) {
      var data = datas ? JSON.parse(JSON.stringify(datas)) : datas;
      this.dialogShow = true;

      if (data && !type) {
        var range = JSON.stringify(data.range) != '[]' ? data.range : data.request_data[0].target.range || '';
        data.reward.map(function (i) {
          i.type_id = i.type_id.toString(); // i.expire = this.$times.timestampToTime(i.expire,"YY-MM-DD H")

          i.ispermanent = i.expire == 0 ? true : false;
        });
        var server_ids = data.server_ids.map(function (i) {
          return Number(i);
        });
        var d = {
          id: data.id,
          title: [],
          content: [],
          is_default: [],
          language: [],
          is_delay: data.is_delay,
          delay_time: data.delay_time ? data.delay_time * 1000 : '',
          server_ids: server_ids,
          reward: data.reward,
          template_type: data.template_type,
          type: data.player_type,
          role_ids: data.args.length ? data.args.toString() : '',
          level: range.level || this.paramsData.level,
          sdk_type: range.sdktype,
          vip_level: range.viplevel || this.paramsData.vip_level,
          content_Val: data.content_Val,
          logout_time: range.logouttime ? {
            minlogouttime: Number(range.logouttime.minlogouttime) * 1000,
            maxlogouttime: Number(range.logouttime.maxlogouttime) * 1000
          } : {
            minlogouttime: '',
            maxlogouttime: ''
          }
        };
        this.isTemplate = data.content_Val[0].is_default;
        this.paramsData = _objectSpread({}, d);
      }

      if (type) {
        this.isshot = true;
        this.paramsData.type = "list_role";
        this.paramsData.server_ids = [parseInt(data.server_id)];
        this.paramsData.role_ids = data.role_id;
      }

      this.changeSelect(this.paramsData.server_ids);
    },
    hiad: function hiad() {
      this.dialogShow = false;
    },
    selectAll: function selectAll() {
      if (this.paramsData.server_ids.length < this.serverList.length) {
        var server_ids = [];
        this.serverList.map(function (item) {
          server_ids.push(item.serverID);
        });
        server_ids.unshift('全选');
        this.paramsData.server_ids = [].concat(server_ids);
      } else {
        // 取消全选
        this.paramsData.server_ids = [];
      }
    },
    changeSelect: function changeSelect(val) {
      if (!val.includes('全选') && val.length === this.serverList.length) {
        this.paramsData.server_ids.unshift('全选');
      } else if (val.includes('全选') && val.length - 1 < this.serverList.length) {
        this.paramsData.server_ids = this.paramsData.server_ids.filter(function (item) {
          return item !== '全选';
        });
      }
    },
    removeTag: function removeTag(val) {
      if (val === '全选') {
        this.paramsData.server_ids = [];
      }
    },
    addDomain: function addDomain() {
      this.paramsData.reward.push({
        num: '',
        expire: '',
        ispermanent: false,
        type_id: ''
      });
    },
    removeDomain: function removeDomain(item) {
      var index = this.paramsData.reward.indexOf(item);

      if (index !== -1) {
        this.paramsData.reward.splice(index, 1);
      }
    },
    addContent: function addContent() {
      this.paramsData.content_Val.push({
        disabled: false,
        content: '',
        language: '',
        title: '',
        is_default: ''
      });
    },
    removeContent: function removeContent(item) {
      var index = this.paramsData.content_Val.indexOf(item);

      if (index !== -1) {
        this.paramsData.content_Val.splice(index, 1);
      }
    },
    clearData: function clearData(isClearId) {
      var isClearContent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.isTemplate = isClearContent ? 0 : this.isTemplate;
      this.paramsData = {
        id: isClearId ? '' : this.paramsData.id,
        title: [],
        content: [],
        is_default: [],
        template_type: isClearContent ? 0 : this.paramsData.template_type,
        language: [],
        server_ids: this.isshot ? this.paramsData.server_ids : [],
        reward: isClearContent ? [] : this.paramsData.reward,
        type: this.paramsData.type,
        is_delay: 2,
        delay_time: '',
        role_ids: this.isshot ? this.paramsData.role_ids : '',
        content_Val: isClearContent ? [] : this.paramsData.content_Val,
        level: {
          minlevel: '',
          maxlevel: ''
        },
        sdk_type: '',
        vip_level: {
          minviplevel: '',
          maxviplevel: ''
        },
        logout_time: {
          minlogouttime: '',
          maxlogouttime: ''
        }
      };
      this.$refs.ruleForm.clearValidate();
    },
    dataChange: function dataChange(e) {
      this.paramsData.start_time = e[0] / 1000;
      this.paramsData.end_time = e[1] / 1000;
    },
    isTemplateChange: function isTemplateChange(e) {
      if (e == 0 && this.paramsData.template_type == 1) {
        this.paramsData.content_Val = [];
      }

      this.paramsData.template_type = 0;
    },
    templateTypeChange: function templateTypeChange(e) {
      var _this = this;

      this.paramsData.content_Val = [];
      var list = [];

      if (e == 1) {
        list = this.compensationList;
      } else if (e == 2) {
        list = this.awardDistribution;
      }

      list.forEach(function (i) {
        _this.paramsData.content_Val.push({
          disabled: true,
          content: i.content,
          language: i.value,
          title: i.title,
          is_default: '1'
        });
      });
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.paramsData));

          if (data.type == 'limit') {
            data.logout_time.minlogouttime = data.logout_time.minlogouttime / 1000;
            data.logout_time.maxlogouttime = data.logout_time.maxlogouttime / 1000;
            data.vip_level = data.vip_level.minviplevel || data.vip_level.maxviplevel ? JSON.stringify(data.vip_level) : "";
            data.logout_time = data.logout_time.minlogouttime || data.logout_time.maxlogouttime ? JSON.stringify(data.logout_time) : "";
            data.level = data.level.minlevel || data.level.maxlevel ? JSON.stringify(data.level) : "";
          } else if (data.type == 'list_role') {
            delete data.vip_level;
            delete data.logout_time;
            delete data.level;
            delete data.sdk_type;
          } else {
            delete data.vip_level;
            delete data.logout_time;
            delete data.level;
            delete data.role_ids;
            delete data.sdk_type;
          }

          var newList = [];
          data.reward.forEach(function (item) {
            newList.push({
              type_id: parseInt(item.type_id),
              expire: item.ispermanent ? 0 : item.expire,
              num: parseInt(item.num)
            });
          });
          data.reward = newList.length ? JSON.stringify(newList) : "";
          data.content_Val.forEach(function (item) {
            data.title.push(item.title);
            data.is_default.push(_this2.isTemplate);
            data.language.push(item.language);
            data.content.push(item.content);
          });
          data.delay_time = data.is_delay == 1 ? data.delay_time / 1000 : '';

          if (data.server_ids.length > _this2.serverList.length) {
            data.server_ids.splice(0, 1);
          }

          var reqFn = _this2.paramsData.id ? editMail : sendMail;
          delete data.content_Val;
          reqFn(_objectSpread(_objectSpread({}, data), _this2.prod)).then(function (res) {
            if (res.code == 200 || res.status == 200) {
              _this2.$message({
                message: '操作成功',
                type: 'success'
              });

              _this2.dialogShow = false;

              _this2.$emit('change');
            }
          });
        }
      });
    }
  }
};