var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "自定义名称", prop: "custom_name" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.custom_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "custom_name", $$v)
                  },
                  expression: "formData.custom_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "产品", prop: "product_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择产品"
                  },
                  model: {
                    value: _vm.formData.product_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "product_id", $$v)
                    },
                    expression: "formData.product_id"
                  }
                },
                _vm._l(_vm.productLists, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告渠道", prop: "advert_channel_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "广告渠道"
                  },
                  on: { change: _vm.channelChange },
                  model: {
                    value: _vm.formData.advert_channel_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "advert_channel_id", $$v)
                    },
                    expression: "formData.advert_channel_id"
                  }
                },
                _vm._l(_vm.advertChannelData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.advert_channel_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._l(_vm.formData.params, function(domain, index) {
            return _c("div", { key: index + "k", staticClass: "paramsClass" }, [
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex-1",
                      staticStyle: { "margin-right": "20px" },
                      attrs: {
                        prop: "params." + index + ".name",
                        rules: _vm.rules.value,
                        "label-width": "60px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity ",
                        attrs: {
                          disabled: "",
                          clearable: "",
                          placeholder: "请输入键"
                        },
                        model: {
                          value: domain.name,
                          callback: function($$v) {
                            _vm.$set(domain, "name", $$v)
                          },
                          expression: "domain.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        prop: "params." + index + ".value",
                        rules: _vm.rules.value,
                        "label-width": "60px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity ",
                        attrs: { clearable: "", placeholder: "请输入值" },
                        model: {
                          value: domain.value,
                          callback: function($$v) {
                            _vm.$set(domain, "value", $$v)
                          },
                          expression: "domain.value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }