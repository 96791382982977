var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          attrs: {
            title: _vm.paramsData.id
              ? "修改邮件"
              : _vm.isSend
              ? "发送邮件"
              : "创建邮件",
            visible: _vm.dialogShow,
            modal: false,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: function($event) {
              return _vm.clearData("ruleForm")
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                model: _vm.paramsData,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  key: "sender",
                  staticClass: "lodingItem",
                  attrs: { prop: "sender", label: "发送者" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "邮件发送人" },
                    model: {
                      value: _vm.paramsData.sender,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "sender", $$v)
                      },
                      expression: "paramsData.sender"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "title",
                  staticClass: "lodingItem",
                  attrs: { prop: "title", label: "邮件标题" }
                },
                [
                  _c("el-input", {
                    staticClass: "operationFrame-entity",
                    attrs: { placeholder: "请输入邮件标题" },
                    model: {
                      value: _vm.paramsData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "title", $$v)
                      },
                      expression: "paramsData.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "language",
                  staticClass: "lodingItem",
                  attrs: { prop: "language", label: "语言" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.paramsData.language,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "language", $$v)
                        },
                        expression: "paramsData.language"
                      }
                    },
                    _vm._l(_vm.Language, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "delay_state",
                  staticClass: "lodingItem",
                  attrs: { prop: "delay_state", label: "定时发送" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "operationFrame-entity",
                      attrs: {
                        disabled: _vm.paramsData.id ? true : false,
                        placeholder: "请选择是否定时发送"
                      },
                      on: { change: _vm.delayStateChange },
                      model: {
                        value: _vm.paramsData.delay_state,
                        callback: function($$v) {
                          _vm.$set(_vm.paramsData, "delay_state", $$v)
                        },
                        expression: "paramsData.delay_state"
                      }
                    },
                    _vm._l(_vm.timing, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.paramsData.delay_state == 1 || _vm.isSend
                ? _c(
                    "el-form-item",
                    {
                      key: "server_channel",
                      staticClass: "lodingItem",
                      attrs: { prop: "server_channel", label: "服务器渠道" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "operationFrame-entity",
                          attrs: { filterable: "", placeholder: "服务器渠道" },
                          on: { change: _vm.serverChange },
                          model: {
                            value: _vm.server_channel,
                            callback: function($$v) {
                              _vm.server_channel = $$v
                            },
                            expression: "server_channel"
                          }
                        },
                        _vm._l(_vm.serverChannel, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.delay_state == 1 || _vm.isSend
                ? _c(
                    "el-form-item",
                    {
                      key: "show_test",
                      staticClass: "lodingItem",
                      attrs: { prop: "show_test", label: "是否展示测试服" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "operationFrame-entity",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "是否展示测试服"
                          },
                          on: { change: _vm.serverChannelChange },
                          model: {
                            value: _vm.show_test,
                            callback: function($$v) {
                              _vm.show_test = $$v
                            },
                            expression: "show_test"
                          }
                        },
                        _vm._l(_vm.serverType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.delay_state == 1 || _vm.isSend
                ? _c(
                    "el-form-item",
                    {
                      key: "server_id",
                      staticClass: "lodingItem",
                      attrs: { prop: "server_id", label: "服务器" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "operationFrame-entity",
                          attrs: {
                            clearable: "",
                            "collapse-tags": "",
                            multiple: "",
                            filterable: "",
                            placeholder: "服务器"
                          },
                          on: { change: _vm.serverIdChange },
                          model: {
                            value: _vm.paramsData.server_id,
                            callback: function($$v) {
                              _vm.$set(_vm.paramsData, "server_id", $$v)
                            },
                            expression: "paramsData.server_id"
                          }
                        },
                        _vm._l(_vm.serverListYJ, function(item) {
                          return _c("el-option", {
                            key: item.serverID,
                            attrs: {
                              label: item.serverName,
                              value: item.serverID
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.delay_state == 1
                ? _c(
                    "el-form-item",
                    {
                      key: "type",
                      staticClass: "lodingItem",
                      attrs: { prop: "type", label: "邮件类型" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "operationFrame-entity",
                          attrs: {
                            disabled: _vm.isSend || _vm.isPermissions,
                            placeholder: "请选择邮件类型"
                          },
                          on: {
                            change: function($event) {
                              _vm.uids = ""
                            }
                          },
                          model: {
                            value: _vm.paramsData.type,
                            callback: function($$v) {
                              _vm.$set(_vm.paramsData, "type", $$v)
                            },
                            expression: "paramsData.type"
                          }
                        },
                        _vm._l(_vm.emailType, function(item) {
                          return _c("el-option", {
                            key: item.label,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.type == "to_users" &&
              _vm.paramsData.delay_state == 1
                ? _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    _vm._l(_vm.paramsData.serverData, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "x-bc",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "lodingItem",
                              attrs: { label: "服务器" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "operationFrame-entity",
                                  attrs: {
                                    disabled: "",
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "服务器"
                                  },
                                  model: {
                                    value: item.label,
                                    callback: function($$v) {
                                      _vm.$set(item, "label", $$v)
                                    },
                                    expression: "item.label"
                                  }
                                },
                                _vm._l(_vm.serverListYJ, function(item) {
                                  return _c("el-option", {
                                    key: item.serverID,
                                    attrs: {
                                      label: item.serverName,
                                      value: item.serverID
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "lodingItem",
                              attrs: {
                                prop: "serverData." + index + ".value",
                                rules: _vm.rules.uids,
                                label: "目标账号"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "operationFrame-entity",
                                attrs: {
                                  disabled: _vm.isSend || _vm.isPermissions,
                                  placeholder: "1000001,1000002"
                                },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              }),
                              _c("uploadImagetow", {
                                ref: "uploadImagetow",
                                refInFor: true,
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  paramsData: { env_id: _vm.propsData.env_id },
                                  fileType: ".txt",
                                  filesname: "txt",
                                  Url: "/sz-gm/upload_txt",
                                  title: "上传txt",
                                  disabled: _vm.isSend || _vm.isPermissions
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.fileUp($event, index)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.paramsData.delay_state == 1 || _vm.isSend
                ? _c(
                    "el-form-item",
                    {
                      key: "expire",
                      staticClass: "lodingItem",
                      attrs: { prop: "expire", label: "过期时间" }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "operationFrame-entity",
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          clearable: false,
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "日期"
                        },
                        model: {
                          value: _vm.paramsData.expire,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "expire", $$v)
                          },
                          expression: "paramsData.expire"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paramsData.delay_state == 1
                ? _c(
                    "el-form-item",
                    {
                      key: "delay_time",
                      staticClass: "lodingItem",
                      attrs: { prop: "delay_time", label: "发送时间" }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "operationFrame-entity",
                        attrs: {
                          "popper-class": "no-atTheMoment",
                          "value-format": "timestamp",
                          type: "datetime",
                          placeholder: "日期"
                        },
                        model: {
                          value: _vm.paramsData.delay_time,
                          callback: function($$v) {
                            _vm.$set(_vm.paramsData, "delay_time", $$v)
                          },
                          expression: "paramsData.delay_time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "flex lodingItem-title first_box",
                  staticStyle: {
                    "margin-bottom": "20px",
                    width: "100%",
                    "align-items": "center"
                  }
                },
                [
                  _vm._v(" 邮件内容: "),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "padding-left": "20px" }
                    },
                    _vm._l(_vm.tagList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "addtag",
                          on: {
                            click: function($event) {
                              return _vm.addtag(item.content)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    0
                  )
                ]
              ),
              _c(
                "el-form-item",
                {
                  key: "context",
                  staticClass: "lodingItem",
                  staticStyle: { width: "100%" },
                  attrs: { prop: "context" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 12 },
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.paramsData.context,
                      callback: function($$v) {
                        _vm.$set(_vm.paramsData, "context", $$v)
                      },
                      expression: "paramsData.context"
                    }
                  })
                ],
                1
              ),
              !_vm.isPermissions
                ? _c(
                    "el-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addDomain }
                        },
                        [_vm._v("添加物品 ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.paramsData.reward, function(domain, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "lodingItem",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lodingItem-operationFrame flex" },
                      [
                        _c("el-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            size: "mini",
                            type: "danger",
                            circle: "",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeDomain(domain)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".type",
                              rules: _vm.rules.item_type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { placeholder: "类型" },
                                on: {
                                  change: function($event) {
                                    return _vm.domainType(index)
                                  }
                                },
                                model: {
                                  value: domain.type,
                                  callback: function($$v) {
                                    _vm.$set(domain, "type", $$v)
                                  },
                                  expression: "domain.type"
                                }
                              },
                              _vm._l(_vm.itemType, function(items, indexs) {
                                return _c("el-option", {
                                  key: indexs,
                                  attrs: {
                                    label: items.label,
                                    value: items.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: {
                              prop: "reward." + index + ".id",
                              rules: !domain.textid ? _vm.rules.id : []
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "150px"
                                },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  disabled: !domain.type || domain.textid != "",
                                  placeholder: "物品"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.itemChange(index, "ITEM")
                                  }
                                },
                                model: {
                                  value: domain.id,
                                  callback: function($$v) {
                                    _vm.$set(domain, "id", $$v)
                                  },
                                  expression: "domain.id"
                                }
                              },
                              [
                                _vm._l(_vm.itemType1, function(items, indexs) {
                                  return domain.type == 1
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType2, function(items, indexs) {
                                  return domain.type == 2
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType3, function(items, indexs) {
                                  return domain.type == 3
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType4, function(items, indexs) {
                                  return domain.type == 4
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType5, function(items, indexs) {
                                  return domain.type == 5
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType6, function(items, indexs) {
                                  return domain.type == 6
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType7, function(items, indexs) {
                                  return domain.type == 7
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._l(_vm.itemType8, function(items, indexs) {
                                  return domain.type == 8
                                    ? _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: items.kind,
                                          value: items.id
                                        }
                                      })
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "reward." + index + ".textid",
                              rules: !domain.id ? _vm.rules.textid : []
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "物品ID",
                                disabled: domain.id != ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.itemChange(index, "ID")
                                }
                              },
                              model: {
                                value: domain.textid,
                                callback: function($$v) {
                                  _vm.$set(domain, "textid", $$v)
                                },
                                expression: "domain.textid"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { margin: "0px 10px 0 0" },
                            attrs: {
                              prop: "reward." + index + ".num",
                              rules: _vm.rules.item_num
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "数量" },
                              model: {
                                value: domain.num,
                                callback: function($$v) {
                                  _vm.$set(domain, "num", _vm._n($$v))
                                },
                                expression: "domain.num"
                              }
                            })
                          ],
                          1
                        ),
                        domain.type == 2
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px" },
                                attrs: {
                                  prop: "reward." + index + ".quality",
                                  rules: _vm.rules.item_quality
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { placeholder: "品质" },
                                    model: {
                                      value: domain.quality,
                                      callback: function($$v) {
                                        _vm.$set(domain, "quality", $$v)
                                      },
                                      expression: "domain.quality"
                                    }
                                  },
                                  _vm._l(_vm.quality, function(items, indexs) {
                                    return _c("el-option", {
                                      key: indexs,
                                      attrs: { label: items, value: items }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogShow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearData()
                    }
                  }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }