import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/datacenter_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tabulation } from "@/mixins/index";
import { overview, overview_currency } from "@/api/index/index";
import pageMinx from "./mixins/pageMinx";
export default {
  mixins: [tabulation, pageMinx],
  data: function data() {
    return {
      latestTodayData: {
        "recharge_amount": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "register_pnum": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "login_pnum": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "register_device_pnum": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "recharge_account": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "recharge_device": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "online_account": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "consume": {
          "today": 0,
          "yesterday": 0,
          "diff": 0
        },
        "arpu": 0,
        "register_arpu": 0
      },
      reqFn: 'getOverview',
      VUE_APP_COUNTTRY: process.env.VUE_APP_COUNTTRY,
      automatic: true // finallyFn:[ 'getOverview' ]

    };
  },
  created: function created() {
    this.latestTodayDataMark = JSON.parse(JSON.stringify(this.latestTodayData));
  },
  mounted: function mounted() {},
  methods: {
    resettingData: function resettingData() {
      this.latestTodayData = _objectSpread({}, this.latestTodayDataMark);
    },
    // 今日最新
    getOverview: function getOverview() {
      var _this = this;

      var isverification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!this.rawData.game_mark) {
        return;
      } // if(this.currencyClose && !this.searchData.currency_info){
      //   if(isverification){
      //     this.$message.error('请选择货币类型')
      //   }   
      //   return
      // }


      return new Promise(function (replace) {
        _this.reqLoading = true;
        var reqFn = _this.currencyClose ? overview_currency : overview;
        reqFn(_objectSpread(_objectSpread({
          date: _this.$times.timestampToTime(new Date(_this.$times.zeroTimeZone()).getTime() / 1000, 'YY-MM-DD')
        }, _this.searchData), _this.defaultData())).then(function (res) {
          if (res.code == 200) {
            res.data.recharge_amount.diff = _this.isyjfmvnd() ? res.data.recharge_amount.diff.toFixed(2) : _this.$utils.conversion(res.data.recharge_amount.diff).toFixed(2);
            res.data.recharge_amount.today = _this.isyjfmvnd() ? res.data.recharge_amount.today.toFixed(2) : _this.$utils.conversion(res.data.recharge_amount.today).toFixed(2);
            res.data.consume.diff = _this.$utils.conversion(res.data.consume.diff).toFixed(2);
            res.data.consume.today = _this.$utils.conversion(res.data.consume.today).toFixed(2);
            res.data.arpu = _this.$utils.conversion(res.data.arpu).toFixed(3);
            res.data.register_arpu = _this.$utils.conversion(res.data.register_arpu).toFixed(2);
            _this.latestTodayData = _objectSpread({}, res.data);
          }
        }).finally(function () {
          _this.reqLoading = false;
          replace();
        });
      });
    }
  }
};