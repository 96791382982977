var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.resetForm("ruleForm")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告渠道名称", prop: "advert_channel_name" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.advert_channel_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "advert_channel_name", $$v)
                  },
                  expression: "formData.advert_channel_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "广告渠道标志", prop: "advert_channel_mark" }
            },
            [
              _c("el-input", {
                staticClass: "operationFrame-entity",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.advert_channel_mark,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "advert_channel_mark", $$v)
                  },
                  expression: "formData.advert_channel_mark"
                }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { width: "100%", "margin-bottom": "0" } },
            [
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { prop: "params" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addContent }
                    },
                    [_vm._v("添加参数")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.formData.params, function(domain, index) {
            return _c("div", { key: index + "k", staticClass: "paramsClass" }, [
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex-1",
                      attrs: {
                        prop: "params." + index + ".value",
                        rules: _vm.rules.value,
                        "label-width": "60px"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "operationFrame-entity ",
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: domain.value,
                          callback: function($$v) {
                            _vm.$set(domain, "value", $$v)
                          },
                          expression: "domain.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticStyle: { margin: "0 10px 24px 10px" },
                    attrs: {
                      size: "mini",
                      type: "danger",
                      circle: "",
                      icon: "el-icon-delete"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.removeContent(domain)
                      }
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }